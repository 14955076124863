import React, { useState, useEffect } from "react";
import * as util from "../../Util";
import * as API from "../../services/api";
// import ComplaintEdit from './ComplaintEdit';
import $ from "jquery";
import SideMenu from "../../containers/sidemenu";
import Navbar from "../../containers/navbar";
import { withRouter } from "react-router-dom";
import ImageLoader from "../../containers/loader";
// import { confirmAlert } from 'react-confirm-alert'; // Import
// import 'react-confirm-alert/src/react-confirm-alert.css';
import Confirm from "../../containers/Confirm";
const SampleDetail = (props) => {
  const [state, setState] = useState({ data: null, loaded: false });
  const [open, setOpen] = useState(false);
  const [ progress, setProgress ] = useState(false);
  useEffect(() => {
    if (util.isLoggedIn() === false) {
      props.history.push("/login");
    }
    util.showLoader();
    // console.log('new props', props)
    let id = props.match.params.id;
    if (id === null || typeof id === "undefined" || id === "") {
      props.history.push("/samples");
    }
    async function fetchData() {
      try {
        setProgress(true);
        let result = await API.getSingleSample(id);
        console.log("##check##", result.data.sample);
        if (result.data.sample) {
          setState((prevState) => {
            let obj = Object.assign({}, prevState);
            obj.data = result.data.sample;
            obj.loaded = true;
            return obj;
          });
          util.hideLoader();
        }
      } catch (error) {
        setState({ ...state, loaded: true });
        util.hideLoader();
      } finally{
        setProgress(false);
      }
    }
    fetchData();
  }, []);
  const onEdit = (e) => {
    $("#closeComplaint").trigger("click");
    $("#complaintEdit")[0].click();
  };
  const onChange = (e) => {
    let { name, value } = e.target;
    setState((prevState) => {
      let obj = Object.assign({}, prevState);
      obj.data[name] = value;
      return obj;
    });
  };
  const onSubmit = async (e) => {
    // console.log('##submitted##', state.data)
    e.preventDefault();
    try {
      setProgress(true);
      util.showLoader();

      let result = await API.submitSample(state.data);
      if (result) {
        util.ShowNotification(result.data.responseMessage);
        props.history.push("/samples");
      }
    } catch (error) {}
    finally {
      setProgress(false);
    }
  };
  const onCancel = (e) => {
    props.history.push("/samples");
  };
  const onDelete = async (e) => {
    try {
      setProgress(true);
      util.showLoader();
      let id = props.match.params.id;
      let result = await API.deleteSample(id);
      // console.log('##submit##', result.data)
      if (result) util.hideLoader();
      setOpen(false);
      util.ShowNotification("Sample Deleted");
      props.history.push("/samples");
    } catch (error) {
      util.hideLoader();
    } finally{
      setProgress(false);
    }
  };
  const onAlert = (e) => {
    setOpen(true);
    // confirmAlert({
    //     title: '',
    //     message: 'Are you sure to do this.',
    //     closeOnEscape: true,
    //     closeOnClickOutside: true,
    //     buttons: [
    //         {
    //             label: 'Yes',
    //             onClick: () => onDelete()
    //         },
    //         {
    //             label: 'No',
    //             onClick: () => { }
    //         }
    //     ]
    // });
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div>
      <SideMenu />
      <div class="dashboard">
        <Navbar />
        <section id="contents">
          <ImageLoader />
          <section class="statis text-center">
            <Confirm
              handleClose={handleClose}
              open={open}
              onDelete={onDelete}
            />
            <div class="container-fluid">
              {state.data !== null ? (
                <div class="user_section complaints">
                  <div class="row margin-bottom">
                    <div class="col-md-12 text-left">
                      <h5 class="heading">Edit Sample</h5>
                    </div>
                  </div>

                  <form onSubmit={onSubmit}>
                    <div class="row margin-bottom">
                      <div class="col-md-4 text-left">
                        <div class="row">
                          <div class="col-md-4">
                            <span>ID</span>
                          </div>
                          <div class="col-md-8">
                            <input disabled type="text" value={state.data.id} />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4 text-left">
                        <div class="row">
                          <div class="col-md-4">
                            <span>User ID</span>
                          </div>
                          <div class="col-md-8">
                            <input
                              required
                              type="text"
                              disabled
                              value={state.data.userId}
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4 text-left">
                        <div class="row">
                          <div class="col-md-4">
                            <span>Added On</span>
                          </div>
                          <div class="col-md-8">
                            <input
                              required
                              type="text"
                              disabled
                              value={util.localDate(state.data.addedOn)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row margin-bottom">
                      <div class="col-md-4 text-left">
                        <div class="row">
                          <div class="col-md-4">
                            <span>Description</span>
                          </div>
                          <div class="col-md-8">
                            <input
                              required
                              type="text"
                              onChange={onChange}
                              name="description"
                              value={state.data.description}
                            />

                            {/* <select name="cars" id="cars">
                                                    <option value="volvo">Steve Smith</option>
                                                    <option value="saab">Steve Smith</option>
                                                    <option value="opel">Steve Smith</option>
                                                    <option value="audi">Steve Smith</option>
                                                </select> */}
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4 text-left">
                        <div class="row">
                          <div class="col-md-4">
                            <span>Name</span>
                          </div>
                          <div class="col-md-8">
                            <input
                              required
                              type="text"
                              onChange={onChange}
                              name="name"
                              value={state.data.name}
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4 text-left">
                        <div class="row">
                          <div class="col-md-4">
                            <span>Status Name</span>
                          </div>
                          <div class="col-md-8">
                            <input
                              required
                              type="text"
                              onChange={onChange}
                              name="statusName"
                              value={state.data.statusName}
                            />

                            {/* <select name="cars" id="cars">
                                                    <option value="volvo">Steve Smith</option>
                                                    <option value="saab">Steve Smith</option>
                                                    <option value="opel">Steve Smith</option>
                                                    <option value="audi">Steve Smith</option>
                                                </select> */}
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* <div class="row margin-bottom">
                                    <div class="col-md-4 text-left">
                                        <div class="row">
                                            <div class="col-md-4">
                                                <span>Associated ICA</span>
                                            </div>
                                            <div class="col-md-8">
                                                <input type="text" placeholder="ICA-23123" />
                                            </div>
                                        </div>
                                    </div>
                                </div> */}

                    {/* <div class="row margin-bottom">
                                    <div class="col-md-4 text-left">
                                        <div class="row">
                                            <div class="col-md-4">
                                                <span>Product</span>
                                            </div>
                                            <div class="col-md-8">
                                                <select name="loream Ipsum" id="cars">
                                                    <option value="volvo">loream Ipsum</option>
                                                    <option value="saab">loream Ipsum</option>
                                                    <option value="opel">loream Ipsum</option>
                                                    <option value="audi">loream Ipsum</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}

                    {/* <div class="row margin-bottom">
                                    <div class="col-md-4 text-left">
                                        <div class="row">
                                            <div class="col-md-4">
                                                <span for="birthday">Date</span>
                                            </div>
                                            <div class="col-md-8">

                                                <input type="date" id="birthday" name="birthday" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4 text-left">
                                        <div class="row">
                                            <div class="col-md-4">
                                                <span for="birthday">Close Date</span>
                                            </div>
                                            <div class="col-md-8">
                                                <input type="date" id="birthday" name="birthday" />
                                            </div>
                                        </div>
                                    </div>

                                </div> */}

                    {/* <div class="row margin-bottom">
                                    <div class="col-md-8 text-left">
                                        <div class="row">
                                            <div class="col-md-2">
                                                <span for="birthday">Description</span>
                                            </div>
                                            <div class="col-md-10">

                                                <textarea placeholder="loream" />
                                            </div>
                                        </div>
                                    </div>


                                </div> */}

                    {/* <div class="row margin-bottom">
                                    <div class="col-md-4 text-left">
                                        <div class="row">
                                            <div class="col-md-4">
                                                <span>Product Process</span>
                                            </div>
                                            <div class="col-md-8">
                                                <input type="text" placeholder="Lorem Ipsum" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4 text-left">
                                        <div class="row">
                                            <div class="col-md-4">
                                                <span>Systemic</span>
                                            </div>
                                            <div class="col-md-8">
                                                <input type="text" placeholder="Lorem Ipsum" />
                                            </div>
                                        </div>
                                    </div>

                                </div> */}

                    {/* <div class="row margin-bottom">
                                    <div class="col-md-4 text-left">
                                        <div class="row">
                                            <div class="col-md-4">
                                                <span>Escape</span>
                                            </div>
                                            <div class="col-md-8">
                                                <input type="text" placeholder="Lorem Ipsum" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4 text-left">
                                        <div class="row">
                                            <div class="col-md-4">
                                                <span>Fortech Affected</span>
                                            </div>
                                            <div class="col-md-8">
                                                <input type="text" placeholder="Lorem Ipsum" />
                                            </div>
                                        </div>
                                    </div>

                                </div> */}

                    {/* <div class="row margin-bottom">
                                    <div class="col-md-4 text-left">
                                        <div class="row">
                                            <div class="col-md-4">
                                                <span>Is Needed CA</span>
                                            </div>
                                            <div class="col-md-8 radio-button">
                                                <label> <input type="radio" id="yes" name="gender" value="Yes" /><span> Yes</span></label>
                                                <label> <input type="radio" id="no" name="gender" value="No" /> <span>No</span></label>
                                            </div>
                                        </div>
                                    </div>


                                </div> */}

                    {/* <div class="row margin-bottom">
                                    <div class="col-md-8 text-left">
                                        <div class="row">
                                            <div class="col-md-2">
                                                <span for="birthday">Why No CA</span>
                                            </div>
                                            <div class="col-md-10">

                                                <input type="text" placeholder="Lorem Ipsum" />
                                            </div>
                                        </div>
                                    </div>


                                </div> */}

                    <div class="row margin-bottom">
                      <div class="col-md-12 text-left">
                        <div class="row">
                          <div class="col-md-12">
                            <div class="buttons">
                              <button type="submit" class="update" disabled={progress}>
                                Update
                              </button>
                              <button onClick={onCancel} class="cancel">
                                Cancel
                              </button>
                              <i
                                onClick={onAlert}
                                class="fa fa-trash-o"
                                aria-hidden="true"
                              ></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              ) : (
                ""
              )}
            </div>
          </section>
        </section>
      </div>
    </div>
  );
};
export default withRouter(SampleDetail);
