import React, { Component } from 'react';
import ScaleLoader from 'react-spinners/ScaleLoader'

export default class ImageLoader extends Component {
    render() {
        return (
            <div id="progress-loader" className="progress-loader hidden">
                <img src="/images/logo.jpg" alt="logo"></img>
                <ScaleLoader
                    className="loader-bar"
                    sizeUnit={"%"}
                    size={30}
                    color={"#3e8bde"}
                />
            </div>
        );
    }
}