import React, { useState, useEffect } from "react";
import * as util from "../../Util";
import * as API from "../../services/api";
// import ComplaintEdit from './ComplaintEdit';
import $ from "jquery";
import SideMenu from "../../containers/sidemenu";
import Navbar from "../../containers/navbar";
import { withRouter } from "react-router-dom";
import ImageLoader from "../../containers/loader";
// import { confirmAlert } from 'react-confirm-alert'; // Import
// import 'react-confirm-alert/src/react-confirm-alert.css';
import Confirm from "../../containers/Confirm";
const FeedbackDetail = (props) => {
  const [state, setState] = useState({ data: null, loaded: false });
  const [open, setOpen] = useState(false);
  const [ progress, setProgress ] = useState(false);
  useEffect(() => {
    console.log("new props", props);
    if (util.isLoggedIn() === false) {
      props.history.push("/login");
    }
    util.showLoader();
    // console.log('new props', props)
    let id = props.match.params.id;
    if (id === null || typeof id === "undefined" || id === "") {
      props.history.push("/feedbacks");
    }
    async function fetchData() {
      try {
        setProgress(true);
        let result = await API.getSingleFeedback(id);
        console.log("##check##", result.data.feedback);
        if (result.data.feedback) {
          setState((prevState) => {
            let obj = Object.assign({}, prevState);
            obj.data = result.data.feedback;
            obj.loaded = true;
            return obj;
          });
          util.hideLoader();
        }
      } catch (error) {
        setState({ ...state, loaded: true });
        util.hideLoader();
      } finally{
        setProgress(false);
      }
    }
    fetchData();
  }, []);
  const onEdit = (e) => {
    $("#closeComplaint").trigger("click");
    $("#complaintEdit")[0].click();
  };
  const onChange = (e) => {
    let { name, value } = e.target;
    setState((prevState) => {
      let obj = Object.assign({}, prevState);
      obj.data[name] = value;
      return obj;
    });
  };
  const onSubmit = async (e) => {
    e.preventDefault();
    // console.log('##submitted##', state.data)
    if (state.data.message === "") {
      util.ShowNotification("Fields cannot be empty");
      return;
    }
    try {
      setProgress(true);
      util.showLoader();

      let result = await API.submitFeedback(state.data);
      console.log("##submit##", result.data);
      if (result) {
        util.hideLoader();
        util.ShowNotification(result.data.responseMessage);
        props.history.push("/feedbacks");
      }
    } catch (error) {
      util.hideLoader();
      util.showLoader(error.message);
      props.history.push("/feedbacks");
    } finally{
      setProgress(false);
    }
  };
  const onCancel = (e) => {
    props.history.push("/feedbacks");
  };
  const onDelete = async (e) => {
    try {
      setProgress(true);
      util.showLoader();
      let id = props.match.params.id;
      let result = await API.deleteFeedback(id);
      // console.log('##submit##', result.data)
      if (result) util.hideLoader();
      setOpen(false);
      util.ShowNotification("Feedback Deleted");
      props.history.push("/feedbacks");
    } catch (error) {
      util.hideLoader();
    } finally{
      setProgress(false);
    }
  };
  const onAlert = (e) => {
    setOpen(true);
    // confirmAlert({
    //     title: '',
    //     message: 'Are you sure to do this.',
    //     closeOnEscape: true,
    //     closeOnClickOutside: true,
    //     buttons: [
    //         {
    //             label: 'Yes',
    //             onClick: () => onDelete()
    //         },
    //         {
    //             label: 'No',
    //             onClick: () => { }
    //         }
    //     ]
    // });
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div>
      <SideMenu />
      <div class="dashboard">
        <Navbar />
        <section id="contents">
          <ImageLoader />
          <section class="statis text-center">
            <Confirm
              handleClose={handleClose}
              open={open}
              onDelete={onDelete}
            />

            <div class="container-fluid">
              {state.data !== null ? (
                <div class="user_section complaints">
                  <div class="row margin-bottom">
                    <div class="col-md-12 text-left">
                      <h5 class="heading">Edit Feedback</h5>
                    </div>
                  </div>
                  <form onSubmit={onSubmit}>
                    <div class="row margin-bottom">
                      <div class="col-md-4 text-left">
                        <div class="row">
                          <div class="col-md-4">
                            <span>ID</span>
                          </div>
                          <div class="col-md-8">
                            <input
                              disabled
                              value={state.data.id}
                              type="text"
                              placeholder="A-212123"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4 text-left">
                        <div class="row">
                          <div class="col-md-4">
                            <span>FeedBack Type</span>
                          </div>
                          <div class="col-md-8">
                            <input
                              disabled
                              value={state.data.feedbackTypeName}
                              type="text"
                              placeholder="CC-23213"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4 text-left">
                        <div class="row">
                          <div class="col-md-4">
                            <span>FeedBack Type ID</span>
                          </div>
                          <div class="col-md-8">
                            <input
                              disabled
                              value={state.data.cfeedbackTypeId}
                              type="text"
                              placeholder="CC-23213"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row margin-bottom">
                      <div class="col-md-4 text-left">
                        <div class="row">
                          <div class="col-md-4">
                            <span>Added On</span>
                          </div>
                          <div class="col-md-8">
                            <input
                              disabled
                              value={util.localDate(state.data.addedOn)}
                              type="text"
                              placeholder="CC-23213"
                            />

                            {/* <select name="cars" id="cars">
                                                    <option value="volvo">Steve Smith</option>
                                                    <option value="saab">Steve Smith</option>
                                                    <option value="opel">Steve Smith</option>
                                                    <option value="audi">Steve Smith</option>
                                                </select> */}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row margin-bottom">
                      <div class="col-md-8 text-left">
                        <div class="row">
                          <div class="col-md-2">
                            <span for="birthday">Feedback</span>
                          </div>
                          <div class="col-md-10">
                            <textarea
                              required
                              onChange={onChange}
                              value={state.data.message}
                              name="message"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* <div class="row margin-bottom">
                                    <div class="col-md-4 text-left">
                                        <div class="row">
                                            <div class="col-md-4">
                                                <span>Concentration Used</span>
                                            </div>
                                            <div class="col-md-8">
                                                <input onChange={onChange} name="concentrationUsed" value={state.data.concentrationUsed} type="text" placeholder="ICA-23123" />                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-4 text-left">
                                        <div class="row">
                                            <div class="col-md-4">
                                                <span>Lab Report URL</span>
                                            </div>
                                            <div class="col-md-8">
                                                <input onChange={onChange} name="labReportUrl" value={state.data.labReportUrl} type="text" placeholder="ICA-23123" />                                            </div>
                                        </div>
                                    </div>
                                </div> */}

                    {/* <div class="row margin-bottom">
                                    <div class="col-md-4 text-left">
                                        <div class="row">
                                            <div class="col-md-4">
                                                <span>Water Quality</span>
                                            </div>
                                            <div class="col-md-8">
                                                <input onChange={onChange} name="concentrationUsed" value={state.data.waterQuality} type="text" placeholder="ICA-23123" />                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-4 text-left">
                                        <div class="row">
                                            <div class="col-md-4">
                                                <span>Metallurgy</span>
                                            </div>
                                            <div class="col-md-8">
                                                <input onChange={onChange} name="metallurgy" value={state.data.metallurgy} type="text" placeholder="ICA-23123" />                                            </div>

                                        </div>
                                    </div>
                                </div> */}

                    {/* <div class="row margin-bottom">
                                    <div class="col-md-8 text-left">
                                        <div class="row">
                                            <div class="col-md-2">
                                                <span for="birthday">Lab Analysis Recomm.</span>
                                            </div>
                                            <div class="col-md-10">

                                                <input name="labAnalysisRecommendations" onChange={onChange} value={state.data.labAnalysisRecommendations} type="text" placeholder="Lorem Ipsum" />
                                            </div>
                                        </div>
                                    </div>


                                </div> */}

                    <div class="row margin-bottom">
                      <div class="col-md-12 text-left">
                        <div class="row">
                          <div class="col-md-12">
                            <div class="buttons">
                              <button type="submit" class="update" disabled={progress}>
                                Update
                              </button>
                              <button onClick={onCancel} class="cancel">
                                Cancel
                              </button>

                              <i
                                onClick={onAlert}
                                class="fa fa-trash-o"
                                aria-hidden="true"
                              ></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              ) : (
                ""
              )}
            </div>
          </section>
        </section>
      </div>
    </div>
  );
};
export default withRouter(FeedbackDetail);
