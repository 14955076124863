import $ from "jquery";
import moment from "moment";
var pjson = require("../package.json");

var config = window["runConfig"];
export let Config = config;
export const DOCK_AUDIT_OPTIONS = [
  {label: 'Yes', value: 'Yes'},
  {label: 'No', value: 'No'},
  {label: 'N/A', value: 'N/A'}
]
export function GetApiRoot() {
  return window.location.href.includes("fortechproducts-admin.netlify.app") ? "https://fortech-api.npit.info/api/" : config.apiRoot;
  // return "https://fortech-api.npit.info/api/";
}
export function ShowNotification(content, type) {
  if (type == null) type = "information";
  new window.Noty({
    text: content,
    type: type,
    timeout: 2500,
    progressBar: false,
    // theme: 'semanticui',
    animation: {
      open: "animated bounceInRight", // Animate.css class names
      close: "animated bounceOutRight", // Animate.css class names
    },
  }).show();
}
export async function getUserLoggedIn(email, password, token, role) {
  localStorage["email"] = email;
  localStorage["pass"] = password;
  localStorage["token"] = token;
  localStorage["role"] = role;
  return true;
}
export async function GetToken() {
  let token = localStorage["token"];
  if (token) {
    return token;
  } else {
    return null;
  }
}
export const changeBlurScrollClass = (prop) => {
  const classList = document.getElementsByTagName("body")[0].classList;
  prop ? classList.add("no-scroll") : classList.remove("no-scroll");
};
export function isLoggedIn() {
  let token = localStorage["token"];
  let user = localStorage["user"];
  if (
    typeof token !== "undefined" &&
    token.length > 0 &&
    typeof user !== "undefined" &&
    user.length > 0
  ) {
    return true;
  } else {
    return false;
  }
}
export function getUserLoggedOut() {
  localStorage.removeItem("token");
  localStorage.removeItem("user");

  localStorage.removeItem("role");
  return true;
}
export async function showLoader() {
  $(".progress-loader").removeClass("hidden");
}
export async function hideLoader() {
  $(".progress-loader").addClass("hidden");
}
export function localDate(date) {
  if (date && new Date(date).getTime() < 0) {
    return "NA";
  }
  return moment.utc(date).format("MMM DD, YYYY");
  // let currentDate = date;
  // let manipulatedDate = new Date(date).toString().split(" ");
  // manipulatedDate =
  //   manipulatedDate[1] + " " + manipulatedDate[2] + ", " + manipulatedDate[3];
  // // console.log('current--', currentDate.substring(0, currentDate.indexOf('T')))
  // // console.log('date--', new Date(currentDate))
  // // console.log('final date', manipulatedDate)
  // return manipulatedDate;
}
export function getVersion() {
  return pjson.version;
}
export function getUserRole() {
  return localStorage["role"];
}
export function isAdmin() {
  let role = localStorage["role"];
  if (
    typeof role !== "undefined" &&
    (role === "Admin" || role.toLowerCase() === "admin")
  ) {
    return true;
  } else {
    return false;
  }
}
export function isLab() {
  let role = localStorage["role"];
  if (typeof role !== "undefined" && role.toUpperCase() === "LABASSISTANCE") {
    return true;
  } else {
    return false;
  }
}

export function IsSales() {
  let role = localStorage["role"];
  if (typeof role !== "undefined" && role.toUpperCase() === "SALES") {
    return true;
  } else {
    return false;
  }
}

export function IsClient() {
  let role = localStorage["role"];
  if (role && role.toUpperCase() === "CLIENT") {
    return true;
  } else {
    return false;
  }
}

export function isEmployee() {
  let role = localStorage["role"];
  if (typeof role !== "undefined" && role.toUpperCase() === "EMPLOYEE") {
    return true;
  } else {
    return false;
  }
}

export async function RememberUser(user) {
  localStorage["user"] = JSON.stringify(user);
  localStorage["token"] = user.token;
  localStorage["role"] = user.role;
  return true;
}
export async function GetUserInfo() {
  if (typeof localStorage["user"] === "undefined") {
    return "";
  }
  let user = await JSON.parse(localStorage["user"]);

  let obj = { email: user.email, password: user.password };
  return obj;
}
export function GetRouteLocation() {
  let path = window.location.href;
  path = path.substring(path.lastIndexOf("/") + 1, path.length);

  if (path.length >= 1 && !path.includes("/")) {
    return path;
  }
  return "";
}

export function parseStrArray(str) {
  try {
    if (str == "" || str == "[]") return "--";
    let temp = JSON.parse(str).join(",");
    return temp;
  } catch {
    return "--";
  }
}

export function FetchFromUserData(key) {
  let user = localStorage.getItem("user");
  if (user != undefined || user != null) {
    user = JSON.parse(user);
    return user[key];
  } else {
    return "--";
  }
}

export function ReduceString(value) {
  if (value == null || value == undefined) {
    return "";
  }
  return value.toString().trim().toLowerCase().replace(/ /g, "");
}

export function checkEmpty(data) {
  if (data == null || data == undefined || data == "") {
    return "--";
  } else {
    return data;
  }
}

export function CreateLinkAndClick(href, name) {
  try {
    // console.log(href, name, "link");
    let link = document.createElement("a");
    link.id = name + "dwnld";
    link.href = href;
    link.target = "_blank";
    let temp = href.split("/");
    link.download = temp[temp.length - 1] || name;
    document.getElementById("root").appendChild(link);
    link.click();
    document.getElementById(name + "dwnld").remove();
  } catch (err) {
    console.log(err);
  }
}

export const dateFormat = (date, format) => {
    if (date && new Date(date).getTime() < 0) {
        return "";
    }
    return moment(date).format(format);
}
