import React, { Component } from "react";
import * as API from "../services/api";
import * as util from "../Util";
import { withRouter } from "react-router-dom";
import ImageLoader from "../containers/loader";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      messageClass: "hidden",
      message: "Please Wait...",
      forget: false,
      inprogress: false
    };
  }

  componentDidMount() {
   if(util.isLoggedIn()) {
    if (util.isAdmin() === true) {
      this.props.history.push("/users");
    } else {
      this.props.history.push("/service-request");
    }
   }

  }
  onSubmit = async e => {
    // debugger;
    let _this = this;
    e.preventDefault();
    this.setState({
      ...this.state,
      messageClass: "text-info",
      messsage: "Please Wait...",
      inprogress: true
    });
    let data = {
      Email: this.state.username,
      Password: this.state.password
    };
    try {
      let result = await API.authorize(data);
      console.log('api ressuulltt', result)
      let loginData = result.data.data;
      if (loginData!=null && loginData.email !== null && loginData.email !== "") {
        let flag = await util.RememberUser({
          email: loginData.email,
          password: this.state.password,
          token: loginData.token,
          role: loginData.userRole,
          ...loginData
        });
        if (util.isAdmin() === true) {
          this.props.history.push("/users");
        } else {
          this.props.history.push("/service-request");
        }
      } else {
        this.setState({
          messageClass: "text-danger",
          message: result.data.message
        });
      }
    } catch (error) {
      this.setState({
        messageClass: "text-danger",
        message: "Something went wrong"
      });
    } finally {
      this.setState({ inprogress: false });
    }
  };
  onChange = e => {
    this.setState({ ...this.state, [e.target.name]: e.target.value, messageClass:"hidden", message: "Please wait..."});
  };

  toggleForget = e => {
    // debugger;
    // util.showLoader();
    this.setState({ forget: !this.state.forget });
  };
  onForgotSubmit = async e => {
    e.preventDefault();
    let _this = this;

    this.setState({
      ...this.state,
      messageClass: "text-info",
      messsage: "Please Wait..."
    });
    let data = this.state.username;
    try {
      let result = await API.forgetPassword(data);
      // console.log('api ressuulltt', result.data.status)
      if (result.data) {
        this.setState({
          messageClass: "text-danger",
          message: result.data.responseMessage
        });
      }
    } catch (error) {
      this.setState({
        messageClass: "text-danger",
        message: "Something went wrong"
      });
    }
  };
  render() {
    return (
      <div>
        <div className="login">
          <div class="container">
            <div class="row">
              <div class="col-md-7 login-left">
                <img src="/images/login.png" />
              </div>
              <div class="col-md-5">
                <div class="login-form">
                  <img src="/images/logo.jpg" />
                  <h2>Welcome Back!</h2>
                  {this.state.forget === false ? (
                    <form onSubmit={this.onSubmit}>
                      <fieldset disabled={this.state.inprogress}>
                        <div class="form-group">
                          <label>Email</label>
                          <input
                            name="username"
                            onChange={this.onChange}
                            type="email"
                            placeholder="Enter your Email"
                            required
                          />
                        </div>
                        <div class="form-group">
                          <label>Password</label>
                          <input
                            name="password"
                            onChange={this.onChange}
                            type="password"
                            placeholder="Enter your Password"
                            required
                          />
                        </div>
                        <div class="login-btn">
                          <button disabled={this.state.inprogress}>
                            Login
                          </button>
                          <p className={this.state.messageClass}>
                            {this.state.message}
                          </p>
                          <a
                            href="javascript:void(0)"
                            onClick={this.toggleForget}
                          >
                            Forgot Password?
                          </a>
                          <ImageLoader />
                          <p className="version">
                            VERSION: {util.getVersion()}
                          </p>
                        </div>
                      </fieldset>
                    </form>
                  ) : (
                    // if forget is true!!!
                    <form onSubmit={this.onForgotSubmit}>
                      <div class="form-group">
                        <label>Email</label>
                        <input
                          name="username"
                          onChange={this.onChange}
                          type="email"
                          placeholder="Enter your Email"
                        />
                      </div>

                      <div class="login-btn">
                        <button>Proceed</button>
                        <p className={this.state.messageClass}>
                          {this.state.message}
                        </p>
                        <a
                          href="javascript:void(0)"
                          onClick={this.toggleForget}
                        >
                          Go Back!
                        </a>
                      </div>
                    </form>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(Login);
