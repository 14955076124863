import React, { useEffect } from "react";
import { localDate } from "../Util";
const Report8DPrintForm = ({ state = {}, id = "", rootCauses = [] }) => {
    const GetValues = (data) => {
        if (data == null || data == undefined) {
            return "NA";
        }
        data = data.replace(/[^A-Za-z',\s\d]/g, "").split(",");
        if (data.length == 1 && data[0] == "") {
            return "NA";
        }
        return data.map((val, id) => {
            if (id + 1 == data.length) {
                return val;
            }
            return val + ", ";
        });
    };
    const RenderBlock = (label, value, setAsHTML = false, colSize = '4') => {
        try{
            return (
                <div className={`col-md-${colSize}`}>
                    <div>
                        <span>{label}</span>
                        {
                            setAsHTML ? <label dangerouslySetInnerHTML={{__html: value || 'NA'}}></label> : <label>{value || 'NA'}</label>
                        }
                    </div>
                </div>
            );
        }catch(error){
            console.log("error", error);
        }
    };
    return (
        <div id='print-form'>
            <div className='row'>
                <div className='col-md-6'>
                    <img
                        className='header-logo'
                        src='/images/logo-print.png'
                        alt=''
                    />
                </div>
                <div className='col-md-6'>
                    <div className='address-block'>
                        7600 Kensington Ct. Brighton, MI 48116
                        <div>Ph: 248-446-9500 ex. 111 Fax: 248-446-1500</div>
                        <span className='email-detail'>
                            www.fortechproducts.com
                        </span>
                    </div>
                </div>
            </div>
            <div className='row form-details'>
                <div className='col-md-12'>
                    <h4>
                        8D Report{" "}
                        <span>
                            #<strong>{id}</strong>
                        </span>
                    </h4>
                    {/* heading */}
                    <div className='row details-head'>
                        <div className='col-md-5'>
                            <div>
                                <span>Customer Complaint No.</span>
                                <label>{`CCNo#${state.customerComplaintsNo}`}</label>
                            </div>
                        </div>
                        <div className='col-md-5'>
                            <div>
                                <span>Company</span>
                                <label>{state.company || "NA"}</label>
                            </div>
                        </div>
                    </div>
                    {/* heading end */}
                    <div className='row form-label-values'>
                        {/* start */}
                        {/*  */}
                        {RenderBlock("Date Open", localDate(state.dateOpen))}
                        {RenderBlock("Initial Response", state.initialResponse)}
                        {RenderBlock(
                            "Target Close Date",
                            localDate(state.targetCloseDate)
                        )}
                        {RenderBlock(
                            "Revision Date",
                            localDate(state.revisionDate)
                        )}
                        {RenderBlock("8D Initiator", state.initiator)}
                        {RenderBlock(
                            "Actual Close Date",
                            localDate(state.actualCloseDate)
                        )}
                        {/* newline */}
                        <div className='col-md-12'>
                            <h5>Who is effected by the problem?</h5>
                        </div>
                        {RenderBlock("Company", state.company)}
                        {RenderBlock("Address", state.address)}
                        {RenderBlock("Part No./ Code", state.partNoCode)}
                        {RenderBlock("Product Name", state.productName)}
                        <div className='col-md-12'>
                            <h5>Team member Names/Titles</h5>
                        </div>
                        {RenderBlock("Champion", state.champion)}
                        {RenderBlock("Team Leader", state.teamLeaderId)}
                        {RenderBlock("Team Members", GetValues(state.teamMembers))}
                        {/* newline */}
                        {RenderBlock("Problem Statement/Description:", state.problemStatement_Description, true, '12')}
                        {/* documents attachments */}
                        {/* newline */}
                        <div className='col-md-12'>
                            <h5>Choose and verify Interim Containment Actions:</h5>
                        </div>
                        {RenderBlock("Notes", state.verifyInterimContainment, true, '12')}
                        {RenderBlock("% Effective", state.interimContainmentEffective)}
                        {RenderBlock("Target Date", localDate(state.interimContainmentTagetDate))}
                        {RenderBlock("Actual Date", localDate(state.interimContainmentActualDate))}
                        {/* documents attachments */}
                        {/* verify root cause */}
                        {
                            rootCauses.length !== 0 ? 
                            <>
                                <div className='col-md-12'>
                                    <h5>Define and Verify Root Causes:</h5>
                                </div>
                                {
                                    rootCauses.map((cause, index)=>{
                                        return <>
                                            {RenderBlock(`Cause ${index+1}:`, cause.note, true, '12')}
                                            {RenderBlock("% Contribution", cause.contribution)}
                                            {RenderBlock("Target Date", localDate(cause.targetDate))}
                                            {RenderBlock("Actual Date", localDate(cause.actualDate))}
                                            {/* documents attachments */}
                                        </>
                                    })
                                }
                            </>
                            : null
                        }
                        {/* newline */}
                        <div className='col-md-12'>
                            <h5>Choose and Verify Permanent Corrective Actions:</h5>
                        </div>
                        {RenderBlock("Notes", state.verifyPermanentCorrective, true, '12')}
                        {RenderBlock("% Effective", state.verifyPermanentCorrectiveEffective)}
                        {RenderBlock("Target Date", localDate(state.verifyPermanentCorrectiveTagetDate))}
                        {RenderBlock("Actual Date", localDate(state.verifyPermanentCorrectiveActualDate))}
                        {/* documents attachments */}
                        {/* newline */}
                        <div className='col-md-12'>
                            <h5>System Prevention Actions to Prevent Recurrence:</h5>
                        </div>
                        {RenderBlock("Mistake Proofing: How are you going to ensure it can't happen again?", state.actionsToPreventRecurrence, true, '12')}
                        {RenderBlock("Target Date", localDate(state.actionsToPreventRecurrenceTagetDate))}
                        {RenderBlock("Actual Date", localDate(state.actionsToPreventRecurrenceActualDate))}
                        {/* documents attachments */}
                        {/* newline */}
                        {RenderBlock("Team and Individual Recognition:", state.individualRecognition, true, '12')}
                        {/* newline */}
                        <div className='col-md-12'>
                            <h5>Has Corrective Action/Implmentation been Reviewed against Documents?</h5>
                        </div>
                        {
                            state.controlPlanEnabled && <div className='col-md-3'>
                                <div>
                                    <label>Control Plan</label>
                                </div>
                            </div>
                        }
                        {
                            state.fmeaEnabled && <div className='col-md-3'>
                                <div>
                                    <label>FMEA</label>
                                </div>
                            </div>
                        }
                        {
                            state.flowchartEnabled && <div className='col-md-3'>
                                <div>
                                    <label>Flow Chart</label>
                                </div>
                            </div>
                        }
                        {
                            state.procWorkIndusEnabled && <div className='col-md-3'>
                                <div>
                                    <label>Proc./Work Instr.</label>
                                </div>
                            </div>
                        }
                        {
                            state.addToInternalAuditEnabled && <div className='col-md-3'>
                                <div>
                                    <label>Add to internal</label>
                                </div>
                            </div>
                        }
                        {/*  */}
                        {/* end */}
                        {/* COMMENTS SECTION */}
                        <div className='col-md-12'>
                            <div>
                                <span>Comments</span>
                            </div>
                        </div>
                        <div className='col-md-12 comment-lines'></div>
                        <div className='col-md-12 comment-lines'></div>
                        <div className='col-md-12 comment-lines'></div>
                        <div className='col-md-12 comment-lines'></div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Report8DPrintForm;
