import React, { useState, useEffect, Fragment } from "react";
import * as util from "../../Util";
import * as API from "../../services/api";
// import ComplaintEdit from './ComplaintEdit';
import $ from "jquery";
import SideMenu from "../../containers/sidemenu";
import Navbar from "../../containers/navbar";
import { withRouter, Link } from "react-router-dom";
import ImageLoader from "../../containers/loader";
// import { confirmAlert } from 'react-confirm-alert'; // Import
// import 'react-confirm-alert/src/react-confirm-alert.css';
import Confirm from "../../containers/Confirm";
import MultiSelector from "./MultiSelect";
import { readFileAsDataUrl } from "../../shared/Shared";
import Upload from "../Upload";
import "./ServiceDetails.css";
import AutoSearchCtrl from "../../shared/AutoSelectCtrl";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import ServiceRequestPrintForm from "../../PrintForms/ServiceRequestPrintForm";
import moment from "moment";
let cb;
const customStyles = {
  control: (base) => ({
    ...base,
    height: 100,
    minHeight: 100,
    overflow: "scroll",
  }),
};
// IS LAB REPORT CHANGED
let LabFlag = 0;
let DeleteArray = [];
let DeleteArrayImages = [];
//
const ServiceDetails = (props) => {
  const severity = ["high", "low", "medium"];
  const labReportRequirement = ["Yes(Internal)", "Yes(External)", "No"];
  const initialValues = {
    competitiveProduct: "",
    competitiveProductPrice: "",
    concentrationUsed: "",
    customerId: null,
    customerName: "",
    customerPartType: "",
    customerSwitching: "",
    detailedRequestObjective: "",
    currentStatus: "",
    id: undefined,
    labAnalysisRecommendations: "",
    labReportRequired: "",
    labReportUrl: "",
    metalType: "",
    process: "",
    processType: "",
    product: "",
    salesOwner: "",
    severityLevel: "",
    status: "",
    tsrType: "",
    assignedTo: "",
    productType: "",
    volumePerYear: "",
    waterQuality: "",
    // requestedCompletionDate: new Date(),
    targetCloseDate: "",
    actualCloseDate: "",
  };

  const [CustomerError, SetCustomerError] = useState(false);
  const [productList, setProductList] = useState();
  const [productType, setProductType] = useState([]);
  const [showSelectors, setShowSelectors] = useState({
    process: false,
    processType: false,
    product: false,
    metalType: false,
  });
  const [state, setState] = useState({
    data: initialValues,
    loaded: false,
    name: "",
    severityLevel: "",
  });
  let [images, setImages] = useState([]);
  const [open, setOpen] = useState(false);
  const [err, setError] = useState(null);
  const [salesOwner, setSalesOwner] = useState([]);
  const [labReport, setLabReport] = useState({
    imageShown: null,
    binary: null,
  });

  const [EditMode, SetEditMode] = useState(false);
  let [PrintFlash, SetPrintFlash] = useState(true);

  let [LabDocuments, SetLabDocuments] = useState([
    { file: "", preview: "", label: "Upload document" },
    { file: "", preview: "", label: "Upload document" },
    { file: "", preview: "", label: "Upload document" },
  ]);
  
  const [DropDownData, SetDropDownData] = useState({
    metaL_type_opt: [],
    process: [],
    subprocess: [],
  });

  const [TSRTypes, SetTSRTypes] = useState([]);
  const [Assignees, SetAssignees] = useState([]);
  const [ progress, setProgress ] = useState(false);

  useEffect(() => {
    DeleteArray = [];
    DeleteArrayImages = [];
    if (util.isLoggedIn() === false) {
      props.history.push("/login");
    }
    let id = props.match.params.id;

    if (id != undefined) {
      if (isNaN(parseInt(id))) {
        // Invalid Edit Id passed
        GoBack();
        return;
      }
      // Otherwise edit is valid
      SetEditMode(true);
    }

    util.showLoader();

    // Inits the 5 image blocks //
    const sampleImages = [];
    for (let i = 0; i < 5; i++) {
      sampleImages.push({
        id: `image${i}`,
        name: `image${i}`,
        imageShown: { imageUrl: "" },
        fileId: null,
        binary: null,
      });
    }
    images = sampleImages;
    setImages([...sampleImages]);
    //

    async function fetchData() {
      try {
        setProgress(true);
        // get products
        FetchProducts();

        // get salespersons
        FetchSalesPersons();

        if (id) {
          // Fetch Images
          FetchServiceRequestImages(id);

          // Fetch Service Request Details
          let result = await API.getSingleRequest(id);

          if (result.data.data) {
            result.data.data.severityLevel =
              result.data.data.severityLevel.toLowerCase();

            // SetData in Autocomplete
            if (cb != undefined) {
              cb({
                value: result.data.data.customerId,
                label: result.data.data.customerName,
              });
            }
            setState((prevState) => {
              let obj = Object.assign({}, prevState);

              obj.data = result.data.data;
              obj.loaded = true;
              let temp = result.data.data.status;

              //
              // target close date
              if (
                obj.data.targetCloseDate &&
                new Date(obj.data.targetCloseDate).getTime() >= 0
              ) {
                obj.data.targetCloseDate = moment(
                  obj.data.targetCloseDate
                ).format("YYYY-MM-DD");
              }
              // actual close date
              if (
                obj.data.actualCloseDate &&
                new Date(obj.data.actualCloseDate).getTime() >= 0
              ) {
                obj.data.actualCloseDate = moment(
                  obj.data.actualCloseDate
                ).format("YYYY-MM-DD");
              }
              //

              if (temp != null || temp != undefined) {
                obj.status = temp;
              }

              return obj;
            });
            //
            if (result.data.data.uploadFilesList != undefined) {
              result.data.data.uploadFilesList.forEach((file, idx) => {
                if (idx < 3) {
                  LabDocuments[idx].file = file.fileUrl;
                  LabDocuments[idx].preview = file.fileUrl;
                  LabDocuments[idx].label = file.fileName;
                }
              });
              setTimeout(() => {
                SetLabDocuments([...LabDocuments]);
                console.log("test", state);
              }, 400);
            }
            //
          }
        }
        util.hideLoader();
      } catch (error) {
        console.log(error, "test");
        setState({ ...state, loaded: true });
        util.hideLoader();
      } finally{
        setProgress(false);
      }
    }
    fetchData();

    //
    FetchDropDownData();
    FetchTSRTypes();
    FetchAssignees();
    FetchProdutTypes();
    //
  }, []);
  
  const FetchProdutTypes = async () => {
    try {
      let result = await API.GetData("Utility/GetProductTypes");
      if (result.data) {
        setProductType(result.data)
      } else {
        throw result;
      }
    } catch(err) {
      console.log("err", err)
    } finally {
      // done
    }
  }


  const FetchTSRTypes = async () => {
    try {
      let result = await API.GetData("Utility/GetTsrTypes");
      if (result.data) {
        SetTSRTypes(result.data)
      } else {
        throw result;
      }
    } catch(err) {
      console.log("err", err)
    } finally {
      // done
    }
  }

  const FetchAssignees = async () => {
    try {
      let result = await API.GetData("Utility/GetAssignees");
      if (result.data) {
        SetAssignees(result.data.map((e)=>({label: e, value: e})));
      } else {
        throw result;
      }
    } catch(err) {
      console.log("err", err)
    } finally {
      // done
    }
  }

  const FetchDropDownData = async () => {
    try {
      let result = await API.GetData("Utility/DropdownData");
      console.log("result", result);
      if (result.data) {
        SetDropDownData({
          metaL_type_opt: result.data.metaL_type_opt,
          process: result.data.process,
          subprocess: result.data.subprocess,
        });
      } else {
        throw result;
      }
    } catch (err) {
      console.log("err", err);
    } finally {
    }
  };

  // Fetch Data //
  // Products
  const FetchProducts = async () => {
    try{
      const productsResults = await API.GetAllProducts();
      console.log("products result is: ", productsResults.data.products.items);
      setProductList(
        productsResults.data.products.items.map((val, index) => {
          return { value: val.name, label: val.name };
        })
      );
    }catch(err){
      console.log('error', err);
    }
  };
  // SalesPeople
  const FetchSalesPersons = async () => {
    const salesPerson = await API.getSalesPeople();
    setSalesOwner(salesPerson.data.data.items);
  };

  // Service Request Images
  const FetchServiceRequestImages = async (id) => {
    const result = await API.getServiceRequestImages(id);

    const imageItems = result.data.data.items;
    console.log("images", imageItems);

    let temp = [...images];

    if (imageItems.length > 0) {
      for (let i = 0; i < imageItems.length; i++) {
        if (temp[i]) {
          temp[i].imageShown.imageUrl = imageItems[i].fileUrl;
          temp[i].fileId = imageItems[i].fileId;
        }
      }
    }
    setImages(temp);
    console.log("sample images now", temp);
  };
  // //

  // Data Input //
  // Handle File Selection
  const HandleFileChooser = async (imageEvent, id, processArray = true) => {
    imageEvent.persist();
    setError(null);
    const file = imageEvent.target.files[0];
    // if (file && file.type.indexOf("image") !== -1) {
    if (file) {
      console.log(file, "choose");
      const dataUrl = await readFileAsDataUrl(imageEvent.target.files[0]);
      console.log("data url rec", dataUrl);
      if (dataUrl.length > 0) {
        if (processArray) {
          const cloneImages = [...images];
          const myImage = cloneImages.find((val) => val.id === id);
          if (myImage) {
            console.log("found");

            myImage.imageShown.imageUrl = dataUrl;
            myImage.binary = file;

            if (myImage.fileId) {
              let index = DeleteArrayImages.indexOf(myImage.fileId);
              if (index == -1) {
                DeleteArrayImages.push(myImage.fileId);
              }
            }
            console.log(cloneImages, myImage, DeleteArrayImages, "test");
            setImages(cloneImages);
          }
        } else {
          setLabReport({ imageShown: { imageUrl: dataUrl }, binary: file });
        }
      }
    } else {
      return;
      // setError("Invalid image format");
    }
  };

  // Select Drop-Downs
  const HandleSelectChange = (e) => {
    let { name, value } = e.target;
    console.log("name" + name + " " + "value" + value);
    setState((prevState) => {
      let obj = Object.assign({}, prevState);
      obj.data[name] = value;
      return obj;
    });
  };
  // Input fields
  const HandleChange = (e) => {
    let { name, value } = e.target;
    setState((prevState) => {
      let obj = Object.assign({}, prevState);
      obj.data[name] = value;
      return obj;
    });
  };
  // //

  // MULTI SELECT //
  // OPEN
  const OpenMultiSelect = (val) => {
    console.log(val, productList);

    const cloneShowSelector = { ...showSelectors };
    cloneShowSelector[val] = !cloneShowSelector[val];
    util.changeBlurScrollClass(cloneShowSelector[val]);
    setShowSelectors(cloneShowSelector);
  };

  // HANDLE SELECTION IN MULTI SELECT
  const SelectionMadeMultiSelect = (name, value) => {
    console.log(name, value, "ok");
    const cloneState = { ...state };
    cloneState.data[name] = value;
    setState(cloneState);
  };

  // BACKDROP DISABLE
  const DisableBackDrop = (_) => {
    const cloned = { ...showSelectors };
    for (let item in cloned) {
      cloned[item] = false;
    }
    setShowSelectors(cloned);
    if (open) {
      setOpen(false);
    }
    util.changeBlurScrollClass(false);
  };

  // //

  const HandleLabThenSave = async (e) => {
    e.preventDefault();
    if (state.data.customerId == null || state.data.customerName == null) {
      SetCustomerError(true);
      return;
    }
    setError(null);

    try {
      util.showLoader();

      console.log("mult", LabFlag, EditMode, DeleteArray);

      // DELETE //
      if (DeleteArray.length > 0 && EditMode) {
        let deleteres = await API.DeleteData("Files/Delete", DeleteArray);
        console.log("mult del", deleteres);
      }
      //---//
      // return;
      if (LabFlag != 0 && EditMode) {
        let LabReportData = new FormData();

        LabDocuments.forEach((doc) => {
          if (typeof doc.file !== "string") {
            LabReportData.append("files", doc.file);
          }
        });

        console.log(LabReportData, "mult");

        let result = await API.PostData(
          "Files/MultipleUploadFiles?type=sr&itemid=" + state.data.id,
          LabReportData
        );
        console.log("mult", result);
      }
    } catch (err) {
      console.log("err");
    } finally {
      OnSubmit();
    }

    // return;
  };

  // SUBMIT METHOD //
  const OnSubmit = async () => {
    // console.log('##submitted##', state.data)
    try {
      setProgress(true);
      // save the lab report
      // let labReportId = null;
      // if (labReport.binary) {
      //   labReportId = await API.fileUpload(labReport.binary);
      // }

      // Delete Images
      if (DeleteArrayImages.length) {
        let tempRes = await API.DeleteData("Files/Delete", DeleteArrayImages);
        console.log("test", tempRes);
      }
      //

      // check
      if (!props.match.params.id) {
        // i.e. add mode
        state.data.status = false;
      }

      //
      if (state.data.volumePerYear == "" || state.data.volumePerYear == null) {
        delete state.data.volumePerYear;
      }
      if (state.data.competitiveProductPrice == "") {
        state.data.competitiveProductPrice = 1;
      }
      if (!state.data.targetCloseDate) {
        state.data.targetCloseDate = "0001-01-01T00:00:00.000Z";
      }
      if (!state.data.actualCloseDate) {
        state.data.actualCloseDate = "0001-01-01T00:00:00.000Z";
      }
      //
      let result = await API.submitRequest({
        ...state.data,
        fileId: 0,
      });
      const imageClone = [...images];
      const uploadTasks = [];
      imageClone.forEach((val) => {
        console.log("inserting image promise");
        if (val.binary) {
          console.log("binary val", val.binary);

          uploadTasks.push(API.fileUpload(val.binary));
        }
      });
      for await (let task of uploadTasks) {
        console.log("awaiting image promise", task);
        // save the id
        await API.saveServiceRequestImages([
          { ServiceRequestId: result.data.data.id, FileId: task.data.data.id },
        ]);
      }

      console.log("##submit##", result.data);
      if (result) {
        util.hideLoader();
      }
      if (result.data.data) {
        util.ShowNotification(result.data.message);
        props.history.push("/service-request");
      } else {
        setError(result.data.message);
      }
    } catch (error) {
      util.hideLoader();
    } finally{
      setProgress(false);
    }
  };
  // //

  // GOBACK METHOD
  const GoBack = (e) => {
    props.history.push("/service-request");
  };

  // DELETE METHOD
  const onDelete = async (e) => {
    setOpen(false);
    try {
      setProgress(true);
      util.showLoader();
      let id = props.match.params.id;
      let result = await API.deleteRequest(id);
      // console.log('##submit##', result.data)
      if (result) util.hideLoader();
      util.ShowNotification("Request Deleted");
      props.history.push("/service-request");
    } catch (error) {
      util.hideLoader();
    } finally{
      setProgress(false);
    }
  };

  // CONFIRM POPOVER METHODS //
  const onAlert = (e) => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  // //

  const GetValues = (data) => {
    if (data == null || data == undefined) {
      return null;
    }
    data = data.replace(/[^A-Za-z',\s\d]/g, "").split(",");
    console.log("rs", data);
    if (data.length == 1 && data[0] == "") {
      return null;
    }
    return data.map((val) => {
      return {
        label: val,
        value: val,
      };
    });
  };

  const HandleMultiSelectChange = async (data, event) => {
    console.log(data, event, state, "test");
    let text = [];

    if (data != null) {
      data.forEach((val) => {
        text.push(val.label);
      });
    }

    text = JSON.stringify(text);
    setState({
      ...state,
      data: {
        ...state.data,
        [event.name]: text,
      },
    });
  };

  const RemoveDoc = (id) => {
    if (LabFlag == 0) {
      LabFlag = 1;
    }
    // REMOVE CHECK
    if (typeof LabDocuments[id].file == "string") {
      let remove = state.data.uploadFilesList.find((ele) => {
        return ele.fileUrl == LabDocuments[id].file;
      });
      console.log("mult rm when rm", remove);
      if (remove != undefined) {
        DeleteArray.push(remove.id);
      }
    }
    //
    LabDocuments[id].file = "";
    LabDocuments[id].preview = "";
    LabDocuments[id].label = "Upload document";
    SetLabDocuments([...LabDocuments]);
  };

  const HandleLabDoc = (file, id) => {
    if (file == undefined) {
      return;
    }
    if (LabFlag == 0) {
      LabFlag = 1;
    }
    // REMOVE CHECK
    if (
      typeof LabDocuments[id].file == "string" &&
      LabDocuments[id].file.length > 0
    ) {
      let remove = state.data.uploadFilesList.find((ele) => {
        return ele.fileUrl == LabDocuments[id].file;
      });
      console.log("mult rm change", remove);
      if (remove != undefined) {
        DeleteArray.push(remove.id);
      }
    }
    //
    LabDocuments[id].file = file;
    LabDocuments[id].preview = URL.createObjectURL(file);
    LabDocuments[id].label = file.name;
    SetLabDocuments([...LabDocuments]);
  };

  const RemoveImage = (data) => {
    let index = DeleteArrayImages.indexOf(data.fileId);
    if (index == -1) {
      DeleteArrayImages.push(data.fileId);
    }

    const cloneImages = [...images];
    const myImageIdx = cloneImages.findIndex((val) => {
      return val.id === data.id;
    });
    if (myImageIdx != -1) {
      data.imageShown.imageUrl = "";
      cloneImages[myImageIdx] = data;
      setImages([...cloneImages]);
    }
  };

  const PrintCmd = () => {
    SetPrintFlash(false);
    setTimeout(() => {
      window.print();
      SetPrintFlash(true);
    }, 250);
  };

  const AddCustomer = async (CustName) => {
    try {
      if (CustomerError) {
        SetCustomerError(false);
      }
      let result = await API.PostData(
        "Customer/AddCustomer?customerName=" + CustName,
        {}
      );
      console.log(
        "result from add custoner",
        result,
        result.data && typeof result.data == "string",
        result.data,
        typeof result.data == "string"
      );
      if (result.data && typeof result.data == "string") {
        util.ShowNotification(
          "Customer added successfully. Please choose from drop down."
        );
      } else {
        util.ShowNotification(
          "Something went wrong could not add the customer."
        );
        throw result;
      }
    } catch (err) {
      console.log(err, "err");
    } finally {
      // done
    }
  };

  return (
    <div>
      {PrintFlash ? (
        <>
          <SideMenu />
          <div class="dashboard">
            <Navbar />
            <section id="contents">
              <ImageLoader />
              <section class="statis text-center">
                <Confirm
                  handleClose={handleClose}
                  open={open}
                  onDelete={onDelete}
                />
                <div class="container-fluid">
                  <div class="user_section complaints">
                    <div class="row margin-bottom">
                      <div class="col-md-12 text-left">
                        <h5 class="heading">
                          {EditMode ? "Edit " : "Add "}Request
                          {EditMode ? (
                            <span onClick={PrintCmd}>
                              <i class="fas fa-print"></i> Print
                            </span>
                          ) : null}
                        </h5>
                      </div>
                    </div>

                    <form onSubmit={HandleLabThenSave}>
                      {/* V2 */}
                      {/* ROW DISABLED VIEWABLE IN EDIT */}
                      {EditMode ? (
                        <div class="row margin-bottom">
                          {/* <div class="col-md-4 text-left">
                        <div class="row">
                          <div class="col-md-4">
                            <span>ID</span>
                          </div>
                          <div class="col-md-8">
                            <input disabled value={state.data.id} type="text" />
                          </div>
                        </div>
                      </div> */}
                          <div class="col-md-4 text-left">
                            <div class="row">
                              <div class="col-md-4">
                                <span>TSR No.</span>
                              </div>
                              <div class="col-md-8">
                                <input
                                  disabled
                                  value={state.data.tsrNo}
                                  type="text"
                                  placeholder=""
                                />
                              </div>
                            </div>
                          </div>
                          <div class="col-md-4 text-left">
                            <div class="row">
                              <div class="col-md-4">
                                <span>Date</span>
                              </div>
                              <div class="col-md-8">
                                <input
                                  onChange={HandleChange}
                                  name="date"
                                  disabled={EditMode}
                                  value={
                                    props.match.params.id
                                      ? state.data.date
                                        ? util.localDate(state.data.date)
                                        : null
                                      : null
                                  }
                                  type={EditMode ? "text" : "date"}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {/* END */}
                      {/* ROW 1 */}
                      <div className="row margin-bottom">
                        <div class="col-md-4 text-left">
                          <div class="row">
                            <div class="col-md-4">
                              <span>TSR Type</span>
                            </div>
                            <div class="col-md-8">
                              <select
                                name="tsrType"
                                value={state.data.tsrType}
                                onChange={HandleSelectChange}
                                id="tsrType"
                              >
                                <option value="" hidden selected>
                                  Select TSR Type
                                </option>
                                {
                                  TSRTypes.map((item)=>{
                                    return <option value={item.value}>{item.label}</option>;
                                  })
                                }
                              </select>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4 text-left">
                          <div class="row">
                            <div class="col-md-4">
                              <span>TSR Assigned to:</span>
                            </div>
                            <div class="col-md-8">
                              <select
                                name="assignedTo"
                                value={state.data.assignedTo}
                                onChange={HandleSelectChange}
                                id="assignedTo"
                              >
                                <option value="" hidden selected>
                                  Select Assigned To
                                </option>
                                {
                                  Assignees.map((item)=>{
                                    return <option value={item.value}>{item.label}</option>;
                                  })
                                }
                              </select>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4 text-left">
                          <div class="row">
                            <div class="col-md-4">
                              <span>Product Type</span>
                            </div>
                            <div class="col-md-8">
                              <select
                                name="productType"
                                value={state.data.productType}
                                onChange={HandleSelectChange}
                                id="productType"
                              >
                                <option value="" hidden selected>
                                  Select Product Type
                                </option>
                                {
                                  productType.map((item)=>{
                                    return <option value={item.value}>{item.label}</option>;
                                  })
                                }
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* ROW 1 END */}
                      {/* ROW 2 */}
                      <div class="row margin-bottom">
                        <div class="col-md-4 text-left">
                          <div class="row">
                            <div class="col-md-4">
                              <span>Lab Report Required</span>
                            </div>
                            <div class="col-md-8">
                              <select
                                name="labReportRequired"
                                value={state.data.labReportRequired}
                                onChange={HandleSelectChange}
                                id="labReportRequired"
                              >
                                <option value="" hidden selected>
                                  Select Requirement
                                </option>
                                {labReportRequirement.map((item, idx) => {
                                  return (
                                    <option key={idx} value={item}>
                                      {item}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          </div>
                        </div>

                        <div class="col-md-4 text-left">
                          <div className="row">
                            <div class="col-md-4">
                              <span>Customer</span>
                            </div>
                            <div class="col-md-8">
                              <AutoSearchCtrl
                                CallBack={(selection) => {
                                  console.log("check", selection);
                                  SetCustomerError(false);
                                  if (!selection || !selection.label) {
                                    selection = {
                                      value: null,
                                      label: null,
                                    };
                                    // return;
                                  }
                                  setState((prevState) => {
                                    let obj = Object.assign({}, prevState);
                                    obj.data.customerId = selection.value;
                                    obj.data.customerName = selection.label;
                                    return obj;
                                  });
                                }}
                                InitVal={(fn) => {
                                  cb = fn;
                                }}
                                Placeholder={"Customer"}
                                name={"customerId"}
                                endpoint={"Customer/GetCustomers"}
                                required={true}
                                AddCustomer={(CustName) => {
                                  console.log("new customer", CustName);
                                  AddCustomer(CustName);
                                }}
                              />
                              {CustomerError ? (
                                <div className="text-danger">
                                  Required Field
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>

                        <div class="col-md-4 text-left">
                          <div class="row">
                            <div class="col-md-4">
                              <span>Sales Owner</span>
                            </div>
                            <div class="col-md-8">
                              <select
                                name="salesOwner"
                                value={state.data.salesOwner}
                                onChange={HandleSelectChange}
                                id="salesOwner"
                              >
                                <option value="" hidden selected>
                                  Select Sales Owner
                                </option>
                                {salesOwner.map((item, idx) => {
                                  return (
                                    <option
                                      key={idx}
                                      value={`${item.firstName} ${item.lastName}`}
                                    >
                                      {`${item.firstName} ${item.lastName}`}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* ROW 2 END */}
                      {/* row */}
                      <div className="row margin-bottom">
                        <div class="col-md-6 text-left">
                          <div class="row">
                            <div class="col-md-4">
                              <span>Products</span>
                            </div>
                            <div class="col-md-8">
                              <Select
                                className="basic-single"
                                placeholder="Products"
                                classNamePrefix="select"
                                options={productList}
                                isMulti={true}
                                onChange={HandleMultiSelectChange}
                                name="product"
                                value={GetValues(state.data.product)}
                                styles={customStyles}
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6 text-left">
                          <div class="row">
                            <div class="col-md-4">
                              <span>Metal Type</span>
                            </div>
                            <div class="col-md-8">
                              <Select
                                className="basic-single"
                                placeholder="Metal Type"
                                classNamePrefix="select"
                                options={DropDownData.metaL_type_opt}
                                isMulti={true}
                                onChange={HandleMultiSelectChange}
                                name="metalType"
                                value={GetValues(state.data.metalType)}
                                styles={customStyles}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* row end */}
                      {/* row */}
                      <div className="row margin-bottom">
                        <div class="col-md-6 text-left">
                          <div class="row">
                            <div class="col-md-4">
                              <span>Process</span>
                            </div>
                            <div class="col-md-8">
                              <Select
                                className="basic-single"
                                placeholder="Process"
                                classNamePrefix="select"
                                options={DropDownData.process}
                                isMulti={true}
                                onChange={HandleMultiSelectChange}
                                name="process"
                                value={GetValues(state.data.process)}
                                styles={customStyles}
                              />
                            </div>
                          </div>
                        </div>

                        <div class="col-md-6 text-left">
                          <div class="row">
                            <div class="col-md-4">
                              <span>Sub Process</span>
                            </div>
                            <div class="col-md-8">
                              <Select
                                className="basic-single"
                                placeholder="Sub Process"
                                classNamePrefix="select"
                                options={DropDownData.subprocess}
                                isMulti={true}
                                onChange={HandleMultiSelectChange}
                                name="processType"
                                value={GetValues(state.data.processType)}
                                styles={customStyles}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* row end */}
                      {/* row */}
                      <div class="row margin-bottom">
                        {!util.IsClient() ? (
                          <div class="col-md-4 text-left">
                            <div class="row">
                              <div class="col-md-4">
                                <span>Target Close Date</span>
                              </div>
                              <div class="col-md-8">
                                <input
                                  name="targetCloseDate"
                                  type={"date"}
                                  value={state.data.targetCloseDate}
                                  onChange={HandleChange}
                                />
                              </div>
                            </div>
                          </div>
                        ) : null}
                        {!util.IsClient() ? (
                          <div class="col-md-4 text-left">
                            <div class="row">
                              <div class="col-md-4">
                                <span>Actual Close Date</span>
                              </div>
                              <div class="col-md-8">
                                <input
                                  name="actualCloseDate"
                                  type={"date"}
                                  value={state.data.actualCloseDate}
                                  onChange={HandleChange}
                                />
                              </div>
                            </div>
                          </div>
                        ) : null}
                      </div>
                      {/* row end */}
                      {/* ROW 3 */}
                      <div class="row margin-bottom">
                        {/* <div class="col-md-4 text-left">
                          <div class="row">
                            <div class="col-md-4">
                              <span>Requested Completion Date</span>
                            </div>
                            <div class="col-md-8">
                              <DatePicker
                                dateFormat="MMM dd, yyyy"
                                selected={
                                  new Date(state.data.requestedCompletionDate)
                                }
                                onChange={(date) => {
                                  console.log("ok", date);
                                  setState((prevState) => {
                                    let obj = Object.assign({}, prevState);
                                    obj.data.requestedCompletionDate =
                                      date.toISOString();
                                    return obj;
                                  });
                                }}
                              />
                            </div>
                          </div>
                        </div> */}
                        <div class="col-md-4 text-left">
                          <div class="row">
                            <div class="col-md-4">
                              <span>Severity Level</span>
                            </div>
                            <div class="col-md-8">
                              <select
                                name="severityLevel"
                                value={state.data.severityLevel}
                                onChange={HandleSelectChange}
                                id="cars"
                              >
                                <option value="" selected hidden>
                                  Select serverity level
                                </option>
                                {severity.map((item) => {
                                  return <option value={item}>{item}</option>;
                                })}
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* ROW 3 END */}
                      {/* ROW 4 */}
                      <div class="row margin-bottom">
                        <div class="col-md-4 text-left">
                          <div class="row">
                            <div class="col-md-4">
                              <span for="birthday">Customer Part Type</span>
                            </div>
                            <div class="col-md-8">
                              <input
                                name="customerPartType"
                                onChange={HandleChange}
                                value={state.data.customerPartType}
                                type="text"
                                placeholder="Customer Part Type"
                              />
                            </div>
                          </div>
                        </div>

                        <div class="col-md-4 text-left">
                          <div class="row">
                            <div class="col-md-4">
                              <span for="birthday">Competitor Products</span>
                            </div>
                            <div class="col-md-8">
                              <input
                                name="competitiveProduct"
                                onChange={HandleChange}
                                value={state.data.competitiveProduct}
                                type="text"
                                placeholder="Competitor Products"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* ROW 4 END */}
                      {/* ROW 5 */}
                      <div className="row margin-bottom">
                        <div class="col-md-4 text-left">
                          <div class="row">
                            <div class="col-md-4">
                              <span for="birthday">Competitor Price</span>
                            </div>
                            <div class="col-md-8">
                              <input
                                name="competitiveProductPrice"
                                onChange={HandleChange}
                                value={state.data.competitiveProductPrice}
                                type="text"
                                placeholder="Competitor Price"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4 text-left">
                          <div class="row">
                            <div class="col-md-4">
                              <span>Competitor Concentration</span>
                            </div>
                            <div class="col-md-8">
                              <input
                                onChange={HandleChange}
                                name="concentrationUsed"
                                value={state.data.concentrationUsed}
                                type="text"
                                placeholder="Competitor Concentration"
                              />{" "}
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4 text-left">
                          <div class="row">
                            <div class="col-md-4">
                              <span>Volume Per Year(Gal)</span>
                            </div>
                            <div class="col-md-8">
                              <input
                                onChange={HandleChange}
                                name="volumePerYear"
                                value={state.data.volumePerYear}
                                type="text"
                                placeholder="Volume Per Year(Gal)"
                              />{" "}
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* ROW 5 END */}
                      {/* ROW 6 */}
                      <div class="row margin-bottom">
                        {/* <div class="col-md-4 text-left">
                      <div class="row">
                        <div class="col-md-4">
                          <span>Water Quality</span>
                        </div>
                        <div class="col-md-8">
                          <input
                            onChange={HandleChange}
                            name="waterQuality"
                            value={state.data.waterQuality}
                            type="text"
                            placeholder=""
                          />{" "}
                        </div>
                      </div>
                    </div> */}
                        {/* Status */}
                        {EditMode && (util.isAdmin() || util.isLab()) ? (
                          <div class="col-md-4 text-left">
                            <div class="row">
                              <div class="col-md-4">
                                <span>Status</span>
                              </div>
                              <div class="col-md-8">
                                <select
                                  name="status"
                                  value={state.data.status}
                                  onChange={HandleSelectChange}
                                >
                                  <option value="" selected hidden>
                                    Select Status
                                  </option>
                                  <option value={false}>{"Open"}</option>
                                  <option value={true}>{"Close"}</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        ) : null}
                      </div>
                      {/* ROW 6 END */}
                      {/* ROW */}
                      <div className="row margin-bottom">
                        <div class="col-md-8 text-left">
                          <div class="row">
                            <div class="col-md-2">
                              <span>Water Quality</span>
                            </div>
                            <div class="col-md-10">
                              <textarea
                                rows="5"
                                onChange={HandleChange}
                                placeholder="Water Quality"
                                value={state.data.waterQuality}
                                name="waterQuality"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* ROW END */}
                      {/* ROW 7 */}
                      <div class="row margin-bottom">
                        <div class="col-md-8 text-left">
                          <div class="row">
                            <div class="col-md-2">
                              <span for="birthday">
                                Detailed Request Objective
                              </span>
                            </div>
                            <div class="col-md-10">
                              <textarea
                                rows="5"
                                onChange={HandleChange}
                                placeholder="Detailed Request Objective"
                                value={state.data.detailedRequestObjective}
                                name="detailedRequestObjective"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* ROW 7 END */}
                      {/* ROW 8 */}
                      <div className="row margin-bottom">
                        <div class="col-md-8 text-left">
                          <div class="row">
                            <div class="col-md-2">
                              <span for="birthday">
                                Customer Switching Reason
                              </span>
                            </div>
                            <div class="col-md-10">
                              <textarea
                                rows="5"
                                onChange={HandleChange}
                                placeholder="Customer Switching Reason"
                                value={state.data.customerSwitching}
                                name="customerSwitching"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* ROW 8 END */}
                      {/* ROW 9 */}
                      <div class="row margin-bottom">
                        <div class="col-md-8 text-left">
                          <div class="row">
                            <div class="col-md-2">
                              <span for="birthday">Lab Analysis Recomm.</span>
                            </div>
                            <div class="col-md-10">
                              <textarea
                                name="labAnalysisRecommendations"
                                onChange={HandleChange}
                                value={state.data.labAnalysisRecommendations}
                                placeholder="Lab Analysis Recomm."
                                rows={5}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* ROW 9 END */}
                      {/* ROW 10 */}
                      <div class="row margin-bottom">
                        <div class="col-md-8 text-left">
                          <div class="row">
                            <div class="col-md-2">
                              <span for="birthday">Current Status</span>
                            </div>
                            <div class="col-md-10">
                              <textarea
                                name="currentStatus"
                                onChange={HandleChange}
                                value={state.data.currentStatus}
                                placeholder="Current Status"
                                rows={5}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* ROW 10 END */}
                      {/* LAB REPORT ROW */}
                      <div className="row margin-bottom">
                        {/* Lab Report */}
                        {util.getUserRole() !== "CLIENT" &&
                        EditMode &&
                        state.data.labReportRequired != null &&
                        state.data.labReportRequired.includes("Yes") ? (
                          <div class="col-md-8 text-left">
                            <div class="row">
                              <div class="col-md-2">
                                <span>Lab Report</span>
                              </div>
                              <div class="col-md-10">
                                <div className="lab-files">
                                  {LabDocuments.map((data, idx) => {
                                    return (
                                      <div className="selected-file">
                                        <label>
                                          <input
                                            type="file"
                                            style={{ display: "none" }}
                                            onChange={(evt) => {
                                              let file = evt.target.files[0];
                                              HandleLabDoc(file, idx);
                                            }}
                                            multiple={false}
                                          />
                                          <span>{data.label}</span>
                                        </label>
                                        {data.file != "" ? (
                                          <>
                                            <span
                                              className="options"
                                              onClick={() => {
                                                RemoveDoc(idx);
                                              }}
                                            >
                                              Remove
                                            </span>
                                            <span className="options view-doc">
                                              <a
                                                href={data.preview}
                                                target={"_blank"}
                                              >
                                                View
                                              </a>
                                            </span>
                                          </>
                                        ) : null}
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : null}
                      </div>
                      {/* END */}
                      {/*  */}

                      <div className="row margin-bottom">
                        <div className="col-md-12 text-left">
                          {images
                            ? images.map((val) => {
                                return (
                                  <span className="img-container">
                                    <Upload
                                      id={val.id}
                                      name={val.name}
                                      onSelect={(e) => {
                                        HandleFileChooser(e, val.id);
                                      }}
                                      imageShown={val.imageShown}
                                    >
                                      {/* <i class="fas fa-times" aria-hidden="true"></i> */}
                                    </Upload>
                                    {val.imageShown.imageUrl ? (
                                      <div className="file-uploader-add-icon">
                                        <i
                                          onClick={() => {
                                            RemoveImage(val);
                                          }}
                                          class="fas fa-times-circle"
                                          aria-hidden="true"
                                        ></i>
                                      </div>
                                    ) : null}
                                  </span>
                                );
                              })
                            : null}
                        </div>
                      </div>

                      {err ? (
                        <div
                          style={{
                            color: "red",
                            fontSize: "1.6rem",
                          }}
                        >
                          {"Error: " + err}
                        </div>
                      ) : null}

                      <div class="row margin-bottom">
                        <div class="col-md-12 text-left">
                          <div class="row">
                            <div class="col-md-12">
                              <div class="buttons">
                                <button type="submit" class="update" disabled={progress}>
                                  {EditMode ? "Update" : "Add"}
                                </button>
                                <button
                                  type="button"
                                  onClick={GoBack}
                                  class="cancel"
                                >
                                  Cancel
                                </button>
                                {util.isAdmin() && EditMode ? (
                                  <i
                                    onClick={onAlert}
                                    class="fa fa-trash"
                                    aria-hidden="true"
                                  ></i>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </section>
            </section>
          </div>
          {showSelectors.process ||
          showSelectors.processType ||
          showSelectors.product ||
          showSelectors.metalType ? (
            <div
              className="backdrop"
              onClick={() => {
                DisableBackDrop();
              }}
            ></div>
          ) : null}
        </>
      ) : (
        <ServiceRequestPrintForm Data={state} />
      )}
    </div>
  );
};
export default withRouter(ServiceDetails);
