import React, { Component } from "react";
import Navbar from "../../containers/navbar";
import ImageLoader from "../../containers/loader";
import SideMenu from "../../containers/sidemenu";
import * as API from "../../services/api";
import * as util from "../../Util";
import Pagination from "react-js-pagination";
import { withRouter } from "react-router-dom";
class Report8D extends Component {
  constructor(props) {
    super(props);
    this.state = {
      IsOpen: false,
      reports: [],
      modalData: null,
      title: "",
      message: "",
      openResults: [],
      closeResults: [],
      filteredData: [],
      Inprogress: false,
      activePage: 1,
      APIPageNumber: 1,
      APIPageSize: 100,
      SearchText: "",
      status: "All",
      latestSearch: undefined,
    };
  }
  componentDidMount() {
    this.GetData();
  }

  GetData = async () => {
    if (util.isLoggedIn() === false) {
      this.props.history.push("/login");
    }
    util.showLoader();
    try {
      let result = await API.GetData(
        `ResponseReport/GetAll?pagenumber=${this.state.APIPageNumber}&pagesize=${this.state.APIPageSize}`
      );
      console.log(result);

      if (result.data !== null) {
        result = result.data.items;
        var reports = await result.filter((report) => {
          return report.status;
        });

        if (result.length < this.state.APIPageSize) {
          this.state.APIPageNumber = null;
        } else {
          this.state.APIPageNumber += 1;
        }

        console.log(reports);
        this.setState(
          {
            reports: this.state.reports.concat(result),
            filteredData: this.state.reports.concat(result),
            message: "No 8D Report",
            openResults: reports,
          },
          () => {
            util.hideLoader();
            console.log(this.state.activePage);
            this.setState({
              ...this.state,
              activePage: this.state.activePage,
            });
          }
        );
      } else {
        throw result;
      }
    } catch (err) {
      this.setState({
        ...this.state,
        feedbacks: [],
        loaded: true,
        message: "No Reports",
      });
      util.hideLoader();
    } finally {
      this.setState(
        {
          Inprogress: false,
        });
    }
  };

  onDropDownChange = async (e) => {
    let value = e.target.value;

    try {
      this.setState(
        {
          ...this.state,
          APIPageNumber: 1,
          activePage: 1,
          reports: [],
          filteredData: [],
          status: value,
        },
        () => {
          this.GetData();
        }
      );
    } catch (err) {
    } finally {
      this.setState({
        activePage: 1,
      });
    }
  };
  openEdit = (item) => {
    let id = item.id;
    this.props.history.push(`/8d-report-detail/${id}`);
  };

  SearchDebounce;
  onSearch = async (e, IsCustomSearch = false) => {
    if (this.SearchDebounce) {
      clearInterval(this.SearchDebounce);
    }
    let value;
    if (!IsCustomSearch) {
      value = e.target.value;
    } else {
      value = e;
    }
    let temp = Math.random();
    this.state.latestSearch = temp;

    this.SearchDebounce = setTimeout(async () => {
      util.showLoader();

      if (value.trim() === "") {
        this.setState(
          {
            APIPageNumber: 1,
            activePage: 1,
            reports: [],
            filteredData: [],
            SearchText: "",
          },
          () => {
            this.GetData();
          }
        );
        return;
      }

      try {
        let result = await API.Search8DReports(value, IsCustomSearch);
        if (this.state.latestSearch != temp) {
          return;
        }

        if (result.data != null) {
          this.setState(
            {
              reports: result.data.data.items,
              filteredData: result.data.data.items,
              SearchText: value,
              activePage: 1,
              APIPageNumber: 1,
              status: "All",
            });
        } else {
          throw "err";
        }
      } catch (error) {
      } finally {
        util.hideLoader();
      }
    }, 400);
  };

  handlePageChange = (pageNumber) => {
    console.log(`active page is ${pageNumber}`);
    this.setState({ activePage: pageNumber });

    if (this.state.SearchText != "") {
      return;
    }

    if (this.state.APIPageNumber != null && !this.state.Inprogress) {
      if (this.state.filteredData.length - pageNumber * 12 <= 30) {
        this.state.Inprogress = true;
        this.GetData();
      }
    }
  };

  OnClose = () => {
    console.log("popup close");
    this.setState({
      IsOpen: false,
    });
  };


  render() {
    return (
      <div>
        <SideMenu />
        <div class="dashboard complaints-section">
          <Navbar onSearch={this.onSearch} Heading={"8D Report"} />
          <section id="contents">
            <ImageLoader />
            <section class="statis text-center">
              <div class="container-fluid">
                <div class="row"></div>

                <div class="row user_section">
                  <div>
                    <div class="col-md-3 text-left">
                      <button
                        onClick={() => {
                          this.props.history.push("/8d-report-detail");
                        }}
                        class="btn btn-primary add-btn "
                      >
                        ADD
                      </button>
                    </div>

                    <div class="form-group statusSelect">
                      {/* <select
                        className="statusDropwdown"
                        id="viewselect"
                        onChange={this.onDropDownChange}
                        value={this.state.status}
                      >
                        <option value={"All"} selected>
                          All
                        </option>
                        <option value={"Open"}>Open</option>
                        <option value={"Close"}>Closed</option>
                      </select> */}
                    </div>
                  </div>
                  <div class="col-md-12">
                    {/* <DetailModal data={this.state.modalData} title={this.state.title} /> */}
                    {this.state.reports.length !== 0 ? (
                      <div>
                        {this.state.filteredData
                          .filter((val, idx) => {
                            let low, high;
                            low = this.state.activePage * 12 - 12;
                            high = this.state.activePage * 12;
                            console.log(low, high, "pagi");
                            if (idx >= low && idx < high) return true;
                            else return false;
                          })
                          .map((item, index) => {
                            return (
                              <div
                                class="col-md-4"
                                onClick={() => this.openEdit(item)}
                              >
                                <div class="section_second list-view report-card">
                                  {/* v2 */}
                                  <div className="cust-row">
                                    <img src="/images/8d-blue.png" alt="" />
                                    <h4>
                                      <strong>
                                        8DRNo#
                                        {item.id}
                                      </strong>
                                    </h4>
                                  </div>
                                  {/*  */}
                                  <div className="cust-row-no-flex">
                                    <div class="cust-col-50 flex-dr-col-baseline">
                                      <span>Company</span>
                                      <label title={item.company}>
                                        {util.checkEmpty(item.company)}
                                      </label>
                                    </div>
                                    <div class="cust-col-50 flex-dr-col-baseline">
                                      <span>Date</span>
                                      <label>
                                        {item.dateOpen
                                          ? util.localDate(item.dateOpen)
                                          : "NA"}
                                      </label>
                                    </div>
                                    <div class="cust-col-50 flex-dr-col-baseline">
                                      <span>Team Members</span>
                                      <label title={item.teamMembers}>
                                        {!item.teamMembers
                                          ? "NA"
                                          : item.teamMembers}
                                      </label>
                                    </div>

                                    <div class="cust-col-50 flex-dr-col-baseline">
                                      <span>Product Name</span>
                                        <label title={item.productName}>
                                          {util.checkEmpty(item.productName)}
                                        </label>
                                    </div>

                                    <div class="cust-col-50 flex-dr-col-baseline">
                                        <span>Target Close Date</span>
                                        <label>
                                          {item.targetCloseDate
                                            ? util.localDate(
                                                item.targetCloseDate
                                              )
                                            : "NA"}
                                        </label>
                                      </div>

                                      <div class="cust-col-50 flex-dr-col-baseline">
                                        <span>Actual Close Date</span>
                                        <label>
                                          {item.actualCloseDate
                                            ? util.localDate(
                                                item.actualCloseDate
                                              )
                                            : "NA"}
                                        </label>
                                      </div>

                                  </div>
                                  {/*  */}
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    ) : (
                      <React.Fragment>{this.state.message}</React.Fragment>
                    )}
                  </div>
                </div>
                {this.state.filteredData.length != 0 ? (
                  <div className="row">
                    <Pagination
                      hideFirstLastPages
                      prevPageText="Prev"
                      nextPageText="Next"
                      activePage={this.state.activePage}
                      itemsCountPerPage={12}
                      totalItemsCount={this.state.filteredData.length}
                      pageRangeDisplayed={5}
                      onChange={this.handlePageChange}
                      className="custom_pagination"
                      innerClass="custom_pagination"
                    />
                  </div>
                ) : null}
              </div>
            </section>
          </section>
        </div>
      </div>
    );
  }
}

export default withRouter(Report8D);