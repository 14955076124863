import React, { useState, useEffect } from "react";
import * as util from "../../Util";
import * as API from "../../services/api";
// import ComplaintEdit from './ComplaintEdit';
import $ from "jquery";
import SideMenu from "../../containers/sidemenu";
import Navbar from "../../containers/navbar";
import ImageLoader from "../../containers/loader";
import Confirm from "../../containers/Confirm";
import moment from "moment";

let initialValues = {
  roleId: 0,
  firstName: "",
  lastName: "",
  dob: "",
  email: "",
  address: "",
  contactNumber: "",
  isBlocked: "",
  image: "",
  password: "",
  isAdmin: false,
  roleName: ""
};

const UserDetail = props => {
  // let severity = ['high', 'low', 'medium']
  const [Inprogress, SetInprogress] = useState(false);
  const [err, setError] = useState(null);
  const [open, setOpen] = useState(false);
  const [state, setState] = useState({
    data: { ...initialValues },
    loaded: false
  });
  const [editMode, setEditMode] = useState(false);
  const [Roles, setRoles] = useState([]);
  const status = [
    { name: "Active", value: true },
    { name: "Inactive", value: false }
  ];

  useEffect(() => {
    // console.log('new props', props)
    if (util.isLoggedIn() === false) {
      props.history.push("/login");
    }

    initData();

    // setState((prevState) => {
    //   let obj = Object.assign({}, prevState);
    //   obj.data = props.data;
    //   obj.loaded = true;
    //   return obj;
    // });
  }, [props]);

  const loadUser = async () => {
    let { id } = props.match.params;

    if (id && id.trim().length > 0) {
      setEditMode(true);

      id = Number(id);

      try {
        let result = await API.getUserById(id);

        console.log(result.data.data, state);

        //   let sLevel = result.data.data.severityLevel.toLowerCase();
        //   // console.log('##user##', sLevel)
        //   if (!severity.includes(sLevel)) {
        //       severity.push(sLevel)
        //   }
        setState(prevState => {
          let obj = Object.assign({}, prevState);
          obj.data = result.data.data;
          obj.data.password = "";
          // obj.data.dob = moment(obj.data.dob).format("YYYY-MM-DD");
          // if (obj.data.dob == "Invalid date") {
          //   obj.data.dob = "";
          // }
          obj.loaded = true;
          return obj;
        });
        console.log("updated state", state);
        util.hideLoader();
      } catch (error) {
        setState({ ...state, loaded: true });
        util.hideLoader();
      }
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onDelete = async e => {
    setOpen(false);
    try {
      SetInprogress(true);
      util.showLoader();
      let id = props.match.params.id;
      let result = await API.deleteUser(id);
      // console.log('##submit##', result.data)
      if (result) util.hideLoader();
      util.ShowNotification("User Deleted");
      props.history.push("/users");
    } catch (error) {
      util.hideLoader();
    } finally {
      SetInprogress(false);
    }
  };

  const onSubmit = async e => {
    e.preventDefault();
    setError(null);
    // console.log('##submitted##', state.data)
    try {
      // util.showLoader();
      SetInprogress(true);
      let role = Roles.find(role => role.id == state.data.roleId);

      if (role != -1) {
        state.data.roleName = role.name;
        state.data.isAdmin = role.name === "ADMIN";

        // setState((prevState) => {
        //   return {
        //     ...prevState,
        //     data: {
        //       ...prevState.data,
        //       roleName: role.name,
        //       isAdmin: role.name == "ADMIN",
        //     },
        //   };
        // });
      }

      let user = {
        RoleId: Number(state.data.roleId),
        FirstName: state.data.firstName,
        LastName: state.data.lastName,
        Email: state.data.email,
        Password: state.data.password,
        Address: "",
        ContactNumber: state.data.contactNumber,
        IsBlocked: state.data.isBlocked,
        Image: state.data.image,
        RoleName: state.data.roleName
      };
      // if (state.data.dob != "") {
      //   user.DOB = new Date(state.data.dob).toISOString();
      // } else {
      // }
      user.DOB = "";
      console.log(user, "post obj");

      if (editMode) {
        user.id = Number(state.data.id);
      }

      let result = await API.saveUser(user);
      console.log("##submitUser##", result.data);
      if (result) {
        util.hideLoader();
      }
      if (result.data.data) {
        util.ShowNotification(result.data.message);
        props.history.push("/users");
      } else {
        setError(result.data.message);
      }
    } catch (error) {
      console.log(error.message, state);
      util.hideLoader();
    } finally {
      SetInprogress(false);
    }
  };

  const onAlert = e => {
    setOpen(true);
    // confirmAlert({
    //     title: '',
    //     message: 'Are you sure to do this.',
    //     closeOnEscape: true,
    //     closeOnClickOutside: true,
    //     buttons: [
    //         {
    //             label: 'Yes',
    //             onClick: () => onDelete()
    //         },
    //         {
    //             label: 'No',
    //             onClick: () => { }
    //         }
    //     ]
    // });
  };

  const onChange = e => {
    let { name, value } = e.target;
    setState(prevState => {
      let obj = Object.assign({}, prevState);
      obj.data[name] = value;
      return obj;
    });
  };

  const onDropDownChange = e => {
    let { name, value } = e.target;
    console.log("name" + name + " " + "value" + value);
    setState(prevState => {
      let obj = Object.assign({}, prevState);
      obj.data[name] = value;
      return obj;
    });
  };

  const onCancel = e => {
    props.history.push("/users");
  };

  const initData = async () => {
    try {
      let Roles = await API.GetAllRoles();
      console.log("roles are ", Roles.data);
      let roles = Roles.data.roles.items.map(role => ({
        id: role.id,
        name: role.displayName
      }));

      Roles = [...roles];
      setRoles(roles);

      loadUser();
    } catch (error) {
      console.log(error.message);
    }
  };
  const onEdit = e => {
    $("#closeComplaint").trigger("click");
    $("#complaintEdit")[0].click();
  };

  return (
    <>
      {/* <div>
      <SideMenu />
      <div class="dashboard">
        <Navbar />
        <section id="contents">
          <section class="statis text-center">
            <div class="container-fluid">
              <div class="user_section complaints">
                <div class="row margin-bottom">
                  <div class="col-md-12 text-left">
                    <h5 class="heading">Edit complaint</h5>
                  </div>
                </div>

                <div class="row margin-bottom">
                  <div class="col-md-4 text-left">
                    <div class="row">
                      <div class="col-md-4">
                        <span>ID</span>
                      </div>
                      <div class="col-md-8">
                        <input type="text" placeholder="A-212123" />
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 text-left">
                    <div class="row">
                      <div class="col-md-4">
                        <span>CC No</span>
                      </div>
                      <div class="col-md-8">
                        <input type="text" placeholder="CC-23213" />
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 text-left">
                    <div class="row">
                      <div class="col-md-4">
                        <span>Complaint ID</span>
                      </div>
                      <div class="col-md-8">
                        <input type="text" placeholder="CC-23213" />
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row margin-bottom">
                  <div class="col-md-4 text-left">
                    <div class="row">
                      <div class="col-md-4">
                        <span>ID</span>
                      </div>
                      <div class="col-md-8">
                        <select name="cars" id="cars">
                          <option value="volvo">Steve Smith</option>
                          <option value="saab">Steve Smith</option>
                          <option value="opel">Steve Smith</option>
                          <option value="audi">Steve Smith</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 text-left">
                    <div class="row">
                      <div class="col-md-4">
                        <span>CC No</span>
                      </div>
                      <div class="col-md-8">
                        <select name="cars" id="cars">
                          <option value="volvo">Steve Smith</option>
                          <option value="saab">Steve Smith</option>
                          <option value="opel">Steve Smith</option>
                          <option value="audi">Steve Smith</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 text-left">
                    <div class="row">
                      <div class="col-md-4">
                        <span>Complaint ID</span>
                      </div>
                      <div class="col-md-8">
                        <select name="cars" id="cars">
                          <option value="volvo">Steve Smith</option>
                          <option value="saab">Steve Smith</option>
                          <option value="opel">Steve Smith</option>
                          <option value="audi">Steve Smith</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row margin-bottom">
                  <div class="col-md-4 text-left">
                    <div class="row">
                      <div class="col-md-4">
                        <span>Associated ICA</span>
                      </div>
                      <div class="col-md-8">
                        <input type="text" placeholder="ICA-23123" />
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row margin-bottom">
                  <div class="col-md-4 text-left">
                    <div class="row">
                      <div class="col-md-4">
                        <span>Product</span>
                      </div>
                      <div class="col-md-8">
                        <select name="loream Ipsum" id="cars">
                          <option value="volvo">loream Ipsum</option>
                          <option value="saab">loream Ipsum</option>
                          <option value="opel">loream Ipsum</option>
                          <option value="audi">loream Ipsum</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row margin-bottom">
                  <div class="col-md-4 text-left">
                    <div class="row">
                      <div class="col-md-4">
                        <span for="birthday">Date</span>
                      </div>
                      <div class="col-md-8">
                        <input type="date" id="birthday" name="birthday" />
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 text-left">
                    <div class="row">
                      <div class="col-md-4">
                        <span for="birthday">Close Date</span>
                      </div>
                      <div class="col-md-8">
                        <input type="date" id="birthday" name="birthday" />
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row margin-bottom">
                  <div class="col-md-8 text-left">
                    <div class="row">
                      <div class="col-md-2">
                        <span for="birthday">Description</span>
                      </div>
                      <div class="col-md-10">
                        <textarea placeholder="loream" />
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row margin-bottom">
                  <div class="col-md-4 text-left">
                    <div class="row">
                      <div class="col-md-4">
                        <span>Product Process</span>
                      </div>
                      <div class="col-md-8">
                        <input type="text" placeholder="Lorem Ipsum" />
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 text-left">
                    <div class="row">
                      <div class="col-md-4">
                        <span>Systemic</span>
                      </div>
                      <div class="col-md-8">
                        <input type="text" placeholder="Lorem Ipsum" />
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row margin-bottom">
                  <div class="col-md-4 text-left">
                    <div class="row">
                      <div class="col-md-4">
                        <span>Escape</span>
                      </div>
                      <div class="col-md-8">
                        <input type="text" placeholder="Lorem Ipsum" />
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 text-left">
                    <div class="row">
                      <div class="col-md-4">
                        <span>Fortech Affected</span>
                      </div>
                      <div class="col-md-8">
                        <input type="text" placeholder="Lorem Ipsum" />
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row margin-bottom">
                  <div class="col-md-4 text-left">
                    <div class="row">
                      <div class="col-md-4">
                        <span>Is Needed CA</span>
                      </div>
                      <div class="col-md-8 radio-button">
                        <label>
                          {" "}
                          <input
                            type="radio"
                            id="yes"
                            name="gender"
                            value="Yes"
                          />
                          <span> Yes</span>
                        </label>
                        <label>
                          {" "}
                          <input
                            type="radio"
                            id="no"
                            name="gender"
                            value="No"
                          />{" "}
                          <span>No</span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row margin-bottom">
                  <div class="col-md-8 text-left">
                    <div class="row">
                      <div class="col-md-2">
                        <span for="birthday">Why No CA</span>
                      </div>
                      <div class="col-md-10">
                        <input type="text" placeholder="Lorem Ipsum" />
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row margin-bottom">
                  <div class="col-md-12 text-left">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="buttons">
                          <button class="update">Update</button>
                          <button class="cancel">Cancel</button>

                          <i class="fa fa-trash-o" aria-hidden="true"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </section>
      </div>
      </div>
       */}

      <div>
        <SideMenu />
        <div class="dashboard">
          <Navbar />
          <section id="contents">
            <ImageLoader />
            <section class="statis text-center">
              <Confirm
                handleClose={handleClose}
                open={open}
                onDelete={onDelete}
              />
              <div class="container-fluid">
                {state.data !== null ? (
                  <div class="user_section complaints">
                    <div class="row margin-bottom">
                      <div class="col-md-12 text-left">
                        <h5 class="heading">
                          {editMode ? "Edit" : "Add"} User
                        </h5>
                      </div>
                    </div>

                    <form onSubmit={onSubmit}>
                      <fieldset disabled={Inprogress}>
                        <div class="row margin-bottom">
                          <div class="col-md-6 text-left">
                            <div class="row">
                              <div class="col-md-3">
                                <span>First Name</span>
                              </div>
                              <div class="col-md-9">
                                <input
                                  value={state.data.firstName}
                                  type="text"
                                  name="firstName"
                                  onChange={onChange}
                                  placeholder="Enter firstname"
                                  required
                                />
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6 text-left">
                            <div class="row">
                              <div class="col-md-3">
                                <span>Last Name</span>
                              </div>
                              <div class="col-md-9">
                                <input
                                  value={state.data.lastName}
                                  type="text"
                                  name="lastName"
                                  onChange={onChange}
                                  placeholder="Enter lastname"
                                  required
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row margin-bottom">
                          <div class="col-md-6 text-left">
                            <div class="row">
                              <div class="col-md-3">
                                <span>Email</span>
                              </div>
                              <div class="col-md-9">
                                <input
                                  value={state.data.email}
                                  type="text"
                                  name="email"
                                  onChange={onChange}
                                  placeholder="Enter email"
                                  required
                                />

                                {/* <select name="cars" id="cars">
                                                    <option value="volvo">Steve Smith</option>
                                                    <option value="saab">Steve Smith</option>
                                                    <option value="opel">Steve Smith</option>
                                                    <option value="audi">Steve Smith</option>
                                                </select> */}
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6 text-left">
                            <div class="row">
                              <div class="col-md-3">
                                <span>Phone Number</span>
                              </div>
                              <div class="col-md-9">
                                <input
                                  value={state.data.contactNumber}
                                  type="text"
                                  name="contactNumber"
                                  onChange={onChange}
                                  placeholder="Enter phone number"
                                  required
                                />

                                {/* <select name="cars" id="cars">
                                                    <option value="volvo">Steve Smith</option>
                                                    <option value="saab">Steve Smith</option>
                                                    <option value="opel">Steve Smith</option>
                                                    <option value="audi">Steve Smith</option>
                                                </select> */}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row margin-bottom">
                          <div class="col-md-6 text-left">
                            <div class="row">
                              <div class="col-md-3">
                                <span>Password</span>
                              </div>
                              <div class="col-md-9">
                                <input
                                  value={state.data.password}
                                  type="password"
                                  name="password"
                                  onChange={onChange}
                                  placeholder="Enter password"
                                  required={!editMode}
                                />
                                {editMode ? (
                                  <small>
                                    Leave field empty if don't want to change
                                    password
                                  </small>
                                ) : null}
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6 text-left">
                            <div class="row">
                              <div class="col-md-3">
                                <span>Role</span>
                              </div>
                              <div class="col-md-9">
                                <select
                                  name="roleId"
                                  value={state.data.roleId}
                                  onChange={onDropDownChange}
                                  id="Role"
                                  required
                                >
                                  <option value="">Select Role</option>
                                  {Roles.map((item, idx) => {
                                    return (
                                      <option key={idx} value={item.id}>
                                        {item.name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* status update */}
                        <div class="row margin-bottom">
                          <div class="col-md-6 text-left">
                            <div class="row">
                              <div class="col-md-3">
                                <span>Status</span>
                              </div>
                              <div class="col-md-9">
                                <select
                                  name="isBlocked"
                                  value={state.data.isBlocked}
                                  onChange={onDropDownChange}
                                  id="isBlocked"
                                  required
                                >
                                  <option value="" hidden selected>
                                    Select Status
                                  </option>
                                  {status.map((item, idx) => {
                                    return (
                                      <option key={idx} value={item.value}>
                                        {item.name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>

                        {err ? (
                          <div className="text-danger">{"Error: " + err}</div>
                        ) : null}

                        <div class="row margin-bottom">
                          <div class="col-md-12 text-left">
                            <div class="row">
                              <div class="col-md-12">
                                <div class="buttons">
                                  <button
                                    type="submit"
                                    class="update"
                                    disabled={Inprogress}
                                  >
                                    {editMode ? "Update" : "Add"}
                                  </button>
                                  <button
                                    onClick={onCancel}
                                    class="cancel"
                                    disabled={Inprogress}
                                  >
                                    Cancel
                                  </button>
                                  {editMode && util.isAdmin ? (
                                    <i
                                      onClick={onAlert}
                                      class="fa fa-trash"
                                      aria-hidden="true"
                                    ></i>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </fieldset>
                    </form>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </section>
          </section>
        </div>
      </div>
    </>
  );
};
export default UserDetail;
