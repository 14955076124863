import React from "react";

import {
  HashRouter as Router,
  Route,
  Switch,
  Redirect
} from "react-router-dom";
import "./App.css";
import "./custom.scss"
import Login from "./views/login";
import Feedbacks from "./views/feedbacks";
import Samples from "./views/samples";
import ServiceRequest from "./views/service-request";
import CustomerComplaints from "./views/customer-complaints";
import Users from "./views/User/Users";
import UserDetail from "./views/User/ UserDetail";
import SampleDetail from "./views/samples/SampleDetails";
import ServiceDetails from "./views/Service Requests/ServiceDetails";
import ComplaintDetail from "./views/ComplaintDetail";
import FeedbackDetail from "./views/Feedback View/FeedbackDetail";
import SampleDetails from "./views/samples/SampleDetails";
import DockAuditForms from "./views/DockAutditForms/DockAuditForms";
import DockAuditFormSubmissions from "./views/DockAutditForms/DockAuditFormSubmissions";
import DockAuditFormUpdate from "./views/DockAutditForms/DockAuditFormUpdate";
import SupplierEvaluationReports from "./views/SupplierEvaluationReports/SupplierEvaluationReports";
import SupplierEvaluationReportForms from "./views/SupplierEvaluationReports/SupplierEvaluationReportForms";
import Eight_D_Report from "./views/8D-Report/8DReport";
import Eight_D_Report_Detail from './views/8D-Report/8DReportDetail';
import Products from "./views/Settings/Products";
import AddProductModal from "./views/Settings/AddProductModal";
import Datatypes from "./views/Settings/Datatypes";
import AddDataType from "./views/Settings/AddDataType";
// import Redirect from "./views/Redirect";

function App() {
  return (
    <Router>
      <Switch>
        <div className="App">
          <Route path="/" exact component={Login} />
          <Route path="/login" exact component={Login} />

          <Route path="/service-request" exact component={ServiceRequest} />
          {/*  */}
          <Route
            path="/dock-audit-forms/:id/edit"
            exact
            component={DockAuditFormUpdate}
          />
          <Route
            path="/dock-audit-forms/:order_id"
            exact
            component={DockAuditFormSubmissions}
          />
          <Route path="/dock-audit-forms" exact component={DockAuditForms} />
          <Route
           path="/setting/products"
          exact
         component={Products}
         />
         <Route
           path="/setting/products/:id"
          exact
         component={AddProductModal}
         />
           <Route
           path="/setting/datatype/:id"
          exact
         component={Datatypes}
         />
         <Route
         path="/setting/datatypeId/:datatypeId/addDataType/:id/:value"
         exact
         component={AddDataType}
         />
          <Route
         path="/setting/datatypeId/:datatypeId/addDataType"
         exact
         component={AddDataType}
         />

          {/*  */}
          <Route
            path="/supplier-evaluation-reports"
            exact
            component={SupplierEvaluationReports}
          />
          <Route
            path="/supplier-evaluation-reports/:id"
            exact
            component={SupplierEvaluationReportForms}
          />
          {/*  */}
          <Route
            path="/customer-complaints"
            exact
            component={CustomerComplaints}
          />
          <Route path="/users" exact component={Users} />
          <Route path="/userdetail/:id" exact component={UserDetail} />
          <Route path="/userdetail" exact component={UserDetail} />

          <Route path="/servicedetails/:id" exact component={ServiceDetails} />
          <Route path="/servicedetails" exact component={ServiceDetails} />
          <Route
            path="/complaintdetail/:id"
            exact
            component={ComplaintDetail}
          />
            <Route
            path="/complaintdetail"
            exact
            component={ComplaintDetail}
          />
          <Route
            path="/8d-report/"
            exact
            component={Eight_D_Report}
          />
          <Route
            path="/8d-report-detail"
            exact
            component={Eight_D_Report_Detail}
          />
          <Route
            path="/8d-report-detail/:id"
            exact
            component={Eight_D_Report_Detail}
          />
         
        </div>
      </Switch>
    </Router>
  );
}

export default App;
