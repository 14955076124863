import React, { useEffect, useState } from "react";
import Tables from "../../Controller/Table";
import { dataType } from "../../services/api";
import SideMenu from "../../containers/sidemenu";
import Navbar from "../../containers/navbar";
import ImageLoader from "../../containers/loader";
import Confirm from "../../containers/Confirm";
import { useHistory, useParams } from "react-router-dom";
import AddDataType from "./AddDataType";

const DataType = () => {
  const [products, setProducts] = useState([]);
  const [progress, setProgress] = useState(false);
  let heading = ["Id", "Items"];
  let accessors = ["id", "value"];
  const history = useHistory();
  const params = useParams()

  let allProducts = async () => {
    try {
      setProgress(true);
      let response = await dataType(params.id);
      if (response.status === 200) {
         setProducts(response.data.data);
      } else {
        console.log(response.statusMessage);
      }
      setProgress(false);
    } catch (error) {
      console.log(error);
      setProgress(false);
    }
  };

  function AddProduct() {
    return history.push(`/setting/datatypeId/${params.id}/addDataType`);
  }
   
  useEffect(() => {
    allProducts();
   
  }, [params.id,products.length]);
  return (
    <div>
      <SideMenu />
      <div class='dashboard complaints-section'>
        <Navbar />
        <section id='contents'>
          <section class='statis text-center'>
            <div class='container-fluid'>
              <div class='row'></div>
              <div class='row user_section'>
                <div className='p-0'>
                  <div class='col-md-3 text-left p-0 ps-5'>
                    <button
                      onClick={AddProduct}
                      class='btn btn-primary add-btn mx-0'
                    >
                      ADD
                    </button>
                  </div>
                </div>
                <div class='col-md-12 px-5'>
                  <Tables
                    heading={heading}
                    itemsPerPage={10}
                    accessors={accessors}
                    data={products}
                    fetching={progress}
                    navlink={`/setting/datatypeId/${params.id}/addDataType/`}
                    onPopupClose={() => {
                      allProducts();
                    }}
                    customComponent={AddDataType}

                  />
                </div>
              </div>
            </div>
          </section>
        </section>
      </div>
    </div>
  );
};

export default DataType;
