import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import * as util from "../../Util";
import * as API from "../../services/api";
import SideMenu from "../../containers/sidemenu";
import Navbar from "../../containers/navbar";
import ImageLoader from "../../containers/loader";
import Confirm from "../../containers/Confirm";
import Upload from "../Upload";
import { readFileAsDataUrl } from "../../shared/Shared";
import AutoSearchCtrl from "../../shared/AutoSelectCtrl";
let cb;
let DeleteArrayImages = [];
const DockAuditFormUpdate = (props) => {
  const [open, setOpen] = useState(false);
  const [Inprogress, SetInprogress] = useState(false);
  // const [teamMembers, setTeamMembers] = useState(null);
  const [CustomerError, SetCustomerError] = useState(false);
  const params = useParams();
  const [state, setState] = useState({ data: null, loaded: false });
  const [roles, setRoles] = useState([]);
  const [images, setImages] = useState();

  useEffect(() => {
    DeleteArrayImages = [];
    if (!util.isLoggedIn()) {
      props.history.push("/");
    }
    util.showLoader();
    let id = params.id;
    console.log(params);

    if (!id) {
      return props.history.push("/dock-audit-forms");
    }
    async function fetchData() {
      try {
        // const teamMembers = await API.getTeamMembers();
        // populate sales people dropdown
        // const teamMemebersData = teamMembers.data.data;
        // if (teamMemebersData) {
        //   console.log("member data", teamMemebersData);

        //   setTeamMembers(
        //     teamMemebersData.items.map(val => {
        //       return {
        //         name: val.fullName,
        //         value: val.id
        //       };
        //     })
        //   );
        // }
        // get the roles
        const roles = await API.GetAllRoles();
        const availableRoles = roles.data.roles.items;
        if (availableRoles.length > 0) {
          setRoles(
            availableRoles.map((val) => {
              return { name: val.displayName, value: val.name, id: val.id };
            })
          );
        }
        // get the images
        const images = await API.getDockAuditFormImages(params.id);
        const sampleImages = [];
        for (let i = 0; i < 5; i++) {
          sampleImages.push({
            id: `image${i}`,
            name: `image${i}`,
            imageShown: { imageUrl: "" },
            fileId: null,
            binary: null,
          });
        }
        const imageItems = images.data.data.items;
        console.log("images", imageItems);

        if (imageItems.length > 0) {
          for (let i = 0; i < imageItems.length; i++) {
            if (sampleImages[i]) {
              sampleImages[i].imageShown.imageUrl = imageItems[i].fileUrl;
              sampleImages[i].fileId = imageItems[i].fileId;
            }
          }
        }
        setImages(sampleImages);
        console.log("sample images now", sampleImages);

        const result = await API.getSingleDockAudit(id);
        setTimeout(() => {
          if (cb != undefined) {
            cb({
              value: result.data.data.notifyUserId,
              label: result.data.data.notifyUserName,
            });
          }
        }, 800);
        if (result.data.data) {
          setState((prevState) => {
            let obj = Object.assign({}, prevState);
            obj.data = result.data.data;
            obj.loaded = true;
            return obj;
          });
          util.hideLoader();
        }
      } catch (error) {
        console.log(error);

        setState({ ...state, loaded: true });
        util.hideLoader();
      }
    }
    fetchData();
  }, []);

  const onAlert = (e) => {
    setOpen(true);
  };

  const onImageSelect = async (imageEvent, id) => {
    imageEvent.persist();
    setError(null);
    const file = imageEvent.target.files[0];
    if (file && file.type.indexOf("image") !== -1) {
      const dataUrl = await readFileAsDataUrl(imageEvent.target.files[0]);
      console.log("data url rec", dataUrl);
      if (dataUrl.length > 0) {
        const cloneImages = [...images];
        const myImage = cloneImages.find((val) => val.id === id);
        if (myImage) {
          console.log("found");

          myImage.imageShown.imageUrl = dataUrl;
          myImage.binary = file;

          if (myImage.fileId) {
            let index = DeleteArrayImages.indexOf(myImage.fileId);
            if (index == -1) {
              DeleteArrayImages.push(myImage.fileId);
            }
          }
          console.log(cloneImages, myImage, DeleteArrayImages, "test");
          // console.log(cloneImages, myImage);

          setImages(cloneImages);
        }
      }
    } else {
      setError("Invalid image format");
    }
  };

  const onCancel = (e) => {
    props.history.goBack();
  };
  const [err, setError] = useState(null);

  const onChange = (e) => {
    e.persist();
    let { name, checked, type, value } = e.target;

    setState((prevState) => {
      let obj = Object.assign({}, prevState);
      obj.data[name] = type === "checkbox" ? checked : value;
      return obj;
    });
  };
  const onDropDownChange = (e) => {
    let { name, value } = e.target;
    console.log("name" + name + " " + "value" + value);
    setState((prevState) => {
      let obj = Object.assign({}, prevState);
      obj.data[name] = value;
      return obj;
    });
  };
  const onDelete = async (e) => {
    SetInprogress(true);
    try {
      util.showLoader();
      let id = props.match.params.id;
      let result = await API.deleteDockAuditForm(id);
      // console.log('##submit##', result.data)
      if (result) util.hideLoader();
      util.ShowNotification("Dock Audit Form Deleted");
      setOpen(false);
      props.history.push("/dock-audit-forms");
    } catch (error) {
      util.hideLoader();
    } finally {
      SetInprogress(false);
    }
  };
  //   const onAlert = e => {
  //     setOpen(true);
  //   };
  const onSubmit = async (e) => {
    e.preventDefault();
    if (state.data.notifyUserId == null || state.data.notifyUserName == null) {
      SetCustomerError(true);
      return;
    }
    setError(null);
    console.log("submitted", state);
    // persist on server
    const data = { ...state }.data;
    console.log("state", data);
    // upload the images

    // const {
    //     orderNo,
    //     yourRole,
    //     notifyUserName,
    //     areTotesDrumsLabelsGood,
    //     areSpecialCustomerInstructionsFollowed,
    //     isPaintNetWeightsToteDrumNoWritten,
    //     ordersLeavingUSHaveCommercialInvoice,
    //     productQuantityNameMatchesBill,
    //     coaIncluded,
    //     expirationDatesPresent,
    //     totesPailsHaveBarcode,
    //     skidsPailsSingleDrumsWrapped,
    //     skidsMultipleDrumsWrapped,
    //     consignorSignatureDone,
    //     additionalComments
    // } = data;
    try {
      util.showLoader();
      // save the images

      //
      if (DeleteArrayImages.length) {
        let tempRes = await API.DeleteData("Files/Delete", DeleteArrayImages);
        console.log("test", tempRes);
      }
      //

      const imageClone = [...images];
      const uploadTasks = [];
      imageClone.forEach((val) => {
        console.log("inserting image promise");
        if (val.binary) {
          console.log("binary val", val.binary);

          uploadTasks.push(API.fileUpload(val.binary));
        }
      });
      for await (let task of uploadTasks) {
        console.log("awaiting image promise", task);
        // save the id
        await API.saveDockAuditFormImages([
          { DockAuditFormId: params.id, FileId: task.data.data.id },
        ]);
      }
      // save other data
      const result = await API.saveDockAuditForm({
        ...state.data,
        // notifyUserName: teamMembers.find(val => val.id === state.notifyUserId)
        //   .name,
        lastModBy: undefined,
        lastModOn: undefined,
      });
      if (result) {
        util.hideLoader();
      }
      if (result.data.data) {
        util.ShowNotification(result.data.message);
        props.history.goBack();
      } else {
        setError(result.data.message);
      }
    } catch (e) {
      util.hideLoader();
    }
  };
  const handleClose = () => {
    setOpen(false);
  };

  const RemoveImage = (data) => {
    let index = DeleteArrayImages.indexOf(data.fileId);
    if (index == -1) {
      DeleteArrayImages.push(data.fileId);
    }

    const cloneImages = [...images];
    const myImageIdx = cloneImages.findIndex((val) => {
      return val.id === data.id;
    });
    if (myImageIdx != -1) {
      data.imageShown.imageUrl = "";
      cloneImages[myImageIdx] = data;
      setImages([...cloneImages]);
    }
  };

  const SelectCtrl = (props) => {
    return <select className='dock-audit-select' {...props}>
      {
        util.DOCK_AUDIT_OPTIONS.map((item)=>(<option value={item.value}>{item.label}</option>))
      }
    </select>
  }

  return (
    <div>
      <SideMenu />
      <div className="dashboard">
        <Navbar />
        <section id="contents">
          <ImageLoader />
          <section className="statis text-center">
            <Confirm
              handleClose={handleClose}
              open={open}
              onDelete={onDelete}
            />

            <div className="container-fluid">
              {state.data ? (
                <div className="user_section complaints">
                  <div className="row margin-bottom">
                    <div className="col-md-12 text-left">
                      <h5 className="heading">Edit Dock Audit</h5>
                    </div>
                  </div>

                  <form onSubmit={onSubmit}>
                    {/* supplier */}
                    <div className="row margin-bottom">
                      <div className="col-md-6 text-left">
                        <div className="row">
                          <div className="col-md-3">
                            <span>Order Number</span>
                          </div>
                          <div className="col-md-9">
                            <input
                              value={state.data.orderNo}
                              type="text"
                              name="orderNo"
                              onChange={onChange}
                              placeholder="Order Number"
                              required
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6 text-left">
                        <div className="row">
                          <div className="col-md-3">
                            <span>
                              What role are you completing this WO as?
                            </span>
                          </div>
                          <div className="col-md-9">
                            <select
                              name="yourRole"
                              value={state.data.yourRole}
                              onChange={onDropDownChange}
                              id="yourRole"
                              required
                            >
                              <option value="">Select Role</option>
                              {roles.map((item, idx) => {
                                return (
                                  <option key={idx} value={item.value}>
                                    {item.name}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* purchase number */}
                    <div className="row margin-bottom">
                      <div className="col-md-6 text-left">
                        <div className="row">
                          <div className="col-md-3">
                            <span>Notify</span>
                          </div>
                          <div className="col-md-9">
                            <AutoSearchCtrl
                              CallBack={(selection) => {
                                console.log("check", selection);
                                SetCustomerError(false);
                                if (
                                  selection == null ||
                                  selection.label == ""
                                ) {
                                  selection = {
                                    value: null,
                                    label: null,
                                  };
                                  // return;
                                }
                                setState((prevState) => {
                                  let obj = Object.assign({}, prevState);
                                  obj.data.notifyUserId = selection.value;
                                  obj.data.notifyUserName = selection.label;
                                  return obj;
                                });
                              }}
                              InitVal={(fn) => {
                                cb = fn;
                              }}
                              Placeholder={"Select"}
                              name={"notifyUserId"}
                              endpoint={"Users/GetTeamMembers"}
                              required={true}
                            />
                            {CustomerError ? (
                              <div className="text-danger">Required Field</div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row margin-bottom">
                      <div className="col-md-6 text-left">
                        <div className="row">
                          <div className="col-md-9">
                            <span>
                              Totes are full, in good condition, have valve caps
                              and seals. Drums have both caps. All required
                              labels are present.
                            </span>
                          </div>
                          <div className="col-md-3">
                            <SelectCtrl
                              value={state.data.areTotesDrumsLabelsGoodStr}
                              name="areTotesDrumsLabelsGoodStr"
                              onChange={onChange}
                              placeholder="Please Select"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6 text-left">
                        <div className="row">
                          <div className="col-md-9">
                            <span>
                              ALL special customer instructions from the work
                              order are followed; confirm individually before
                              continuing.
                            </span>
                          </div>
                          <div className="col-md-3">
                            <SelectCtrl
                              value={state.data.areSpecialCustomerInstructionsFollowedStr}
                              name="areSpecialCustomerInstructionsFollowedStr"
                              onChange={onChange}
                              placeholder="Please Select"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row margin-bottom display-none">
                      <div className="col-md-12 text-left">
                        <div className="row">
                          <div className="col-md-6">
                            <span>
                              Paint: Net Weights and Tote/Drum # written on work
                              order and both Bills of Lading
                            </span>
                          </div>
                          <div className="col-md-3">
                            <SelectCtrl
                              value={state.data.isPaintNetWeightsToteDrumNoWrittenStr}
                              name="isPaintNetWeightsToteDrumNoWrittenStr"
                              onChange={onChange}
                              placeholder="Please Select"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row margin-bottom">
                      <div className="col-md-12 text-left">
                        <div className="row">
                          <div className="col-md-6">
                            <span>
                              All orders leaving the United States are
                              accompanied by a commercial invoice except Fortech
                              Mexico and PTI Dos
                            </span>
                          </div>
                          <div className="col-md-3">
                            <SelectCtrl
                              value={state.data.ordersLeavingUSHaveCommercialInvoiceStr}
                              name="ordersLeavingUSHaveCommercialInvoiceStr"
                              onChange={onChange}
                              placeholder="Please Select"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row margin-bottom">
                      <div className="col-md-12 text-left">
                        <div className="row">
                          <div className="col-md-6">
                            <span>
                              Product quantity & name being shipped matches
                              exactly the bill of lading / work order
                            </span>
                          </div>
                          <div className="col-md-3">
                            <SelectCtrl
                              value={state.data.productQuantityNameMatchesBillStr}
                              name="productQuantityNameMatchesBillStr"
                              onChange={onChange}
                              placeholder="Please Select"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row margin-bottom">
                      <div className="col-md-12 text-left">
                        <div className="row">
                          <div className="col-md-6">
                            <span>
                              COA included with all orders (unless quality
                              notifies otherwise)
                            </span>
                          </div>
                          <div className="col-md-3">
                            <SelectCtrl
                              value={state.data.coaIncludedStr}
                              name="coaIncludedStr"
                              onChange={onChange}
                              placeholder="Please Select"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row margin-bottom">
                      <div className="col-md-12 text-left">
                        <div className="row">
                          <div className="col-md-6">
                            <span>
                              Expiration Dates are present on labels. If
                              expiration date is less than 30 days from ship
                              date, stop and notify quality.
                            </span>
                          </div>
                          <div className="col-md-3">
                            <SelectCtrl
                              value={state.data.expirationDatesPresentStr}
                              name="expirationDatesPresentStr"
                              onChange={onChange}
                              placeholder="Please Select"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row margin-bottom">
                      <div className="col-md-12 text-left">
                        <div className="row">
                          <div className="col-md-6">
                            <span>
                              Totes and pails have one Fortech barcode label
                              each. Drums have two barcode labels, one on front,
                              one on top.
                            </span>
                          </div>
                          <div className="col-md-3">
                            <SelectCtrl
                              value={state.data.totesPailsHaveBarcodeStr}
                              name="totesPailsHaveBarcodeStr"
                              onChange={onChange}
                              placeholder="Please Select"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row margin-bottom">
                      <div className="col-md-12 text-left">
                        <div className="row">
                          <div className="col-md-6">
                            <span>
                              Skids of Pails and Single Drums: Plastic wrap is
                              tight around products and secured to the skid
                            </span>
                          </div>
                          <div className="col-md-3">
                            <SelectCtrl
                              value={state.data.skidsPailsSingleDrumsWrappedStr}
                              name="skidsPailsSingleDrumsWrappedStr"
                              onChange={onChange}
                              placeholder="Please Select"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row margin-bottom">
                      <div className="col-md-12 text-left">
                        <div className="row">
                          <div className="col-md-6">
                            <span>
                              Skids of Multiple Drums: Drums are banded and
                              wrapped in plastic
                            </span>
                          </div>
                          <div className="col-md-3">
                            <SelectCtrl
                              value={state.data.skidsMultipleDrumsWrappedStr}
                              name="skidsMultipleDrumsWrappedStr"
                              onChange={onChange}
                              placeholder="Please Select"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row margin-bottom">
                      <div className="col-md-12 text-left">
                        <div className="row">
                          <div className="col-md-6">
                            <span>
                              Consignor Signature Section 7 box on Bill of
                              Lading is signed
                            </span>
                          </div>
                          <div className="col-md-3">
                            <SelectCtrl
                              value={state.data.consignorSignatureDoneStr}
                              name="consignorSignatureDoneStr"
                              onChange={onChange}
                              placeholder="Please Select"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row margin-bottom">
                      <div className="col-md-12 text-left">
                        <div className="row">
                          <div className="col-md-6">
                            <span>
                              Did you find any non-conformances during your
                              audit? (If Yes, please note the finding in the
                              comments)
                            </span>
                          </div>
                          <div className="col-md-3">
                            <SelectCtrl
                              value={state.data.foundNonConformityStr}
                              name="foundNonConformityStr"
                              onChange={onChange}
                              placeholder="Please Select"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row margin-bottom">
                      <div className="col-md-12 text-left">
                        <div className="row">
                          <div className="col-md-6">
                            <span>Product name on large product label matches product name on smaller barcode label.</span>
                          </div>
                          <div className="col-md-3">
                            <SelectCtrl
                              value={state.data.productNameMatchesStr}
                              name="productNameMatchesStr"
                              onChange={onChange}
                              placeholder="Please Select"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row margin-bottom">
                      <div className="col-md-12 text-left">
                        <div className="row">
                          <div className="col-md-6">
                            <span>Detach Work Order from the Bill of Lading. Put the Work Order in the shipping office and put the Bill of Lading back into the shipping envelope.</span>
                          </div>
                          <div className="col-md-3">
                            <SelectCtrl
                              value={state.data.detachWorkOrderStr}
                              name="detachWorkOrderStr"
                              onChange={onChange}
                              placeholder="Please Select"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row margin-bottom">
                      <div className="col-md-12 text-left">
                        <div className="row">
                          <div className="col-md-6">
                            <span>Additional Comments</span>
                          </div>
                          <div className="col-md-6">
                            <textarea
                              rows="5"
                              onChange={onChange}
                              placeholder="Additional Comments"
                              value={state.data.additionalComments}
                              name="additionalComments"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row margin-bottom">
                      <div className="col-md-12 text-left">
                        {images.map((val) => {
                          return (
                            <span className="img-container">
                              <Upload
                                id={val.id}
                                name={val.name}
                                onSelect={(e) => {
                                  onImageSelect(e, val.id);
                                }}
                                imageShown={val.imageShown}
                              >
                                <i
                                  class="fa fa-plus-circle"
                                  aria-hidden="true"
                                ></i>
                              </Upload>
                              {val.imageShown.imageUrl ? (
                                <div className="file-uploader-add-icon">
                                  <i
                                    onClick={() => {
                                      RemoveImage(val);
                                    }}
                                    class="fas fa-times-circle"
                                    aria-hidden="true"
                                  ></i>
                                </div>
                              ) : null}
                            </span>
                          );
                        })}
                      </div>
                    </div>

                    {err ? (
                      <div
                        style={{
                          color: "red",
                          fontSize: "1.6rem",
                        }}
                      >
                        {"Error: " + err}
                      </div>
                    ) : null}

                    <div className="row margin-bottom">
                      <div className="col-md-12 text-left">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="buttons">
                              <button
                                type="submit"
                                className="update"
                                disabled={Inprogress}
                              >
                                Update
                                {/* {editMode ? "Update" : "Add"} */}
                              </button>
                              <button
                                onClick={onCancel}
                                className="cancel"
                                type="button"
                                disabled={Inprogress}
                              >
                                Cancel
                              </button>
                              {util.isAdmin() ? (
                                <i
                                  onClick={onAlert}
                                  class="fa fa-trash"
                                  aria-hidden="true"
                                ></i>
                              ) : null}
                              {/* {editMode ? (
                                    <i
                                      onClick={onAlert}
                                      className="fa fa-trash-o delete"
                                      aria-hidden="true"
                                    ></i>
                                  ) : null} */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              ) : null}
            </div>
          </section>
        </section>
      </div>
    </div>
  );
};
export default DockAuditFormUpdate;
