import React, { useState, useEffect } from "react";
import { BsPlusLg } from "react-icons/bs";
import * as util from "../../Util";
import * as API from "../../services/api";
import SideMenu from "../../containers/sidemenu";
import Navbar from "../../containers/navbar";
import { withRouter } from "react-router-dom";
import ImageLoader from "../../containers/loader";
import Confirm from "../../containers/Confirm";
import Attachments from "./Attachments"
import Report8DPrintForm from "../../PrintForms/Report8DPrintForm";
import { PulseLoader } from "react-spinners";

const DEFAULT_DATE = '0001-01-01T00:00:00.000+00:00';
const API_DATE_FORMAT = "YYYY-MM-DDThh:mm:ss.SSSZ"
const dateFields = [ 
    "dateOpen", 
    "targetCloseDate", 
    "revisionDate", 
    "actualCloseDate", 
    "interimContainmentTagetDate", 
    "interimContainmentActualDate", 
    "validatePermanentCorrectiveTagetDate", 
    "validatePermanentCorrectiveActualDate", 
    "actionsToPreventRecurrenceTagetDate", 
    "actionsToPreventRecurrenceActualDate", 
    'actualDate', 
    'targetDate',
    'verifyPermanentCorrectiveTagetDate',
    'verifyPermanentCorrectiveActualDate'
];
const customStyles = {
  control: (base) => ({
    ...base,
    height: 100,
    minHeight: 100,
    overflow: "scroll",
  }),
};

  const singleFile = {
    fileName: null,
    fileUrl: ""
  }

  const defaultCause = {
      "note": "",
      "contribution": "",
      "targetDate": "",
      "actualDate": "",
      "documentAttachmntsIds": "",
      "pictureAttachmntsIds": "",
      "documentAttachmnts": [singleFile, singleFile, singleFile],
      "pictureAttachmnts": [singleFile, singleFile, singleFile]
  }

const ReportDetail8D = (props) => {
  let [images, setImages] = useState([]);
  let [documents, setDocuments] = useState([]);
  let [PrintFlash, SetPrintFlash] = useState(true);
  const [problemStatementDA, setProblemStatementDA] = useState([singleFile, singleFile, singleFile]);
  const [problemStatementPA, setProblemStatementPA] = useState([singleFile, singleFile, singleFile]);
  const [interimContainmentDA, setInterimContainmentDA] =useState([singleFile, singleFile, singleFile]);
  const [interimContainmentPA, setInterimContainmentPA] = useState([singleFile, singleFile, singleFile]);
  const [verifyPermanentCorrectiveDA, setVerifyPermanentCorrectiveDA] = useState([singleFile, singleFile, singleFile]);
  const [verifyPermanentCorrectivePA, setVerifyPermanentCorrectivePA] = useState([singleFile, singleFile, singleFile]);
  const [validatePermanentCorrectiveDA, setValidatePermanentCorrectiveDA] = useState([singleFile, singleFile, singleFile]);
  const [validatePermanentCorrectivePA, setValidatePermanentCorrectivePA] = useState([singleFile, singleFile, singleFile]);

  const [rootCauses, setRootCauses] = useState([{...defaultCause}])
  const [state, setState] = useState({
    data: {
      company: "",
      address: "",
      location: "",
      partNoCode: "",
      productType: "",
      productName: "",
      dateOpen: "",
      initialResponse: "",
      targetCloseDate: "",
      revisionDate: "",
      initiator: "",
      actualCloseDate: "",
      champion: "",
      teamLeaderId: "",
      teamMembers: "",
      problemStatement_Description: "",
      verifyInterimContainment: "",
      interimContainmentEffective: "",
      interimContainmentTagetDate: "",
      interimContainmentActualDate: "",
      verifyRootCause: "",
      verifyRootCauseContribution: "",
      verifyPermanentCorrective: "",
      verifyPermanentCorrectiveEffective: "",
      validatePermanentCorrective: "",
      validatePermanentCorrectiveTagetDate: "",
      validatePermanentCorrectiveActualDate: "",
      actionsToPreventRecurrence: "",
      actionsToPreventRecurrenceTagetDate: "",
      actionsToPreventRecurrenceActualDate: "",
      controlPlanEnabled: false,
      fmeaEnabled: false,
      flowchartEnabled: false,
      procWorkIndusEnabled: false,
      addToInternalAuditEnabled: false,
      individualRecognition: "",
      customerComplaintsNo:"",
      verifyPermanentCorrectiveTagetDate:"",
      verifyPermanentCorrectiveActualDate:"",
      problemStatement_DocumentAttachmntsIds: "",
      problemStatement_PictureAttachmntsIds: "",
      interimContainment_DocumentAttachmntsIds: "",
      interimContainment_PictureAttachmntsIds: "",
      verifyPermanentCorrective_DocumentAttachmntsIds: "",
      verifyPermanentCorrective_PictureAttachmntsIds: "",
      validatePermanentCorrective_DocumentAttachmntsIds: "",
      validatePermanentCorrective_PictureAttachmntsIds: "",
    },
    loaded: false,
  });
  const [open, setOpen] = useState(false);
  const [teamMembers, setTeamMembers] = useState([]);
  const [customerComplaints, setCustomerComplaints] = useState([])
  const [locations, setLocations] = useState([]);
  const [EditMode, SetEditMode] = useState(false);
  const [showSelectors, setShowSelectors] = useState({
    teamMembers: false  
  });

  const [progress, setProgress] = useState(false);
  const [printing, setPrinting] = useState(false);

  const updateData = (data = {}) => {
    const { 
        rootCauses,
        problemStatement_DocumentAttachmnts,
        problemStatement_PictureAttachmnts,
        interimContainment_DocumentAttachmnts,
        interimContainment_PictureAttachmnts,
        verifyPermanentCorrective_DocumentAttachmnts,
        verifyPermanentCorrective_PictureAttachmnts,
        validatePermanentCorrective_DocumentAttachmnts,
        validatePermanentCorrective_PictureAttachmnts 
    } = data;
    handleRootCause(rootCauses);
    
    setProblemStatementDA(handleAttachments(problemStatement_DocumentAttachmnts));
    setProblemStatementPA(handleAttachments(problemStatement_PictureAttachmnts))

    setInterimContainmentDA(handleAttachments(interimContainment_DocumentAttachmnts));
    setInterimContainmentPA(handleAttachments(interimContainment_PictureAttachmnts));
    
    setVerifyPermanentCorrectiveDA(handleAttachments(verifyPermanentCorrective_DocumentAttachmnts));
    setVerifyPermanentCorrectivePA(handleAttachments(verifyPermanentCorrective_PictureAttachmnts));
  
    setValidatePermanentCorrectiveDA(handleAttachments(validatePermanentCorrective_DocumentAttachmnts));
    setValidatePermanentCorrectivePA(handleAttachments(validatePermanentCorrective_PictureAttachmnts));
  }

  const dateConverter = (obj = {}, dateKeys = [], format = "YYYY-MM-DD") => {
    Object.keys(obj).map((key) => {
        if(dateKeys.includes(key)){
          if(obj[key] && obj[key] !== DEFAULT_DATE){
            obj[key] = util.dateFormat(obj[key], format);
          }else{
            obj[key] = DEFAULT_DATE;
          }
        }
        return key
    });
    return obj;
  }

  useEffect(() => {
    if (util.isLoggedIn() === false) {
      props.history.push("/");
    }

    let id = props.match.params.id;

    if (id != undefined) {
      if (isNaN(parseInt(id))) {
        // Invalid Edit Id passed
        GoBack();
        return;
      }
      // Otherwise edit is valid
      SetEditMode(true);
    }

    util.showLoader();

    async function fetchData() {
      try {
        setProgress(true);

        if (id) {
          let result = await API.GetData(`ResponseReport/Get?id=${id}`);
          if (result.data) {
            updateData(result.data)
            setState((prevState) => {
              let obj = Object.assign({}, prevState);
              obj.data = result.data;
              obj.data = dateConverter(obj.data, dateFields, "YYYY-MM-DD");
              
              obj.loaded = true;
              return obj;
            });
          }
        }
        util.hideLoader();
      } catch (error) {
        setState({ ...state, loaded: true });
        util.ShowNotification(error.message, 'error');
        util.hideLoader();
      } finally {
        setProgress(false);
      }
    }
    fetchData();
    fetchTeamMebers();
    fetchLocations();
    fetchCustomerComplaints();
    //
  }, []);

  useEffect(()=>{
    // Inits the 5 image blocks //
    const sampleImages = [];
    for (let i = 0; i < 3; i++) {
      sampleImages.push({
        id: `image${i}`,
        name: `image${i}`,
        imageShown: { imageUrl: "" },
        fileId: null,
        binary: null,
      });
    }
    setImages(sampleImages);
    setDocuments(sampleImages);
  },[]);

  const fetchLocations = async () => {
    try {
      let result = await API.GetData("Utility/GetLocations");
      if (result.data) {
        setLocations(result.data)
      } else {
        throw result;
      }
    } catch(err) {
      console.log("err", err)
      util.ShowNotification(err.message, 'error');
    } finally {
      // done
    }
  }

  const fetchTeamMebers = async () => {
    try{
      const teamMembersResult = await API.GetData("Customer/GetCustomers");
      setTeamMembers(
        teamMembersResult.data.items.map((val, index) => {
          return { value: val.fullName, label: val.fullName };
        })
      );
    }catch(err){
      console.log('error', err);
      util.ShowNotification(err.message, 'error');
    }
  };


  const fetchCustomerComplaints = async () => {
    try{
        let result = await API.GetData(
            `CustomerComplaints/GetAll?pagenumber=${1}&pagesize=${10000}&CustomerComplaintStatus=All`
          );
          if (result.data !== null) {
            const items = result.data.items;
            const complaints = await items.map((complaint) => {
              return { value: complaint.id, label: 'ccNo#' + complaint.ccNo || "" };
            });
            setCustomerComplaints(complaints)
            console.log('items', complaints);
        }
    }catch(err){
      console.log('error', err);
      util.ShowNotification(err.message, 'error');
    }
  };

  // // Input fields
  const HandleChange = (e) => {
    let { name, value } = e.target || e;
    console.log(name, value)
    setState((prevState) => {
      let obj = Object.assign({}, prevState);
      obj.data[name] = value;
      return obj;
    });
  };

  // // Select Drop-Downs
  const HandleSelectChange = (e) => {
    let { name, value } = e.target;
    setState((prevState) => {
      let obj = Object.assign({}, prevState);
      obj.data[name] = value;
      return obj;
    });
  };

  // checkbox method
  const onChange = e => {
    e.persist();
    let { name, checked, type, value } = e.target;
    console.log(name, checked, type, value);
    setState(prevState => {
      let obj = Object.assign({}, prevState);
      obj.data[name] = type === "checkbox" ? checked : value;
      return obj;
    });
  };

  const prepareString = (arr, key) => {
    const tempArr = []
    if(Array.isArray(arr)) {
        arr.map((item, idx) => {
            if(item[key]) {
                tempArr.push(item[key])  
            }
            return item
        })
    }
    return tempArr.join(",");
  }

  // SUBMIT METHOD //
  const OnSubmit = async (e) => {
    console.log('OnSubmit', state)
    e.preventDefault();
    let { data } = state;
    try {
      setProgress(true);
      let postBody = {
        ...state.data,
          "dateOpen": data.dateOpen,
          "targetCloseDate": data.targetCloseDate,
          "revisionDate": data.revisionDate,
          "actualCloseDate": data.actualCloseDate,
          "interimContainmentTagetDate": data.interimContainmentTagetDate,
          "interimContainmentActualDate": data.interimContainmentActualDate,
          "validatePermanentCorrectiveTagetDate": data.validatePermanentCorrectiveTagetDate,
          "validatePermanentCorrectiveActualDate": data.validatePermanentCorrectiveActualDate,
          "actionsToPreventRecurrenceTagetDate": data.actionsToPreventRecurrenceTagetDate,
          "actionsToPreventRecurrenceActualDate": data.actionsToPreventRecurrenceActualDate,
          "problemStatement_DocumentAttachmntsIds": prepareString(problemStatementDA, 'id'),
          "problemStatement_PictureAttachmntsIds": prepareString(problemStatementPA, 'id'),
          "interimContainment_DocumentAttachmntsIds": prepareString(interimContainmentDA, 'id'),
          "interimContainment_PictureAttachmntsIds": prepareString(interimContainmentPA, 'id'),
          "verifyPermanentCorrective_DocumentAttachmntsIds": prepareString(verifyPermanentCorrectiveDA, 'id'),
          "verifyPermanentCorrective_PictureAttachmntsIds": prepareString(verifyPermanentCorrectivePA, 'id'),
          "validatePermanentCorrective_DocumentAttachmntsIds": prepareString(validatePermanentCorrectiveDA, 'id'),
          "validatePermanentCorrective_PictureAttachmntsIds": prepareString(validatePermanentCorrectivePA, 'id'),
          "rootCauses": rootCauses.map((cause) => {
            cause.documentAttachmntsIds = prepareString(cause.documentAttachmnts, 'id');
            cause.pictureAttachmntsIds = prepareString(cause.pictureAttachmnts, 'id');

            delete cause.documentAttachmnts;
            delete cause.pictureAttachmnts;

            cause = dateConverter(cause, dateFields, API_DATE_FORMAT)
            return cause
          })
      }

      postBody = dateConverter(postBody, dateFields, API_DATE_FORMAT)
      console.log('wolf', state.data, postBody);
    //   setProgress(false);
    //   return

      //
      let result = await API.PostData("ResponseReport/Save", {
        ...postBody
      });
      console.log('submit', result.data)
      if (result) {
        util.hideLoader();
        setProgress(false);
      }
      if (result && result.data && result.data.data) {
        util.ShowNotification(result.data.message);
        props.history.push("/8d-report");
      } else {
        throw new Error(result.data.message);
      }
    } catch (error) {
      console.error(error)
      util.hideLoader();
      util.ShowNotification(error.message, 'error');
      setProgress(false);
    }
  };

  // GOBACK METHOD
  const GoBack = (e) => {
    props.history.push("/8d-report");
  };

  // DELETE METHOD
  const onDelete = async (e) => {
    try {
      util.showLoader();
      setProgress(true);
      let id = props.match.params.id;
      let result = await API.DeleteData('ResponseReport/Delete', undefined, { id });
      if (result) util.hideLoader();
      util.ShowNotification("Report Deleted");
      setOpen(false);
      props.history.push("/8d-report");
    } catch (error) {
      util.hideLoader();
      util.ShowNotification(error.message, 'error');
    } finally {
      setProgress(false);
    }
  };

  // CONFIRM POPOVER METHODS //
  const onAlert = (e) => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  // //

  // BACKDROP DISABLE
  const DisableBackDrop = (_) => {
    const cloned = { ...showSelectors };
    for (let item in cloned) {
      cloned[item] = false;
    }
    setShowSelectors(cloned);
    if (open) {
      setOpen(false);
    }
    util.changeBlurScrollClass(false);
  };

  const handleAttachments = (attachments, count = 3, defaultObj = singleFile) => {
    let items = [...new Array(count).fill(defaultObj)];
    if(Array.isArray(attachments)) {
        const tempList = attachments.filter(file => file.id)
        const tempCount = count - tempList.length;
        console.log(tempList, tempCount, count, tempCount >= 0)
        if(tempCount >= 0) {
            items = [...tempList, ...new Array(tempCount).fill(singleFile)]
        } else {
            items = [...tempList]
        }
    }
    return items
  }

  const fileUpload = async (event, attachments, index, cb) => {
    const files = event.target.files;
    let tempList = attachments || [];
    if (files[0]) {
        const fileRes = await API.fileUpload(files[0]);
        console.log(fileRes.data?.data)
        if (fileRes.data.data?.id) {
            if (index || index === 0) {
                tempList = tempList.map((file, idx) => {
                    if (idx === index) {
                        file = fileRes.data.data;
                    }
                    return file;
                });
            } else {
                tempList.push(fileRes.data?.data);
            }
        }
        if(cb) {
            const result = handleAttachments(tempList);
            console.log(result);
            cb([...result])
        }
    }
} 

  const removeAttachments = (attachments, idx, cb) => {
    let items = [...new Array(3).fill(singleFile)]
    if(Array.isArray(attachments)) {
        items = attachments.filter((file, fIdx) =>  fIdx !== idx);
        items = handleAttachments(items)
    }
    if(cb) {
        cb(items);
    }
  }

  const handleRootCause = async (rCause = rootCauses) => {
    let tempRootCause = [{...defaultCause}];
        if(Array.isArray(rCause) && rCause.length) {
            tempRootCause = rCause;
        }
        tempRootCause.map(cause => {
            cause.documentAttachmnts = handleAttachments(cause.documentAttachmnts);
            cause.pictureAttachmnts =  handleAttachments(cause.pictureAttachmnts);
            return dateConverter(cause, dateFields, 'YYYY-MM-DD')
        })
        setRootCauses(tempRootCause)
  }
  
    const onChangeRootCause = (event, idx) => {
        const { name, value } = event.target;
        setRootCauses((causes) => 
            causes?.map((cause, cIdx) => 
            cIdx === idx ? { ...cause, [name]: value } : cause)
        );
    }

    const onRemoveRootCause = (rootIdx) => {
        setRootCauses((causes) =>{
            if(causes && causes.length > 1) {
                causes = causes.filter((cause, cIdx) => cIdx !== rootIdx)
            }
            return causes
        });  
    }

    const onUpdateRootCause = (rootIdx, itemIdx, itemType, val) => {
        setRootCauses((causes) => 
            causes?.map((cause, cIdx) => 
            cIdx === rootIdx ? { ...cause, [itemType]: val } : cause)
        );
        // let tempRootCause = rootCauses.map((cause, idx) => {
        //     if(rootIdx === idx) {
        //         if(Array.isArray(cause[itemType])) {
        //             cause[itemType] = val
        //         }
        //     }
        //     return cause;
        // });
        // handleRootCause(tempRootCause);
    }

    // const removeRootCause = (rootIdx, itemIdx, itemType) => {
    //     let tempRootCause = rootCauses.filter((cause, idx) => {
    //         if(rootIdx === idx) {
    //             if(itemType === 'cause') {
    //                 return null
    //             }
    //             if(Array.isArray(cause[itemType])) {
    //                 cause[itemType] = cause[itemType].filter((file, idx2) => itemIdx !==  idx2)
    //             }
    //         }
    //         return cause;
    //     });
    //     handleRootCause(tempRootCause);
    // }

    const addCause = () => {
        const team = [...rootCauses];
        team.push({...defaultCause});
        setRootCauses(team)
    }

    const PrintCmd2 = async () => {
      SetPrintFlash(false);
      setTimeout(() => {
        window.print();
        SetPrintFlash(true);
      }, 250);
    }

    const PrintCmd = async () => {
      try {
        let id = props.match.params.id;
        if(!id || printing) return;
        setPrinting(true);

        if (id) {
          let result = await API.GetData(`ResponseReport/Get?id=${id}&generatePDF=true`);
          if (result.data) {
            console.log(result);
            util.CreateLinkAndClick(result.data.pdfurl);
          }
        }
        util.hideLoader();
      } catch (error) {
        setState({ ...state, loaded: true });
        util.ShowNotification(error.message, 'error');
        util.hideLoader();
      } finally {
        setPrinting(false);
      }
    };

  const prepareState = () => {
    try{
      let copy = {...state.data};
      const ids = ["problemStatement_Description", "verifyInterimContainment", "verifyPermanentCorrective", "actionsToPreventRecurrence", "individualRecognition"];
      ids.forEach((key)=>{
        const ele = document.getElementById(key)
        if(ele && ele.innerHTML){
          let formattedText = ele.innerHTML.replace(/\n/g, "<br/>");
          copy[key] = formattedText;
        }
      })
      copy.rootCauses.forEach((r, i)=>{
        const ele = document.getElementById(`rootCauseNote-${i}`)
        if(ele && ele.innerHTML){
          let formattedText = ele.innerHTML.replace(/\n/g, "<br/>");
          copy.rootCauses[i] = {
            ...copy.rootCauses[i],
            note: formattedText
          }
        }
      })
      return copy;
    }catch(error){
      console.log("error", error);
    }
    return state.data;
  }

  return (
    <div>
        {
          PrintFlash ? (<>
          <SideMenu />
          <div className="dashboard">
            <Navbar />
            <section id="contents">
              <ImageLoader />
              <section className="statis text-center">
                <Confirm
                  handleClose={handleClose}
                  open={open}
                  onDelete={onDelete}
                />

                <div className="container-fluid">
                  {state.data !== null ? (
                    <div className="user_section complaints">
                      <div className="row margin-bottom">
                        <div className="col-md-12 text-left">
                          <h5 className="heading">
                            {EditMode ? "Edit " : "Add "}
                            8D Report
                            {EditMode ? (
                            <span className="d-flex" onClick={PrintCmd2}>
                              <i className="me-2 fas fa-print" style={{marginRight: "0.25rem"}}></i> Print
                            </span>
                          ) : null}
                          {EditMode ? (
                            <span className="d-flex" onClick={PrintCmd} style={{marginRight: "1rem"}}>
                              <i className="me-2 fas fa-file-pdf" style={{marginRight: "0.25rem"}}></i> 
                              {printing ? <PulseLoader size={5} color="#3e8bde" /> : "Generate PDF"}
                            </span>
                          ) : null}
                          </h5>
                        </div>
                      </div>

                      <form onSubmit={OnSubmit}>
                        <div className="row margin-bottom">
                          <div className="col-md-12 text-left">
                            <div className="row">
                              <div className="col-md-4">
                                <span>Date Open</span>
                                <div className="space">
                                  <input
                                    name="dateOpen"
                                    type={"date"}
                                    value={state.data.dateOpen}
                                    onChange={HandleChange}
                                  />
                                </div>
                              </div>
                              <div className="col-md-4">
                                <span>Initial Response</span>
                                <input
                                  name="initialResponse"
                                  type="text"
                                  placeholder="Initial Response"
                                  onChange={HandleChange}
                                  value={state.data.initialResponse}
                                />
                              </div>
                              <div className="col-md-4 fd-column">
                                
                                  <span>Customer Complaint No.</span>
                                  {/* <input
                                    name="customerComplaintNo"
                                    type="text"
                                    placeholder="Customer Complaint No."
                                    onChange={HandleChange}
                                    value={props.match.params.id}
                                    readOnly
                                    disabled
                                  /> */}

                            <select
                              name="customerComplaintsNo"
                              value={state.data.customerComplaintsNo}
                              onChange={HandleSelectChange}
                              id="customerComplaintsNo"
                              required
                            >
                              <option value="" selected hidden>
                                Select Customer Complaint No.
                              </option>
                              {customerComplaints.map(option => {
                               return <option value={option.value}>{option.label}</option>
                              })}
                            </select>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* ROW 1 */}
                        <div className="row margin-bottom">
                          <div className="col-md-12 text-left">
                            <div className="row">
                              <div className="col-md-4">
                                <span>Target Close Date</span>
                                <input
                                  name="targetCloseDate"
                                  type={"date"}
                                  value={state.data.targetCloseDate}
                                  onChange={HandleChange}
                                />
                              </div>
                              <div className="col-md-4">
                                <span>Revision Date</span>
                                <input
                                  name="revisionDate"
                                  type={"date"}
                                  value={state.data.revisionDate}
                                  onChange={HandleChange}
                                />
                              </div>
                              <div className="col-md-4 fd-column">
                                <span>8D Initiator</span>
                                <input
                                  name="initiator"
                                  type="text"
                                  placeholder="8D Initiator"
                                  onChange={HandleChange}
                                  value={state.data.initiator}
                                  // required
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* ROW 1 END */}
                        {/* ROW 2 */}
                        <div className="row margin-bottom">
                          <div className="col-md-12 text-left">
                            <div className="col-md-4">
                              <span>Actual Close Date</span>
                              <input
                                name="actualCloseDate"
                                type={"date"}
                                value={state.data.actualCloseDate}
                                onChange={HandleChange}
                              />
                            </div>
                          </div>
                        </div>
                        {/* ROW 2 END */}
                        {/* ROW 3 */}
                        <div className="row margin-bottom">
                          <div className="col-md-12 text-left">
                            <div className="col-md-12 text-left">
                              <span className="font-15">Who is effected by the problem?</span>
                            </div>
                            <div className="col-md-4">
                              <span>Company</span>
                              <input
                                name="company"
                                type="text"
                                placeholder="Company"
                                onChange={HandleChange}
                                value={state.data.company}
                                required
                              />
                            </div>
                            <div className="col-md-4">
                              <span>Address</span>
                              <input
                                name="address"
                                type="text"
                                placeholder="Address"
                                onChange={HandleChange}
                                value={state.data.address}
                              />
                            </div>
                            {/* <div className="col-md-4">
                              <span>Location</span>
                              <select
                                  name="location"
                                  value={state.data.location}
                                  onChange={HandleSelectChange}
                                >
                                  <option value="">
                                    Select Location
                                  </option>
                                  {locations.map((item) => {
                                    return <option value={item.value}>{item.label}</option>;
                                  })}
                                </select>
                            </div> */}
                          </div>
                        </div>
                        {/* ROW 3 END */}
                        {/* ROW 4 */}
                        <div className="row margin-bottom">
                          <div className="col-md-12 text-left">
                            <div className="">
                              <div className="col-md-4">
                                <span>Part No./ Code</span>
                                <input
                                  name="partNoCode"
                                  type="text"
                                  placeholder="Part No./ Code"
                                  onChange={HandleChange}
                                  value={state.data.partNoCode}
                                />
                              </div>
                              <div className="col-md-4">
                                <span>Product Name</span>
                                <input
                                  name="productName"
                                  type="text"
                                  placeholder="Product Name"
                                  onChange={HandleChange}
                                  value={state.data.productName}
                                />
                              </div>
                              {/* <div className="col-md-4">
                                <span>Type</span>
                                <input
                                  name="productType"
                                  type="productType"
                                  placeholder="Type"
                                  onChange={HandleChange}
                                  value={state.data.productType}
                                />
                              </div> */}
                            </div>
                          </div>
                        </div>
                        {/* ROW 4 END */}
                        {/* row 5*/}
                        <div className="row margin-bottom">
                          <div className="col-md-12 text-left">
                            <div className="col-md-12 text-left">
                              <span className="font-15">Team member Names/Titles</span>
                            </div>
                            <div className="col-md-4">
                              <span>Champion</span>
                              <input
                                name="champion"
                                type="text"
                                placeholder="Champion"
                                onChange={HandleChange}
                                value={state.data.champion}
                              />
                            </div>
                            <div className="col-md-4 fd-column">
                              <span>Team Leader</span>
                              <input
                                name="teamLeaderId"
                                type="text"
                                placeholder="Team Leader"
                                onChange={HandleChange}
                                value={state.data.teamLeaderId}
                                // required
                              />
                            </div>
                            <div className="col-md-4 fd-column">
                              <span>Team Members</span>
                              <input
                                name="teamMembers"
                                type="text"
                                placeholder="Team Members"
                                onChange={HandleChange}
                                value={state.data.teamMembers}
                              />
                            </div>
                          </div>
                        </div>
                        {/* row 5 end */}
                        {/* ROW 6 */}
                        <div className="row margin-bottom">
                          <div className="col-md-12 text-left">
                            <div className="col-md-8">
                              <span>
                                Problem Statement/Description:
                              </span>
                              <textarea
                                id="problemStatement_Description"
                                rows="8"
                                columns="12"
                                placeholder="Notes"
                                onChange={HandleChange}
                                value={state.data.problemStatement_Description}
                                name="problemStatement_Description"
                              />
                            </div>
                            {/* Attachment Section */}
                             <Attachments 
                                title="Document Attachments" 
                                name="problemStatementDA" 
                                attachmnts={problemStatementDA} 
                                handleFile={(e, fileId, index) => fileUpload(e, problemStatementDA, index, setProblemStatementDA)}
                                removeFile={(index) => removeAttachments(problemStatementDA, index, setProblemStatementDA)}
                            />
                            <Attachments 
                                title="Picture Attachments" 
                                name="problemStatementPA" 
                                attachmnts={problemStatementPA} 
                                handleFile={(e, fileId, index) => fileUpload(e, problemStatementPA, index, setProblemStatementPA)}
                                removeFile={(index) => removeAttachments(problemStatementPA, index, setProblemStatementPA)}
                            />
                            {/* Attachment Section end */}
                          </div>
                        </div>
                        {/* row 6 end*/}
                        {/* row 7*/}
                        <div className="row margin-bottom">
                          <div className="col-md-12 text-left">
                            <div className="col-md-12 text-left">
                              <span className="font-15">Choose and verify Interim Containment Actions:</span>
                            </div>
                            <div className="col-md-8"> 
                              <span>Notes</span>
                              <textarea
                                id="verifyInterimContainment"
                                rows="8"
                                columns="12"
                                placeholder="Notes"
                                onChange={HandleChange}
                                value={state.data.verifyInterimContainment}
                                name="verifyInterimContainment"
                              />
                            </div>
                            <div className="col-md-4 mb-1">
                              <span>% Effective</span>
                              <input
                                name="interimContainmentEffective"
                                type="number"
                                min="0"
                                max="100"
                                placeholder="% Effective"
                                onChange={(e) => {
                                    const { value } = e.target;
                                    const num = +value
                                    if(!isNaN(+num) && (0 <= num && num <= 100)) {
                                        HandleChange(e)
                                    }
                                }}
                                value={state.data.interimContainmentEffective}
                              />
                            </div>
                            <div className="col-md-4 mb-1">
                              <span>Target Date</span>
                              <input
                                name="interimContainmentTagetDate"
                                type={"date"}
                                value={state.data.interimContainmentTagetDate}
                                onChange={HandleChange}
                              />
                            </div>
                            <div className="col-md-4 mb-1">
                              <span>Actual Date</span>
                              <input
                                name="interimContainmentActualDate"
                                type={"date"}
                                value={
                                  state.data.interimContainmentActualDate
                                }
                                onChange={HandleChange}
                              />
                            </div>
                            {/* Attachment Section */}
                            <Attachments 
                                title="Document Attachments"
                                name="interimContainmentDA" 
                                attachmnts={interimContainmentDA} 
                                handleFile={(e, fileId, index) => fileUpload(e, interimContainmentDA, index, setInterimContainmentDA)}
                                removeFile={(index) => removeAttachments(interimContainmentDA, index, setInterimContainmentDA)}
                            />
                            <Attachments 
                                title="Picture Attachments" 
                                name="interimContainmentPA" 
                                attachmnts={interimContainmentPA} 
                                handleFile={(e, fileId, index) => fileUpload(e, interimContainmentPA, index, setInterimContainmentPA)}
                                removeFile={(index) => removeAttachments(interimContainmentPA, index, setInterimContainmentPA)}
                            />
                            {/* Attachment Section end */}
                          </div>
                        </div>
                        {/* row 7 end*/}
                        {/* row 8*/}
                                          <div className="row margin-bottom">
                                              <div className="col-md-12 text-left">
                                                  <div className="col-md-12">
                                                      <span>Define and Verify Root Causes:</span>
                                                  </div>
                                                  {rootCauses.map((cause, idx) => {
                                                      return <>
                                                       {/* <div className="col-md-12 mt-1 mb-1">
                                                                <div className="d-flex justify-content-between">
                                                                      <div>
                                                                          <b>Cause {idx + 1}:</b>
                                                                      </div>
                                                                      <div>
                                                                          <i onClick={() => onRemoveRootCause(idx)} className="fa fa-plus-circle" aria-hidden="true"></i>
                                                                      </div>
                                                                  </div>
                                                            </div> */}
                                                          {/* Cause start */}
                                                          <div className="col-md-12 d-flex justify-content-between">
                                                            <div>
                                                            </div>
                                                            <div>
                                                                {rootCauses.length > 1 ? <i onClick={() => onRemoveRootCause(idx)} className="fa fa-plus-circle hand" aria-hidden="true"></i> : null}
                                                            </div>
                                                          </div>
                                                          <div className="col-md-8">
                                                              <span><b>Cause {idx + 1}:</b></span>
                                                              <textarea
                                                                  id={`rootCauseNote-${idx}`}
                                                                  rows="5"
                                                                  columns="12"
                                                                  placeholder="Notes"
                                                                  onChange={(e) => onChangeRootCause(e, idx)}
                                                                  name="note"
                                                                  value={cause.note}
                                                              />
                                                          </div>
                                                          <div className="col-md-4 mb-1">
                                                              <span>% Contribution</span>
                                                              <input
                                                                  name="contribution"
                                                                  type="number"
                                                                  min="0"
                                                                  max="100"
                                                                  placeholder="% Contribution"
                                                                  onChange={(e) => {
                                                                    const { value } = e.target;
                                                                    const num = +value
                                                                    if(!isNaN(+num) && (0 <= num && num <= 100)) {
                                                                        onChangeRootCause(e, idx)
                                                                    }
                                                                }}
                                                                //   onChange={(e) => onChangeRootCause(e, idx)}
                                                                  value={cause.contribution}
                                                              />
                                                          </div>
                                                          <div className="col-md-4 mb-1">
                                                              <span>Target Date</span>
                                                              <input
                                                                  name="targetDate"
                                                                  type={"date"}
                                                                  value={cause.targetDate}
                                                                  onChange={(e) => onChangeRootCause(e, idx)}
                                                              />
                                                          </div>
                                                          <div className="col-md-4 mb-1">
                                                              <span>Actual Date</span>
                                                              <input
                                                                  name="actualDate"
                                                                  type={"date"}
                                                                  value={cause.actualDate}
                                                                  onChange={(e) => onChangeRootCause(e, idx)}
                                                              />
                                                          </div>
                                                          {/* Attachment Section */}
                                                            <Attachments 
                                                            title="Document Attachments"
                                                            name={"causeDocumentAttachmnts" + idx} 
                                                            attachmnts={cause.documentAttachmnts} 
                                                            // handleFile={(e, fileId, index) => HandleFile(e, fileId, index, 'doc')}
                                                            // removeFile={(index) => removeRootCause(idx, index, 'documentAttachmnts')}
                                                            handleFile={(e, fileId, index) => fileUpload(e, cause.documentAttachmnts, index, (val) => onUpdateRootCause(idx, index, 'documentAttachmnts', val))}
                                                            removeFile={(index) => removeAttachments(cause.documentAttachmnts, index, (val) => onUpdateRootCause(idx, index, 'documentAttachmnts', val))} 
                                                            />
                                                            <Attachments 
                                                            title="Picture Attachments" 
                                                            name={"causePictureAttachmnts" + idx}
                                                            attachmnts={cause.pictureAttachmnts} 
                                                            // handleFile={(e, fileId, index) => HandleFile(e, fileId, index, 'img')}
                                                            // removeFile={(index) => removeRootCause(idx, index, 'pictureAttachmnts')}
                                                            handleFile={(e, fileId, index) => fileUpload(e, cause.pictureAttachmnts, index, (val) => onUpdateRootCause(idx, index, 'pictureAttachmnts', val))}
                                                            removeFile={(index) => removeAttachments(cause.pictureAttachmnts, index, (val) => onUpdateRootCause(idx, index, 'pictureAttachmnts', val))}  
                                                            />
                                                          {/* Attachment Section end */}
                                                          {/* Cause end */}
                                                      </>;
                                                  })}
                                              </div>
                                                  {/* Cause Add Button */}
                                                    <div className="col-md-12 add-cause">
                                                      <button onClick={addCause} type="button"><BsPlusLg /></button>
                                                  </div>
                                                  {/* Cause Add Button end */}
                                          </div>
                        {/* row 8 end*/}
                        {/* row 9*/}
                        <div className="row margin-bottom">
                          <div className="col-md-12 text-left">
                            <div className="col-md-12 text-left">
                              <span className="font-15">Choose and Verify Permanent Corrective Actions:</span>
                            </div>
                            <div className="col-md-8">
                              <span>Notes</span>
                              <textarea
                                id="verifyPermanentCorrective"
                                rows="8"
                                columns="12"
                                placeholder="Notes"
                                onChange={HandleChange}
                                value={state.data.verifyPermanentCorrective}
                                name="verifyPermanentCorrective"
                              />
                            </div>
                            <div className="col-md-4 mb-1">
                              <span>% Effective</span>
                              <input
                                name="verifyPermanentCorrectiveEffective"
                                type="number"
                                min={0}
                                max={100}
                                placeholder="% Effective"
                                onChange={(e) => {
                                    const { value } = e.target;
                                    const num = +value
                                    if(!isNaN(+num) && (0 <= num && num <= 100)) {
                                        HandleChange(e)
                                    }
                                }}
                                value={
                                  state.data.verifyPermanentCorrectiveEffective
                                }
                              />
                            </div>
                            <div className="col-md-4 mb-1">
                              <span>Target Date</span>
                              <input
                                name="verifyPermanentCorrectiveTagetDate"
                                type="date"
                                value={state.data.verifyPermanentCorrectiveTagetDate}
                                onChange={HandleChange}
                              />
                            </div>
                            <div className="col-md-4 mb-1">
                              <span>Actual Date</span>
                              <input
                                name="verifyPermanentCorrectiveActualDate"
                                type="date"
                                value={state.data.verifyPermanentCorrectiveActualDate}
                                onChange={HandleChange}
                              />
                            </div>
                            {/* Attachment Section */}
                            <Attachments 
                                title="Document Attachments"
                                name="verifyPermanentCorrectiveDA" 
                                attachmnts={verifyPermanentCorrectiveDA} 
                                handleFile={(e, fileId, index) => fileUpload(e, verifyPermanentCorrectiveDA, index, setVerifyPermanentCorrectiveDA)}
                                removeFile={(index) => removeAttachments(verifyPermanentCorrectiveDA, index, setVerifyPermanentCorrectiveDA)}
                            />
                            <Attachments 
                                title="Picture Attachments" 
                                name="verifyPermanentCorrectivePA" 
                                attachmnts={verifyPermanentCorrectivePA} 
                                handleFile={(e, fileId, index) => fileUpload(e, verifyPermanentCorrectivePA, index, setVerifyPermanentCorrectivePA)}
                                removeFile={(index) => removeAttachments(verifyPermanentCorrectivePA, index, setVerifyPermanentCorrectivePA)}
                            />
                            {/* Attachment Section end */}
                          </div>
                        </div>
                        {/* row 9 end*/}
                        {/* row 10*/}
                        <div className="row margin-bottom">
                          <div className="col-md-12 text-left">
                            <div className="col-md-12 text-left">
                              <span className="font-15">Implement and Validate Permanent Corrective Actions:</span>
                            </div>
                            <div className="col-md-8">
                              <span>Notes</span>
                              <textarea
                                rows="8"
                                columns="12"
                                placeholder="Notes"
                                onChange={HandleChange}
                                value={state.data.validatePermanentCorrective}
                                name="validatePermanentCorrective"
                              />
                            </div>
                            <div className="col-md-4 mb-1">
                              <span>Target Date</span>
                              <input
                                name="validatePermanentCorrectiveTagetDate"
                                type={"date"}
                                value={
                                  state.data
                                    .validatePermanentCorrectiveTagetDate
                                }
                                onChange={HandleChange}
                              />
                            </div>
                            <div className="col-md-4 mb-1">
                              <span>Actual Date</span>
                              <input
                                name="validatePermanentCorrectiveActualDate"
                                type={"date"}
                                value={
                                  state.data
                                    .validatePermanentCorrectiveActualDate
                                }
                                onChange={HandleChange}
                              />
                            </div>
                          </div>
                        </div>
                        {/* row 10 end*/}
                        {/* row 11*/}
                        <div className="row margin-bottom">
                          <div className="col-md-12 text-left">
                            <div className="col-md-12 text-left">
                              <span className="font-15">System Prevention Actions to Prevent Recurrence:</span>
                            </div>
                            <div className="col-md-8">
                              <span>
                                Mistake Proofing: How are you going to ensure it can't
                                happen again?
                              </span>
                              <textarea
                                id="actionsToPreventRecurrence"
                                rows="8"
                                columns="12"
                                placeholder="Notes"
                                onChange={HandleChange}
                                value={state.data.actionsToPreventRecurrence}
                                name="actionsToPreventRecurrence"
                              />
                            </div>
                            <div className="col-md-4 mb-1">
                              <span>Target Date</span>
                              <input
                                name="actionsToPreventRecurrenceTagetDate"
                                type={"date"}
                                value={
                                  state.data.actionsToPreventRecurrenceTagetDate
                                }
                                onChange={HandleChange}
                              />
                            </div>
                            <div className="col-md-4 mb-1">
                              <span>Actual Date</span>
                              <input
                                name="actionsToPreventRecurrenceActualDate"
                                type={"date"}
                                value={
                                  state.data
                                    .actionsToPreventRecurrenceActualDate
                                }
                                onChange={HandleChange}
                              />
                            </div>
                            {/* Attachment Section */}
                            <Attachments 
                                title="Document Attachments"
                                name="validatePermanentCorrectiveDA" 
                                attachmnts={validatePermanentCorrectiveDA} 
                                handleFile={(e, fileId, index) => fileUpload(e, validatePermanentCorrectiveDA, index, setValidatePermanentCorrectiveDA)}
                                removeFile={(index) => removeAttachments(validatePermanentCorrectiveDA, index, setValidatePermanentCorrectiveDA)}
                            />
                            <Attachments 
                                title="Picture Attachments" 
                                name="validatePermanentCorrectivePA" 
                                attachmnts={validatePermanentCorrectivePA} 
                                handleFile={(e, fileId, index) => fileUpload(e, validatePermanentCorrectivePA, index, setValidatePermanentCorrectivePA)}
                                removeFile={(index) => removeAttachments(validatePermanentCorrectivePA, index, setValidatePermanentCorrectivePA)}
                            />
                            {/* Attachment Section end */}
                          </div>
                        </div>
                        {/* row 11 end*/}
                        {/* row 12*/}
                        <div className="row margin-bottom">
                          <div className="col-md-12 text-left">
                            <div className="col-md-8">
                              <span>
                                Team and Individual Recognition:
                              </span>
                              <textarea
                                id="individualRecognition"
                                rows="8"
                                columns="12"
                                placeholder="Notes"
                                onChange={HandleChange}
                                value={state.data.individualRecognition}
                                name="individualRecognition"
                              />
                            </div>
                          </div>
                        </div>
                        {/* row 12 end*/}
                        {/* row 13*/}
                        <div className="row">
                          <div className="col-md-12 text-left checkboxes-wrapper">
                            <div className="col-md-12 text-left">
                              <span className="font-15">
                                Has Corrective Action/Implmentation been Reviewed
                                against Documents?
                              </span>
                            </div>
                            <div className="col-md-12 text-left">
                              <span>Check boxes that apply</span>
                            </div>
                            <div className="col-md-2">
                              <label>
                                <input
                                  name="controlPlanEnabled"
                                  type={"checkbox"}
                                  checked={state.data.controlPlanEnabled}
                                  onChange={onChange}
                                />
                                <span>Control Plan</span>
                              </label>
                            </div>
                            <div className="col-md-2">
                              <label>
                                <input
                                  name="fmeaEnabled"
                                  type={"checkbox"}
                                  checked={state.data.fmeaEnabled}
                                  onChange={onChange}
                                />
                                <span>FMEA</span>
                              </label>
                            </div>
                            <div className="col-md-2">
                              <label>
                                <input
                                  name="flowchartEnabled"
                                  type={"checkbox"}
                                  checked={state.data.flowchartEnabled}
                                  onChange={onChange}
                                />
                                <span>Flow Chart</span>
                              </label>
                            </div>
                            <div className="col-md-2">
                              <label>
                                <input
                                  name="procWorkIndusEnabled"
                                  type={"checkbox"}
                                  checked={state.data.procWorkIndusEnabled}
                                  onChange={onChange}
                                />
                                <span>Proc./Work Instr.</span>
                              </label>
                            </div>
                            <div className="col-md-2">
                              <label>
                                <input
                                  name="addToInternalAuditEnabled"
                                  type={"checkbox"}
                                  checked={state.data.addToInternalAuditEnabled}
                                  onChange={onChange}
                                />
                                <span>Add to internal</span>
                              </label>
                            </div>
                          </div>
                          {/* </div> */}
                        </div>
                        {/* row 13 end*/}
                        <div className="row margin-bottom">
                          <div className="col-md-12 text-left">
                            <div className="col-md-12">
                              <div className="buttons">
                                <button
                                  type="submit"
                                  className="update"
                                  disabled={progress}
                                >
                                  {EditMode ? "Update" : "Add"}
                                </button>
                                <button onClick={GoBack} className="cancel">
                                  Cancel
                                </button>
                                {util.isAdmin() && EditMode ? (
                                  <i
                                    onClick={onAlert}
                                    className="fa fa-trash"
                                    aria-hidden="true"
                                  ></i>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* row 13 end*/}
                      </form>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </section>
            </section>
          </div>
          { showSelectors.metalType ? (
              <div
                className="backdrop"
                onClick={() => {
                  DisableBackDrop();
                }}
              ></div>
          ) : null}
        </>) : <Report8DPrintForm rootCauses={rootCauses} state={prepareState()} id={props.match.params.id} />
        }
    </div>
  );
};
export default withRouter(ReportDetail8D);
