import React, { Component } from "react";
import Navbar from "../../containers/navbar";
import ImageLoader from "../../containers/loader";
import SideMenu from "../../containers/sidemenu";
import * as API from "../../services/api";
import * as util from "../../Util";
import { withRouter } from "react-router-dom";
import moment from "moment";
import Pagination from "react-js-pagination";

class DockAuditForms extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filteredData: [],
      status: "All", // i.e. open
      use_api: "DockAuditForm/GetDockAuditForms?",
      latestSearch: undefined,
      activePage: 1,
      APIPageNumber: 1,
      APIPageSize: 100,
      Inprogress: false,
      searchQuery: "",
    };
  }
  distinctUntilChangeTimeout = null;

  async componentDidMount() {
    this.GetData(true);
  }

  filterSearchQuery = (query, array) => {
    return array.filter((val) => val.orderNo.indexOf(query) !== -1);
  };

  GetData = async (concatResult, filterString = "") => {
    if (util.isLoggedIn() === false) {
      this.props.history.push("/login");
    }
    util.showLoader();

    try {
      let result = await API.GetRecords(
        this.state.use_api,
        this.state.APIPageNumber,
        this.state.APIPageSize
      );

      if (result.data != null && result.data != undefined) {
        console.log("result", result);
        if (result.data.data.items.length < this.state.APIPageSize) {
          // Means end of records reached
          this.state.APIPageNumber = null;
        } else {
          this.state.APIPageNumber = this.state.APIPageNumber + 1;
        }

        if (concatResult) {
          this.state.filteredData = this.state.filteredData.concat(
            result.data.data.items
          );
        } else {
          this.state.filteredData = result.data.data.items;
        }

        this.setState({
          filteredData: filterString
            ? this.filterSearchQuery(filterString, this.state.filteredData)
            : this.state.filteredData,
        });
      } else {
        throw "err";
      }
    } catch (err) {
      this.setState({ ...this.state, loaded: true });
    } finally {
      util.hideLoader();
    }
  };
  filterSearch = (val, arr) => {
    if (val !== "All") {
      arr = arr.filter((value) => value.status === val.toUpperCase());
    }
    return arr;
  };

  onSearch = (e) => {
    if (this.distinctUntilChangeTimeout) {
      clearTimeout(this.distinctUntilChangeTimeout);
    }

    const debounceTime = 400;
    let value = e.target.value;
    let temp = Math.random();
    this.state.latestSearch = temp;
    this.distinctUntilChangeTimeout = setTimeout(async () => {
      util.showLoader();
      this.setState({ searchQuery: value.trim() });

      if (!value) {
        this.setState(
          {
            activePage: 1,
            APIPageNumber: 1,
            filteredData: [],
          },
          () => {
            this.GetData(false);
          }
        );
        return;
      }

      try {
        let result = await API.searchDockForm(value);
        // Check if latest search query ?
        if (this.state.latestSearch != temp) {
          return;
        }
        if (result.data != null) {
          const items = result.data.data.items;
          const data =
            items.length > 0
              ? this.filterSearch(this.state.status, result.data.data.items)
              : items;
          this.setState(
            {
              filteredData: data,
            },
            () => {
              // debugger
              // Filter by current drop-down value
              // this.onDropDownChange({ target: { value: this.state.status } });
            }
          );
        } else {
          throw "err";
        }
      } catch (error) {
      } finally {
        util.hideLoader();
        this.setState({
          activePage: 1,
          APIPageNumber: 1,
        });
      }
    }, debounceTime);
  };

  onDropDownChange = async (e) => {
    let value = e.target.value;
    console.log("result", value);
    let temp = "";
    if (value == "All") {
      temp = "DockAuditForm/GetDockAuditForms?";
    } else if (value == "Assigned") {
      temp = "DockAuditForm/GetDockAuditFormsAssignedToMe?";
    } else {
      temp = `DockAuditForm/GetDockAuditForms?status=${value}&`;
    }
    this.setState(
      {
        use_api: temp,
        status: value,
        activePage: 1,
        APIPageNumber: 1,
      },
      () => {
        this.GetData(false, this.state.searchQuery);
      }
    );
  };
  GetStatus = (status) => {
    if (util.ReduceString(status) == "approved") {
      return (
        <span className="dot green-dot">
          <i class="fas fa-check"></i>
        </span>
      );
    } else {
      return (
        <span className="dot yellow-dot">
          <i class="fas fa-hourglass-end"></i>
        </span>
      );
    }
  };

  handlePageChange = (pageNumber) => {
    console.log(
      `active page is ${pageNumber} total docks is ${this.state.filteredData.length}`
    );
    this.setState({ activePage: pageNumber });

    if (this.state.searchQuery) {
      return;
    }

    if (this.state.APIPageNumber != null && !this.state.Inprogress) {
      if (this.state.filteredData.length - pageNumber * 12 <= 30) {
        this.state.Inprogress = true;
        this.GetData(true);
      }
    }
  };

  ExportExcel = async () => {
    console.log("export excel file");
    let result = await API.GetData("DockAuditForm/ExportDockAuditExcel");
    console.log(result);
    if (result.data) {
      util.CreateLinkAndClick(result.data, "dock-audits");
    }
  };

  render() {
    const { props } = this;
    return (
      <div>
        <SideMenu />
        <div class="dashboard">
          <Navbar onSearch={this.onSearch} Heading={"Dock Audit Forms"} />
          <section id="contents">
            <ImageLoader />
            <section class="statis text-center">
              <div class="container-fluid">
                <div class="row"></div>
                <div class="row user_section">
                  <div class="form-group statusSelect">
                    <span>
                      <i
                        className="fas fa-file-export hand"
                        onClick={this.ExportExcel}
                      ></i>
                      EXPORT
                    </span>
                    <select
                      className="statusDropwdown"
                      id="viewselect"
                      onChange={this.onDropDownChange}
                    >
                      <option value={"All"} selected>
                        All
                      </option>
                      <option value={"Assigned"}>Assigned</option>
                      <option value={"approved"}>Approved</option>
                      <option value={"pending"}>Pending</option>
                    </select>
                  </div>
                  <div class="col-md-12">
                    {this.state.filteredData.length != 0 ? (
                      <div>
                        {this.state.filteredData
                          .filter((val, idx) => {
                            let low, high;
                            low = this.state.activePage * 12 - 12;
                            high = this.state.activePage * 12;
                            console.log(low, high, "pagi");
                            if (idx >= low && idx < high) return true;
                            else return false;
                          })
                          .map((item) => {
                            return (
                              <div
                                class="col-md-4"
                                onClick={() => {
                                  this.props.history.push(
                                    "/dock-audit-forms/" + item.orderNo
                                  );
                                }}
                              >
                                <div class="section_second dock-audit-forms">
                                  <div className="row">
                                    <div class="img-left">
                                      <img src="/images/dock-audit-ico.png" />
                                    </div>
                                    <div class="content-right">
                                      <div class="row">
                                        <div class="col-md-6 text-left">
                                          <span>Date</span>
                                          <label>
                                            {moment(item.date).format(
                                              "MMM DD, YYYY"
                                            )}
                                          </label>
                                        </div>
                                        <div class="col-md-6 text-left">
                                          <span>Order Number</span>

                                          <label>#{item.orderNo}</label>
                                        </div>
                                      </div>
                                      <div class="row">
                                        <div class="col-md-12 text-left">
                                          <span>Submitted by</span>
                                          {item.submittedBy}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="row status-marker">
                                    {this.GetStatus(item.status)}
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    ) : (
                      "No Dock Audit Forms"
                    )}
                  </div>
                </div>
                {this.state.filteredData.length != 0 ? (
                  <div className="row">
                    <Pagination
                      hideFirstLastPages
                      prevPageText="Prev"
                      nextPageText="Next"
                      activePage={this.state.activePage}
                      itemsCountPerPage={12}
                      totalItemsCount={this.state.filteredData.length}
                      pageRangeDisplayed={5}
                      onChange={this.handlePageChange}
                      className="custom_pagination"
                      innerClass="custom_pagination"
                    />
                  </div>
                ) : null}
              </div>
            </section>
          </section>
        </div>
      </div>
    );
  }
}

export default withRouter(DockAuditForms);
