import React, { useEffect } from "react";
import { localDate } from "../Util";

const ServiceRequestPrintForm = props => {
  useEffect(() => {
    console.log(props, "print");
  }, []);

  const GetValues = data => {
    if (data == null || data == undefined) {
      return "NA";
    }
    data = data.replace(/[^A-Za-z',\s\d]/g, "").split(",");
    console.log("rs", data);
    if (data.length == 1 && data[0] == "") {
      return "NA";
    }
    return data.map((val, id) => {
      if (id + 1 == data.length) {
        return val;
      }
      return val + ", ";
    });
  };

  return (
    <div id="print-form">
      <div className="row">
        <div className="col-md-6">
          <img className="header-logo" src="/images/logo-print.png" />
        </div>
        <div className="col-md-6">
          <div className="address-block">
            7600 Kensington Ct. Brighton, MI 48116
            <div>Ph: 248-446-9500 ex. 111 Fax: 248-446-1500</div>
            <span className="email-detail">www.fortechproducts.com</span>
          </div>
        </div>
      </div>
      <div className="row form-details">
        <div className="col-md-12">
          <h4>
            Service Request{" "}
            <span>
              #<strong>{props.Data.data.tsrNo}</strong>
            </span>
          </h4>
          <div className="row details-head">
            <div className="col-md-3">
              <div>
                <span>Date</span>
                <label>{localDate(props.Data.data.date)}</label>
              </div>
            </div>
            <div className="col-md-3">
              <div>
                <span>Tsr No</span>
                <label>TSR-{props.Data.data.tsrNo}</label>
              </div>
            </div>
            {/* <div className="col-md-3">
              <div>
                <span>Customer id</span>
                <label>{props.Data.data.customerId}</label>
              </div>
            </div> */}
            <div className="col-md-3">
              <div>
                <span>Customer Name</span>
                <label>{props.Data.data.customerName}</label>
              </div>
            </div>
            <div className="col-md-3">
              <div>
                <span>Sales Owner</span>
                <label>{props.Data.data.salesOwner}</label>
              </div>
            </div>
          </div>
          <div className="row form-label-values">
            <div className="col-md-4">
              <div>
                <span>Lab Report Required</span>
                <label>
                  {props.Data.data.labReportRequired &&
                  props.Data.data.labReportRequired
                    .toLowerCase()
                    .includes("yes")
                    ? "Yes"
                    : "No"}
                </label>
              </div>
            </div>
            <div className="col-md-4">
              <div>
                <span>External Report Required</span>
                <label>
                  {" "}
                  {props.Data.data.labReportRequired &&
                  props.Data.data.labReportRequired
                    .toLowerCase()
                    .includes("yes") &&
                  props.Data.data.labReportRequired
                    .toLowerCase()
                    .includes("external")
                    ? "Yes"
                    : "No"}
                </label>
              </div>
            </div>
            {/* <div className="col-md-4">
              <div>
                <span>Request Completion Date</span>
                <label>
                  {localDate(props.Data.data.requestedCompletionDate)}
                </label>
              </div>
            </div> */}
            <div className="col-md-4">
              <div>
                <span>Severity Level</span>
                <label>{props.Data.data.severityLevel || "NA"}</label>
              </div>
            </div>

            <div className="col-md-4">
              <div>
                <span>Product</span>
                <label>{GetValues(props.Data.data.product)}</label>
              </div>
            </div>
            <div className="col-md-4">
              <div>
                <span>Customer Part Type</span>
                <label>{props.Data.data.customerPartType || "NA"}</label>
              </div>
            </div>
            <div className="col-md-4">
              <div>
                <span>Metal Type</span>
                <label>{GetValues(props.Data.data.metalType)}</label>
              </div>
            </div>
            <div className="col-md-4">
              <div>
                <span>Competitive Product</span>
                <label>{props.Data.data.competitiveProduct || "NA"}</label>
              </div>
            </div>
            <div className="col-md-4">
              <div>
                <span>Concentration Used</span>
                <label>{props.Data.data.concentrationUsed || "NA"}</label>
              </div>
            </div>
            <div className="col-md-4">
              <div>
                <span>Competitive Product Price</span>
                <label>{props.Data.data.competitiveProductPrice || "NA"}</label>
              </div>
            </div>
            <div className="col-md-4">
              <div>
                <span>Volume Per Year</span>
                <label>{props.Data.data.volumePerYear || "NA"}</label>
              </div>
            </div>
            <div className="col-md-4">
              <div>
                <span>Detail Request Objective</span>
                <label>
                  {props.Data.data.detailedRequestObjective || "NA"}
                </label>
              </div>
            </div>
            <div className="col-md-4">
              <div>
                <span>Current Status</span>
                <label>
                  {props.Data.data.currentStatus || "NA"}
                </label>
              </div>
            </div>
            <div className="col-md-4">
              <div>
                <span>Lab Analysis Recommendation</span>
                <label>
                  {props.Data.data.labAnalysisRecommendations || "NA"}
                </label>
              </div>
            </div>
            <div className="col-md-4">
              <div>
                <span>LAB Report Url</span>
                <label>{props.Data.data.labReportUrl || "NA"}</label>
              </div>
            </div>
            {/* <div className="col-md-4">
              <div>
                <span>Complete Date</span>
                <label>
                  {localDate(props.Data.data.requestedCompletionDate)}
                </label>
              </div>
            </div> */}
            {/* <div className="col-md-4">
              <div>
                <span>Approved By</span>
                <label>{"NA"}</label>
              </div>
            </div> */}
            <div className="col-md-4">
              <div>
                <span>Customer Switching</span>
                <label>{props.Data.data.customerSwitching || "NA"}</label>
              </div>
            </div>
            {/* <div className="col-md-4">
              <div>
                <span>Is Product Like</span>
                <label>{"NA"}</label>
              </div>
            </div> */}
            <div className="col-md-4">
              <div>
                <span>Water Quality</span>
                <label>{props.Data.data.waterQuality || "NA"}</label>
              </div>
            </div>
            {/* <div className="col-md-4">
              <div>
                <span>Metallurgy</span>
                <label>{"NA"}</label>
              </div>
            </div> */}
            {/* <div className="col-md-12">
              <div>
                <span>Description</span>
                <label>{"NA"}</label>
              </div>
            </div> */}
            <div className="col-md-12">
              <div>
                <span>Comments</span>
              </div>
            </div>
            <div className="col-md-12 comment-lines"></div>
            <div className="col-md-12 comment-lines"></div>
            <div className="col-md-12 comment-lines"></div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ServiceRequestPrintForm;
