import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import * as util from "../../Util";
import * as API from "../../services/api";
import SideMenu from "../../containers/sidemenu";
import Navbar from "../../containers/navbar";
import ImageLoader from "../../containers/loader";
import Confirm from "../../containers/Confirm";
import moment from "moment";
import { readFileAsDataUrl } from "../../shared/Shared";
import Upload from "../Upload";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

let DeleteArrayImages = [];
const SupplierEvaluationReport = props => {
  const [open, setOpen] = useState(false);
  const [Inprogress, SetInprogress] = useState(false);

  const params = useParams();
  const [images, setImages] = useState();
  const [state, setState] = useState({ data: null, loaded: false });

  useEffect(() => {
    DeleteArrayImages = [];
    if (!util.isLoggedIn()) {
      props.history.push("/");
    }
    util.showLoader();
    let id = params.id;
    if (!id) {
      props.history.push("/supplier-evaluation-reports");
    }
    async function fetchData() {
      try {
        const images = await API.getSupplierImages(id);
        const sampleImages = [];
        for (let i = 0; i < 5; i++) {
          sampleImages.push({
            id: `image${i}`,
            name: `image${i}`,
            imageShown: { imageUrl: "" },
            fileId: null,
            binary: null
          });
        }
        const imageItems = images.data.data.items;
        console.log("images", imageItems);

        if (imageItems.length > 0) {
          for (let i = 0; i < imageItems.length; i++) {
            if (sampleImages[i]) {
              sampleImages[i].imageShown.imageUrl = imageItems[i].fileUrl;
              sampleImages[i].fileId = imageItems[i].fileId;
            }
          }
        }
        setImages(sampleImages);

        const result = await API.getSingleSupplierReport(id);

        if (result.data.data) {
          setState(prevState => {
            let obj = Object.assign({}, prevState);
            obj.data = result.data.data;
            obj.loaded = true;
            return obj;
          });
          util.hideLoader();
        }
      } catch (error) {
        setState({ ...state, loaded: true });
        util.hideLoader();
      }
    }
    fetchData();
  }, []);

  const onImageSelect = async (imageEvent, id, processArray = true) => {
    // debugger;
    imageEvent.persist();
    setError(null);
    const file = imageEvent.target.files[0];
    if (file && file.type.indexOf("image") !== -1) {
      const dataUrl = await readFileAsDataUrl(imageEvent.target.files[0]);
      console.log("data url rec", dataUrl);
      if (dataUrl.length > 0) {
        if (processArray) {
          const cloneImages = [...images];
          const myImage = cloneImages.find(val => val.id === id);
          if (myImage) {
            console.log("found");

            myImage.imageShown.imageUrl = dataUrl;
            myImage.binary = file;

            if (myImage.fileId) {
              let index = DeleteArrayImages.indexOf(myImage.fileId);
              if (index == -1) {
                DeleteArrayImages.push(myImage.fileId);
              }
            }
            console.log(cloneImages, myImage, DeleteArrayImages, "test");
            // console.log(cloneImages, myImage);

            setImages(cloneImages);
          }
        }
      }
    } else {
      setError("Invalid image format");
    }
  };

  const onCancel = e => {
    props.history.push("/supplier-evaluation-reports");
  };
  const [err, setError] = useState(null);

  const onChange = e => {
    e.persist();
    let { name, checked, type, value } = e.target;
    console.log(name, checked, type, value);

    setState(prevState => {
      let obj = Object.assign({}, prevState);
      obj.data[name] = type === "checkbox" ? checked : value;
      return obj;
    });
  };
  const onDropDownChange = e => {
    let { name, value } = e.target;
    console.log("name" + name + " " + "value" + value);
    setState(prevState => {
      let obj = Object.assign({}, prevState);
      obj.data[name] = value;
      return obj;
    });
  };
  const onDelete = async e => {
    SetInprogress(true);
    try {
      util.showLoader();
      let id = props.match.params.id;
      let result = await API.deleteSupplierForm(id);
      // console.log('##submit##', result.data)
      if (result) util.hideLoader();
      util.ShowNotification("Supplier evaluation report deleted");
      setOpen(false);
      props.history.push("/supplier-evaluation-reports");
    } catch (error) {
      util.hideLoader();
    } finally {
      SetInprogress(false);
    }
  };
  const onAlert = e => {
    setOpen(true);
  };
  const onSubmit = async e => {
    e.preventDefault();

    setError(null);

    //
    if (DeleteArrayImages.length) {
      let tempRes = await API.DeleteData("Files/Delete", DeleteArrayImages);
      console.log("test", tempRes);
    }
    //

    console.log("submitted", state);
    const imageClone = [...images];
    const uploadTasks = [];
    imageClone.forEach(val => {
      console.log("inserting image promise");
      if (val.binary) {
        console.log("binary val", val.binary);

        uploadTasks.push(API.fileUpload(val.binary));
      }
    });
    try {
      util.showLoader();
      for await (let task of uploadTasks) {
        console.log("awaiting image promise", task);
        // save the id
        await API.saveSupplierImage([
          {
            SupplierEvaluationId: props.match.params.id,
            FileId: task.data.data.id
          }
        ]);
      }

      // persist on server
      const data = { ...state }.data;
      console.log("state", data);

      const {
        coaLotNumberMatchContainer,
        containersClearlyMarked,
        containersInAcceptableCondition,
        dateProductDelivered,
        fortech800CodeAppearOnPaperwork,
        fortechProductNumbers,
        id,
        idRawMaterialInWarehouse,
        orderComments,
        purchaseOrderNumber,
        rawMaterialLotNumbers,
        signatureImageFileId,
        supplierId,
        nameSupplier
      } = data;

      const result = await API.updateSupplierInfo({
        coaLotNumberMatchContainer,
        containersClearlyMarked,
        containersInAcceptableCondition,
        dateProductDelivered,
        fortech800CodeAppearOnPaperwork,
        fortechProductNumbers,
        id,
        idRawMaterialInWarehouse,
        orderComments,
        purchaseOrderNumber,
        rawMaterialLotNumbers,
        signatureImageFileId,
        supplierId,
        nameSupplier
      });
      if (result) {
        util.hideLoader();
      }
      if (result.data.data) {
        util.ShowNotification(result.data.message);
        props.history.push("/supplier-evaluation-reports");
      } else {
        setError(result.data.message);
      }
    } catch (e) {
      util.hideLoader();
    }
  };
  const handleClose = () => {
    setOpen(false);
  };

  const RemoveImage = data => {
    let index = DeleteArrayImages.indexOf(data.fileId);
    if (index == -1) {
      DeleteArrayImages.push(data.fileId);
    }

    const cloneImages = [...images];
    const myImageIdx = cloneImages.findIndex(val => {
      return val.id === data.id;
    });
    if (myImageIdx != -1) {
      data.imageShown.imageUrl = "";
      cloneImages[myImageIdx] = data;
      setImages([...cloneImages]);
    }
  };

  return (
    <div>
      <SideMenu />
      <div className="dashboard">
        <Navbar />
        <section id="contents">
          <ImageLoader />
          <section className="statis text-center">
            <Confirm
              handleClose={handleClose}
              open={open}
              onDelete={onDelete}
            />

            <div className="container-fluid">
              {state.data ? (
                <div className="user_section complaints">
                  <div className="row margin-bottom">
                    <div className="col-md-12 text-left">
                      <h5 className="heading">Edit Evaluation Report</h5>
                    </div>
                  </div>

                  <form onSubmit={onSubmit}>
                    {/* supplier */}
                    <div className="row margin-bottom">
                      <div className="col-md-6 text-left">
                        <div className="row">
                          <div className="col-md-3">
                            <span>Supplier</span>
                          </div>
                          <div className="col-md-9">
                            <input
                              name="nameSupplier"
                              onChange={onChange}
                              value={state.data.nameSupplier}
                              type="text"
                              placeholder="Supplier"
                              required
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6 text-left">
                        <div className="row">
                          <div className="col-md-3">
                            <span>Fortech Product Numbers</span>
                          </div>
                          <div className="col-md-9">
                            <input
                              value={state.data.fortechProductNumbers}
                              type="text"
                              name="fortechProductNumbers"
                              onChange={onChange}
                              placeholder="Fortech Product Numbers"
                              required
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* purchase number */}
                    <div className="row margin-bottom">
                      <div className="col-md-6 text-left">
                        <div className="row">
                          <div className="col-md-3">
                            <span>Purchase order number</span>
                          </div>
                          <div className="col-md-9">
                            <input
                              value={state.data.purchaseOrderNumber}
                              type="text"
                              name="purchaseOrderNumber"
                              onChange={onChange}
                              placeholder="Purchase order number"
                              required
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6 text-left">
                        <div className="row">
                          <div className="col-md-3">
                            <span>Date Product was delivered</span>
                          </div>
                          <div className="col-md-9">
                            <DatePicker
                              dateFormat="MMM dd, yyyy"
                              selected={
                                new Date(state.data.dateProductDelivered)
                              }
                              onChange={date => {
                                console.log("ok", date);
                                setState(prevState => {
                                  let obj = Object.assign({}, prevState);
                                  obj.data.dateProductDelivered = date.toISOString();
                                  return obj;
                                });
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row margin-bottom">
                      <div className="col-md-6 text-left">
                        <div className="row">
                          <div className="col-md-8">
                            <span>
                              Is raw material in consignment warehouse?
                            </span>
                          </div>
                          <div className="col-md-4">
                            <input
                              value={state.data.idRawMaterialInWarehouse}
                              defaultChecked={
                                state.data.idRawMaterialInWarehouse
                              }
                              type="checkbox"
                              name="idRawMaterialInWarehouse"
                              onChange={onChange}
                              placeholder=""
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6 text-left">
                        <div className="row">
                          <div className="col-md-3">
                            <span>List all raw material lot numbers</span>
                          </div>
                          <div className="col-md-9">
                            <textarea
                              rows="5"
                              onChange={onChange}
                              placeholder="Lot numbers"
                              value={state.data.rawMaterialLotNumbers}
                              name="rawMaterialLotNumbers"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    {/*  */}
                    <div className="row margin-bottom">
                      <div className="col-md-12 text-left">
                        <div className="row">
                          <div className="col-md-6">
                            <span>
                              Are all containers in acceptable condition?
                              subheading - Containers free of leaks, holes,
                              dents, etc
                            </span>
                          </div>
                          <div className="col-md-6">
                            <input
                              value={state.data.containersInAcceptableCondition}
                              type="checkbox"
                              defaultChecked={
                                state.data.containersInAcceptableCondition
                              }
                              name="containersInAcceptableCondition"
                              onChange={onChange}
                              placeholder=""
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row margin-bottom">
                      <div className="col-md-12 text-left">
                        <div className="row">
                          <div className="col-md-6">
                            <span>
                              Does Fortech 800 code appear on paperwork AND
                              containers? SH - Code must be on BOL/packing list
                              and tote/drum/pail
                            </span>
                          </div>
                          <div className="col-md-6">
                            <input
                              value={state.data.fortech800CodeAppearOnPaperwork}
                              type="checkbox"
                              defaultChecked={
                                state.data.fortech800CodeAppearOnPaperwork
                              }
                              name="fortech800CodeAppearOnPaperwork"
                              onChange={onChange}
                              placeholder=""
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row margin-bottom">
                      <div className="col-md-12 text-left">
                        <div className="row">
                          <div className="col-md-6">
                            <span>
                              Does COA lot number match container and paperwork
                              lot number? sh - Lot # must match
                            </span>
                          </div>
                          <div className="col-md-6">
                            <input
                              value={state.data.coaLotNumberMatchContainer}
                              defaultChecked={
                                state.data.coaLotNumberMatchContainer
                              }
                              type="checkbox"
                              name="coaLotNumberMatchContainer"
                              onChange={onChange}
                              placeholder=""
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row margin-bottom">
                      <div className="col-md-12 text-left">
                        <div className="row">
                          <div className="col-md-6">
                            <span>
                              Are all containers clearly marked with proper
                              chemical name? sh - Refer to Raw Material List to
                              confirm chemical name
                            </span>
                          </div>
                          <div className="col-md-6">
                            <input
                              value={state.data.containersClearlyMarked}
                              defaultChecked={
                                state.data.containersClearlyMarked
                              }
                              type="checkbox"
                              name="containersClearlyMarked"
                              onChange={onChange}
                              placeholder=""
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row margin-bottom">
                      <div className="col-md-12 text-left">
                        <div className="row">
                          <div className="col-md-9">
                            <span>
                              Record any relevant comment about the
                              order(prototype, late, nonconformities, etc)- if
                              none, write NA
                            </span>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-8">
                            <textarea
                              rows="5"
                              onChange={onChange}
                              placeholder="Enter "
                              value={state.data.orderComments}
                              name="orderComments"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row margin-bottom">
                      <div className="col-md-12 text-left">
                        {images.map(val => {
                          return (
                            <span className="img-container">
                              <Upload
                                id={val.id}
                                name={val.name}
                                onSelect={e => {
                                  onImageSelect(e, val.id);
                                }}
                                imageShown={val.imageShown}
                              >
                                <i
                                  class="fa fa-plus-circle"
                                  aria-hidden="true"
                                ></i>
                              </Upload>
                              {val.imageShown.imageUrl ? (
                                <div className="file-uploader-add-icon">
                                  <i
                                    onClick={() => {
                                      RemoveImage(val);
                                    }}
                                    class="fas fa-times-circle"
                                    aria-hidden="true"
                                  ></i>
                                </div>
                              ) : null}
                            </span>
                          );
                        })}
                      </div>
                    </div>

                    {err ? (
                      <div
                        style={{
                          color: "red",
                          fontSize: "1.6rem"
                        }}
                      >
                        {"Error: " + err}
                      </div>
                    ) : null}

                    <div className="row margin-bottom">
                      <div className="col-md-12 text-left">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="buttons">
                              <button
                                type="submit"
                                className="update"
                                disabled={Inprogress}
                              >
                                Update
                                {/* {editMode ? "Update" : "Add"} */}
                              </button>
                              <button
                                onClick={onCancel}
                                className="cancel"
                                disabled={Inprogress}
                              >
                                Cancel
                              </button>
                              {util.isAdmin() ? (
                                <i
                                  onClick={onAlert}
                                  class="fa fa-trash"
                                  aria-hidden="true"
                                ></i>
                              ) : null}
                              {/* {editMode ? (
                                    <i
                                      onClick={onAlert}
                                      className="fa fa-trash-o delete"
                                      aria-hidden="true"
                                    ></i>
                                  ) : null} */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              ) : null}
            </div>
          </section>
        </section>
      </div>
    </div>
  );
};
export default SupplierEvaluationReport;
