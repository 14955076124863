import React, { useState, useEffect } from "react";

import * as Api from "../services/api";
// import * as Utility from "../utility";
import Select, { components } from "react-select";

let initialValues = {
  list: [],
  value: "",
  query: "",
  // skipReq: false,
  CustName: "",
  InitVal: "",
  CallBack: "",
  Placeholder: "",
  name: "",
  required: true,
};

class AutoSearchCtrl extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...initialValues,
    };
  }

  componentDidMount() {
    this.props.InitVal((obj) => {
      this.state.value = this.props.InitVal;
      this.setState({
        value: obj,
      });
    });

    // this.setState({ ...this.state });
    // FetchData("");
    console.log(this.props.initialValues, "check");
  }

  IsLatest = undefined;
  FetchData = async (query) => {
    let temp = Math.random();
    this.IsLatest = temp;
    let res = await Api.GetQueried(this.props.endpoint + "?query=" + query);
    console.log(res, "RES");
    if (temp != this.IsLatest) {
      return;
    }
    if (res.data && res.data.data != null) {
      res = res.data.data.items;
      res = res.map((ele) => {
        return {
          value: ele.id,
          label: ele.fullName,
        };
      });
      this.setState({
        list: res,
      });
      console.log(res, "ok options");
    }
  };

  Reset = () => {
    if (this.props.CallBack != undefined) {
      this.props.CallBack(this.state.value);
    }
    console.log(this.state, "call back");
  };

  syncVar = undefined;
  searchOnInput = (text) => {
    if (this.syncVar) clearInterval(this.syncVar);
    this.syncVar = setTimeout(() => {
      if (text.length >= 3) {
        // if (text.length >= 3 && !this.state.skipReq) {
        // this.state.skipReq = true;
        this.state.query = text;
        this.setState({ ...this.state }, () => {
          if (text != null) {
            this.FetchData(text);
          }
        });
      } else {
        this.setState({ ...this.state, query: text });
      }
    }, 100);
  };

  handleChange = (e) => {
    this.state.value = e;
    this.setState({ ...this.state }, () => {
      this.Reset();
    });
    console.log(e, "VAL");
  };

  render() {
    return (
      <div
        onBlur={() => {
          setTimeout(() => {
            this.Reset();
          }, 400);
        }}
      >
        <div className="searchSelectDrop">
          <Select
            placeholder={this.props.Placeholder}
            name={this.props.name}
            onChange={this.handleChange}
            onInputChange={(text) => {
              this.state.CustName = text;
              this.searchOnInput(text);
            }}
            onFocus={() => this.FetchData("")}
            onKeyDown={(e) => {
              if (e.which == 13) {
                setTimeout(() => {
                  if (!this.state.value && this.props.AddCustomer) {
                    this.props.AddCustomer(this.state.CustName);
                  }
                }, 100);
              }
            }}
            autoComplete="off"
            value={this.state.value}
            required={this.props.required}
            className="searchSelect"
            options={this.state.list}
            isSearchable
            isClearable
          />
          {this.props.AddCustomer && (
            <small>*Clear field before adding new customer</small>
          )}
        </div>
      </div>
    );
  }
}

export default AutoSearchCtrl;
