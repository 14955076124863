import React, { Component } from "react";
import Navbar from "../containers/navbar";
import ImageLoader from "../containers/loader";
import SideMenu from "../containers/sidemenu";
import * as API from "../services/api";
import Axios from "axios";
import FeedbackType from "./FeedbackType";
import * as util from "../Util";
import { withRouter } from "react-router-dom";
import FeedbackDetail from "./Feedback View/FeedbackDetail";
import $ from "jquery";
class Feedbacks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      feedbacks: [],
      feedbackType: null,
      modalData: null,
      title: "",
      message: "",
    };
  }

  async componentDidMount() {
    let _this = this;
    // this.setState({ ...this.state, loaded: false })
    if (util.isLoggedIn() === false) {
      this.props.history.push("/login");
    }
    util.showLoader();
    try {
      let result = await API.GetAllFeedbacks();

      // Util.log.info("Data came from cats:" + res.data);
      this.setState(
        {
          feedbacks: result.data.feedbacks.items,
          message: "No Feedbacks",
        },
        () => {
          util.hideLoader();
        }
      );
    } catch (err) {
      this.setState({
        ...this.state,
        feedbacks: [],
        loaded: true,
        message: "No Feedbacks",
      });
      util.hideLoader();
    }
  }

  getFeedbackType = async (id) => {
    let response = "";

    // API.GetFeedbackType(id)
    //   .then(async (res) => {
    //     response = res.data.feedbackType.name;
    //     return response;
    //   })
    //   .catch((err) => {});
    try {
      let result = await API.GetFeedbackType(id);
      // console.log('final result', result)
      // this.setState({ ...this.state, feedbackType: result.data })
      return result.data;
    } catch (error) {}
  };

  getUserDetail = async (id) => {
    let response = "";

    await API.GetUser(id)
      .then(async (res) => {
        //debugger;
        response = res.data.user.firstName;
        return response;
      })
      .catch((err) => {});
  };
  onModalClick = (e, item) => {
    let id = item.id;
    this.props.history.push(`/feedbackdetail/${id}`);
    // this.setState({ ...this.state, modalData: item, title: "Feedback-" + item.id })
    // $('#feedback')[0].click()
  };
  onSearch = async (e) => {
    util.showLoader();
    // console.log('search value', e.target.value)

    try {
      let result = await API.SearchFeedbacks(e.target.value);

      this.setAllValue(result.data);
    } catch (error) {}
  };
  setAllValue = async (data) => {
    this.setState(
      (prevState) => {
        let obj = Object.assign({}, prevState);

        obj.feedbacks = data.feedbacks.items;

        return obj;
      },
      () => util.hideLoader()
    );
  };
  render() {
    return (
      <div>
        <SideMenu />
        <div class="dashboard">
          <Navbar onSearch={this.onSearch} />
          <section id="contents">
            {/* <FeedbackDetail data={this.state.modalData} title={this.state.title} /> */}
            <ImageLoader />
            <section class="statis text-center">
              <div class="container-fluid">
                <div class="row"></div>
                <div class="row user_section">
                  <div class="col-md-12">
                    {this.state.feedbacks.length > 0 ? (
                      <div>
                        {this.state.feedbacks.map((item) => {
                          return (
                            <div
                              class="col-md-4"
                              onClick={(e) => this.onModalClick(e, item)}
                            >
                              <div class="section_second">
                                <div class="img-left">
                                  {/* <img src="/images/feedback-company.png" /> */}
                                  <i className="fa fa-commenting-o"></i>
                                </div>
                                <div class="content-right">
                                  {item.feedbackTypeName ? (
                                    <h4>{item.feedbackTypeName}</h4>
                                  ) : (
                                    ""
                                  )}

                                  <div class="row">
                                    <div class="col-md-6 text-left">
                                      <span>ID</span>
                                      <label>{item.id}</label>
                                    </div>
                                    <div class="col-md-6 text-left">
                                      <span>Feedback Type</span>
                                      <FeedbackType
                                        field="name"
                                        id={item.feedbackTypeId}
                                      />
                                      {/* 
                                      <button>
                                      </button> */}
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-md-6 text-left">
                                      <span>Comment</span>
                                      {item.message ? (
                                        <label className="message">{item.message}</label>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                    <div class="col-md-6 text-left"></div>
                                  </div>
                                  <div class="row">
                                    <div class="col-md-6 text-left">
                                      <span>Added By</span>
                                      <FeedbackType
                                        field="added"
                                        id={item.feedbackTypeId}
                                      />
                                    </div>
                                    <div class="col-md-6 text-left">
                                      <span>Added On</span>
                                      {item.addedOn ? (
                                        <label>
                                          {util.localDate(item.addedOn)}{" "}
                                        </label>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    ) : (
                      <React.Fragment>{this.state.message}</React.Fragment>
                    )}
                  </div>
                </div>
              </div>
            </section>
          </section>
        </div>
      </div>
    );
  }
}

export default withRouter(Feedbacks);
// loader css
//   .progress - loader {
//   position: fixed;
//   top: 14 %;
//   left: 47 %;
//   z - index: 9999999999;
// }
// .progress - loader img {
//   max - width: 150px;
//   opacity: 80 %;
// }
