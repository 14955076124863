import React from "react";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { GetAllProducts, GetData } from "../services/api";
import Select from "react-select";
import AutoSearchCtrl from "../shared/AutoSelectCtrl";
const customStyles = {
  control: (base) => ({
    ...base,
    height: 100,
    minHeight: 100,
    overflow: "scroll",
  }),
};
var months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
var years = [];
const severity = ["high", "low", "medium"];
let InitValues = {
  ProductName: "",
  CustomerName: "",
  CustomerId: "",
  MetalType: "",
  SubProcessType: "",
  TSRType: "",
  TSRTypes: [],
  ProcessType: "",
  SeverityLevel: "",
  Month: "",
  Year: "",
  Keyword: "",
};
let cb = undefined;
export default class FilterForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      productList: [],
      metaL_type_opt: [],
      process: [],
      subprocess: [],
      //
      ...InitValues,
    };
  }

  componentDidMount = () => {
    console.log("popup mount");
    this.FetchProducts();
    this.SetUpYears();
    if (this.props.ClearAll) {
      this.props.ClearAll(() => {
        this.setState({
          ...this.state,
          ...InitValues,
        });
      });
    }

    //
    this.FetchDropDownData();
    this.FetchTSRTypes()
    //
  };

  FetchTSRTypes = async () => {
    try {
      let result = await GetData("Utility/GetTsrTypes");
      if (result.data) {
        this.setState({
          ...this.state,
          TSRTypes: result.data
        })
      } else {
        throw result;
      }
    } catch(err) {
      console.log("err", err)
    } finally {
      // done
    }
  }

  FetchDropDownData = async () => {
    try {
      let result = await GetData("Utility/DropdownData");
      console.log("result", result);
      if (result.data) {
        this.setState({
          ...this.state,
          metaL_type_opt: result.data.metaL_type_opt,
          process: result.data.process,
          subprocess: result.data.subprocess,
        });
      } else {
        throw result;
      }
    } catch (err) {
      console.log("err", err);
    } finally {
    }
  };

  componentWillReceiveProps = () => {
    console.log("popup change");
    if (cb) {
      cb({
        value: this.state.CustomerId,
        label: this.state.CustomerName,
      });
    }
  };

  SetUpYears = () => {
    let currentYear = new Date().getFullYear();
    let earliestYear = 1970;

    while (currentYear >= earliestYear) {
      years.push(currentYear);
      currentYear -= 1;
    }
  };

  FetchProducts = async () => {
    try{
      const productsResults = await GetAllProducts();
      console.log("products result is: ", productsResults.data.products.items);
      this.setState({
        productList: productsResults.data.products.items.map((val, index) => {
          return { value: val.name, label: val.name };
        }),
      });
    }catch(err){
      console.log('error', err);
    }
  };

  HandleMultiSelectChange = async (data, event) => {
    console.log(data, event, "test");
    let text = [];

    if (data != null) {
      data.forEach((val) => {
        text.push(val.label);
      });
    }

    text = JSON.stringify(text);
    this.setState({
      ...this.state,

      [event.name]: text == "[]" ? "" : text,
    });
  };

  GetValues = (data) => {
    if (!data) {
      return null;
    }
    data = data.replace(/[^A-Za-z',\s\d]/g, "").split(",");
    console.log("rs", data);
    if (data.length == 1 && data[0] == "") {
      return null;
    }
    return data.map((val) => {
      return {
        label: val,
        value: val,
      };
    });
  };

  OnSubmit = (close) => {
    console.log("submit");
    if (this.props.ReceiveFilterValues) {
      this.props.ReceiveFilterValues(this.state);
    }
    close();
  };

  HandleChange = (e) => {
    let { value, name } = e.target;
    this.setState({
      [name]: value,
    });
  };

  RenderKeyField = () => {
    return (
      <div className="col-md-4 form-group">
        <label>Keyword</label>
        <input
          type="text"
          placeholder="Search with keyword"
          className="form-control"
          name="Keyword"
          onChange={this.HandleChange}
        />
      </div>
    );
  };

  render() {
    return (
      <Popup
        closeOnDocumentClick={false}
        open={this.props.IsOpen}
        onClose={() => {
          // this.setState({
          //   ...this.state,
          //   CustomerName: "",
          //   CustomerId: ""
          // });
          //
          this.props.OnClose();
        }}
        contentStyle={{
          width: "70%",
          "margin-right": "5%",
        }}
      >
        {(close) => (
          <div className="filter-form">
            <h4>
              Filter Options
              <i className="fas fa-times hand" onClick={close}></i>
            </h4>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                this.OnSubmit(close);
              }}
            >
              <div className="row">
                <div className="col-md-4 form-group">
                  <label>Year</label>
                  <select
                    id="date-dropdown"
                    className="form-control"
                    name="Year"
                    value={this.state.Year}
                    onChange={this.HandleChange}
                  >
                    <option selected value="" disabled hidden>
                      Select Year
                    </option>
                    <option value="">Any</option>
                    {years.map((name) => {
                      return <option value={name}>{name}</option>;
                    })}
                  </select>
                  {/* <input type="text" className="form-control" /> */}
                </div>
                <div className="col-md-4 form-group">
                  <label>Month</label>
                  {/* <input type="text" className="form-control" /> */}
                  <select
                    className="form-control"
                    name="Month"
                    value={this.state.Month}
                    onChange={this.HandleChange}
                  >
                    <option value="" selected hidden disabled>
                      Select Month
                    </option>
                    <option value="">Any</option>
                    {months.map((name) => {
                      return <option value={name}>{name}</option>;
                    })}
                  </select>
                </div>
                <div className="col-md-4 form-group">
                  <label>Severity</label>
                  <select
                    name="SeverityLevel"
                    value={this.state.SeverityLevel}
                    onChange={this.HandleChange}
                    className="form-control"
                  >
                    <option value="" selected hidden>
                      Select severity level
                    </option>
                    <option value="">Any</option>
                    {severity.map((item) => {
                      return <option value={item}>{item}</option>;
                    })}
                  </select>
                  {/* <input type="text" className="form-control" /> */}
                </div>
                <div className="col-md-4 form-group">
                  <label>Product Name</label>
                  <Select
                    className="basic-single"
                    placeholder="Products"
                    classNamePrefix="select"
                    options={this.state.productList}
                    isMulti={true}
                    onChange={this.HandleMultiSelectChange}
                    name="ProductName"
                    value={this.GetValues(this.state.ProductName)}
                    styles={customStyles}
                  />
                  {/* <input type="text" className="form-control" /> */}
                </div>
                <div className="col-md-4 form-group">
                  <label>Metal Type</label>
                  {/* <input type="text" className="form-control" /> */}
                  <Select
                    className="basic-single"
                    placeholder="Metal Type"
                    classNamePrefix="select"
                    options={this.state.metaL_type_opt}
                    isMulti={true}
                    onChange={this.HandleMultiSelectChange}
                    name="MetalType"
                    value={this.GetValues(this.state.MetalType)}
                    styles={customStyles}
                  />
                </div>
                <div className="col-md-4 form-group">
                  <label>Process</label>
                  <Select
                    className="basic-single"
                    placeholder="Process"
                    classNamePrefix="select"
                    options={this.state.process}
                    isMulti={true}
                    onChange={this.HandleMultiSelectChange}
                    name="ProcessType"
                    value={this.GetValues(this.state.ProcessType)}
                    styles={customStyles}
                  />
                  {/* <input type="text" className="form-control" /> */}
                </div>
                <div className="col-md-4 form-group">
                  <label>Sub Process</label>
                  <Select
                    className="basic-single"
                    placeholder="Sub Process"
                    classNamePrefix="select"
                    options={this.state.subprocess}
                    isMulti={true}
                    onChange={this.HandleMultiSelectChange}
                    name="SubProcessType"
                    value={this.GetValues(this.state.SubProcessType)}
                    styles={customStyles}
                  />
                  {/* <input type="text" className="form-control" /> */}
                </div>
                {this.props.ShowTSR ? (
                  <div className="col-md-4 form-group">
                    <label>TSR Type</label>
                    <select
                      name="TSRType"
                      value={this.state.TSRType}
                      className="form-control"
                      onChange={this.HandleChange}
                    >
                      <option value="" hidden selected>
                        Select TSR Type
                      </option>
                      {this.state.TSRTypes.map((item) => {
                        return <option value={item.value}>{item.label}</option>;
                      })}
                    </select>
                  </div>
                ) : null}
                <div className="col-md-4 form-group">
                  <label>Customer Name</label>
                  {/* <input type="text" className="form-control" /> */}
                  <AutoSearchCtrl
                    CallBack={(selection) => {
                      console.log("check", selection);
                      if (selection == null || selection.label == "") {
                        selection = {
                          value: null,
                          label: null,
                        };
                        // return;
                      }
                      this.setState({
                        CustomerName: selection.label,
                        CustomerId: selection.value,
                      });
                    }}
                    InitVal={(fn) => {
                      // cb = fn;
                      if (!this.state.CustomerId || !this.state.CustomerName) {
                        fn(null);
                        return;
                      }
                      fn({
                        value: this.state.CustomerId,
                        label: this.state.CustomerName,
                      });
                    }}
                    Placeholder={"Customer Name"}
                    name={"customerId"}
                    endpoint={"Customer/GetCustomers"}
                    required={true}
                  />
                </div>
                {!this.props.ShowTSR ? this.RenderKeyField() : null}
              </div>
              <div className="row">
                {this.props.ShowTSR ? this.RenderKeyField() : null}
              </div>

              <div className="row">
                <div class="button-grp">
                  <button type="submit" class="update">
                    Apply Filter
                  </button>
                  <button type="button" onClick={close} class="cancel">
                    Cancel
                  </button>
                </div>
              </div>
            </form>
          </div>
        )}
      </Popup>
    );
  }
}
