import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Pagination from "react-js-pagination";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";

const Tables = ({
  heading,
  data,
  accessors,
  button,
  itemsPerPage,
  fetching = false,
  navlink,
  customComponent,
  onPopupClose,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [productId, setProductId] = useState();

  const history = useHistory();

  const totalItems = data ? data.length : 0;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data
    ? data.slice(indexOfFirstItem, indexOfLastItem)
    : [];

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const goToPrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const goToNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  // const handleClick = (id, value) => {
  //   const url = value ? `${navlink}${id}/${value}` : `${navlink}${id}`;

  //   history.push(url);
  // };

  return (
    <div className="w-100">
      <table className="table app-table">
        <thead>
          <tr>
            {heading.map((head, index) => (
              <th key={index} className="text-left">
                {head}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {currentItems.map((row, rowIndex) => (
            <tr key={rowIndex} className="table-text">
              {accessors.map((accessor, colIndex) => (
                <Popup
                  trigger={
                    <td key={colIndex} className="hand text-left">
                      {row[accessor]}
                    </td>
                  }
                  position="right center"
                  modal
                  
                  onClose={() => {
                    onPopupClose();
                  }}
                >
                  {(close) => (
                    <>
                      {customComponent &&
                        React.createElement(customComponent, {
                          id: row.id,
                          closeModal: close,
                          dataTypeId: row.dataTypeId,
                          value: row.value,
                        })}
                    </>
                  )}
                </Popup>
              ))}
            </tr>
          ))}
          {currentItems.length === 0 ? (
            <tr className="table-text">
              <td className="text-center" colSpan={heading.length}>
                {fetching ? "Fetching Data..." : "No Data"}
              </td>
            </tr>
          ) : null}
        </tbody>
      </table>
      <div className="mt-5">
        <Pagination
          hideFirstLastPages
          prevPageText="Prev"
          nextPageText="Next"
          activePage={currentPage}
          itemsCountPerPage={itemsPerPage}
          totalItemsCount={totalItems}
          pageRangeDisplayed={5}
          onChange={paginate}
          className="custom_paginations"
          innerClass="custom_pagination m-0"
        />
      </div>
    </div>
  );
};

export default Tables;
