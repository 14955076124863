import React, { Fragment } from "react";

/**Component for upload handling
 *
 * requirements: name, id, onSelect function ref, imageShown
 */

const Upload = props => {
  return (
    <Fragment>
      <input
        type="file"
        name={props.name}
        onChange={props.onSelect}
        style={{ display: "none" }}
        id={props.id}
        accept={
          props.IsLab
            ? "*/*"
            : "image/*"
        }
      />
      <label htmlFor={props.id}>
        {props.imageShown ? (
          <div className="file-uploader-container hand">
            <div className="file-uploader">
              {props.imageShown.imageUrl ? (
                <img
                  width="100px"
                  height="100px"
                  src={props.imageShown.imageUrl}
                />
              ) : null}
            </div>
            {/* <div className="file-uploader-add-icon">
            <i class="fas fa-times-circle" aria-hidden="true"></i> */}
              {/* <i class="fa fa-plus-circle" aria-hidden="true"></i> */}
            {/* </div> */}
          </div>
        ) : (
          props.children
        )}
      </label>
    </Fragment>
  );
};
export default Upload;
