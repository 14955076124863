import React, { Component } from "react";
import { NavLink, withRouter } from "react-router-dom";
import $ from "jquery";
import * as util from "../Util";
import { MdArrowDropDown } from "react-icons/md";
import { IoMdArrowDropup } from "react-icons/io";
import { IoSettingsOutline } from "react-icons/io5";

let subCatSetting = [
  { id: 1, name: "Metal Types", key: "metal-types"},{ id: 2, name: "Process", key: "process" }, { id: 3, name: 'Sub-Process', key: "sub-process" } 
];
class SideMenu extends Component {
  constructor(props) {
    super(props);
    const storedSettingMenu = localStorage.getItem('settingMenu');
    this.state = {
      settingMenu: storedSettingMenu ? JSON.parse(storedSettingMenu) : false,
    };
  }

  componentDidMount() {
    let location = util.GetRouteLocation();
    console.log('wolf', location);
    if(window.location.hash.includes('setting/datatype')){
      const found = subCatSetting.find((e)=>(e.id.toString() === location));
      if(found){
        $(`#left-menu-${found.key}`).addClass("selected");
      }
    }else{
      $(`#left-menu-${location}`).addClass("selected");
    }
  }
  onLogout = async e => {
    util.getUserLoggedOut();
    this.props.history.push("/login");
  };
  onActivate = () => {
    $(this).css("color", "blue");
  };

  toggleSettings = () => {
    this.setState(prevState => {
      return {
        ...prevState,
        settingMenu: !prevState.settingMenu
      }
    },()=>{
      console.log('wolf', this.state);
    })
  }

  render() {
    return (
      <div>
        <aside class="side-nav" id="show-side-navigation1">
          <i
            class="fa fa-bars close-aside hidden-sm hidden-md hidden-lg"
            data-close="show-side-navigation1"
          ></i>
          {/* Start */}
          <ul class="categories">
            {util.isAdmin() ? (
              <li className="users" id="left-menu-users">
                <img src="../images/user.png" />
                <NavLink to="/users">Users</NavLink>
              </li>
            ) : null}

            {!util.isEmployee() ? (
              <>
                {" "}
                <li className="service-request" id="left-menu-service-request">
                  <img src="../images/service-request.png" />
                  <NavLink to="/service-request">Service Requests</NavLink>
                </li>
                <li
                  className="customer-complaints"
                  id="left-menu-customer-complaints"
                >
                  <img src="../images/customer-complaint.png" />
                  <NavLink to="/customer-complaints">
                    Customer Complaints
                  </NavLink>
                </li>
              </>
            ) : null}
             <li id="left-menu-8d-report">
              <img src="../images/8d-white.png" />
              <NavLink to="/8d-report">8D Report</NavLink>
            </li>
            <li id="left-menu-dock-audit-forms">
              <img src="../images/dock-audit.png" />
              <NavLink to="/dock-audit-forms">Dock Audits</NavLink>
            </li>
            <li id="left-menu-supplier-evaluation-reports">
              <img src="../images/supplier-evaluation.png" />
              <NavLink to="/supplier-evaluation-reports">
                Supplier Evaluations
              </NavLink>
            </li>
            <li id="left-menu-settings" className='d-flex'  onClick={this.toggleSettings}>
              <div className="d-flex"  style={{ color: '#aaa' }}>
              <IoSettingsOutline className="navIcon" style={{ color: '#FFF' }} />
                Settings
              </div>
              <span className="text-light setting-toggle-icon">
                { 
                  this.state.settingMenu ? <IoMdArrowDropup className="icon"/>
                  : <MdArrowDropDown className="icon"/>
                }
              </span>
            </li>
            {this.state.settingMenu ? (
              <>
                <li
                  id='left-menu-products'
                  // className='td-none NavLinkText d-flex gap-5 w-100  p-3'
                >
                  <NavLink
                    to='/setting/products'
                    // className='td-none NavLinkText navPad'
                    className='settings-sub-item'
                    style={{
                      color:
                        this.state.locationPath === `/setting/products`
                          ? "#aaa"
                          : "#aaa",
                    }}
                  >
                    Products
                  </NavLink>
                </li>
                {subCatSetting.map((item) => (
                  <li
                    id={`left-menu-${item.key}`}
                    // className='td-none d-flex gap-5 NavLinkText  '
                  >
                    <NavLink
                      to={`/setting/datatype/${item.id}`}
                      // className='td-none NavLinkText  navMargin py-3 w-100'
                      className='settings-sub-item'
                      // activeClassName='selected'
                      style={{
                        color:
                          this.state.locationPath ===
                          `/setting/datatype/${item.id}`
                            ? "#aaa"
                            : "#aaa",
                      }}
                    >
                      {item.name}
                    </NavLink>
                  </li>
                ))}
              </>
            ) : null}
            {/* End */}
            <li className="sideVersion">
              <span className="version">VERSION: {util.getVersion()}</span>
            </li>
            <li class="logout"  style={{ border: 'none' }}>
            <img src="../images/logout.png"/>
              {/* <i class="fa fa-sign-out" aria-hidden="true"></i> */}
              <a onClick={this.onLogout}>Logout</a>
              <br />
            </li>
          </ul>
        </aside>
      </div>
    );
  }
}

export default withRouter(SideMenu);
