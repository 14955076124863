import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import moment from "moment";
import { saveDataType, deleteDataType } from "../../services/api";
import SideMenu from "../../containers/sidemenu";
import Navbar from "../../containers/navbar";
import ImageLoader from "../../containers/loader";

const AddDataType = ({ id, dataTypeId, closeModal, value }) => {
  const [Inprogress, setInprogress] = useState(false);
  const [formData, setFormData] = useState({
    Value: "",
  });

  const isEditMode = Number(id) > 0;
  const history = useHistory();
  const params = useParams();

  useEffect(() => {
    if (isEditMode) {
      setFormData({ Value: value || "" });
    }
  }, [isEditMode, value]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    try {
      const productData = {
        Id: id || 0,
        dataTypeId: params.datatypeId || dataTypeId || 0,
        Value: formData.Value || "",
      };

      const response = await saveDataType(productData);
      if (response.status === 200) {
        console.log("Successfully Saved");
        if (params.datatypeId == 1) {
          history.push("/setting/datatype/1");
        } else if (params.datatypeId == 2) {
          history.push("/setting/datatype/2");
        } else if (params.datatypeId == 3) {
          history.push("/setting/datatype/3");
        } else if (isEditMode) {
          closeModal();
        }
      } else {
        throw new Error("Failed to Save User");
      }
    } catch (error) {
      console.error(error);
    }
  };

  function cancelAddProduct() {
    if (isEditMode) {
      closeModal();
    } else {
      history.goBack();
    }
  }

  async function deleteDatatypeById() {
    try {
      let response = await deleteDataType(id);
      if (response.status === 200) {
        closeModal();
        console.log("Delete Successful");
      } else {
        console.log("not deleted");
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <>
      {isEditMode ? (
        <>
          <div
            className="user_section complaints"
            style={{ marginLeft: "0", marginRight: "0", boxShadow: "none" }}
          >
            <div className="row margin-bottom">
              <div className="col-md-12 text-left">
                <h5 className="heading">
                  {isEditMode ? "Edit" : "Add"} Product
                </h5>
              </div>
            </div>

            <form onSubmit={onSubmit}>
              <div className="row margin-bottom">
                <div className="col-md-6 text-left">
                  <div className="row">
                    <div className="col-md-3">
                      <span>Name</span>
                    </div>
                    <div className="col-md-9">
                      <input
                        type="text"
                        name="Value"
                        value={formData.Value}
                        onChange={handleChange}
                        placeholder="Value"
                        required
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="row margin-bottom">
                <div className="col-md-12 text-left">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="buttons">
                        <button
                          type="submit"
                          className="update"
                          disabled={Inprogress}
                        >
                          {isEditMode ? "Update" : "Add"}
                        </button>
                        <button
                          type="button"
                          className="cancel"
                          disabled={Inprogress}
                          onClick={cancelAddProduct}
                        >
                          Cancel
                        </button>
                        {Number(id) ? (
                          <i
                            onClick={deleteDatatypeById}
                            className="fa fa-trash"
                            aria-hidden="true"
                          ></i>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </>
      ) : (
        <div>
          {isEditMode ? "" : <SideMenu />}
          <div className="dashboard">
            {isEditMode ? "" : <Navbar />}
            <section id="contents">
              <ImageLoader />
              <section className="statis text-center">
                <div className="container-fluid">
                  <div className="user_section complaints">
                    <div className="row margin-bottom">
                      <div className="col-md-12 text-left">
                        <h5 className="heading">
                          {isEditMode ? "Edit" : "Add"} Product
                        </h5>
                      </div>
                    </div>

                    <form onSubmit={onSubmit}>
                      <div className="row margin-bottom">
                        <div className="col-md-6 text-left">
                          <div className="row">
                            <div className="col-md-3">
                              <span>Name</span>
                            </div>
                            <div className="col-md-9">
                              <input
                                type="text"
                                name="Value"
                                value={formData.Value}
                                onChange={handleChange}
                                placeholder="Value"
                                required
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row margin-bottom">
                        <div className="col-md-12 text-left">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="buttons">
                                <button
                                  type="submit"
                                  className="update"
                                  disabled={Inprogress}
                                >
                                  {isEditMode ? "Update" : "Add"}
                                </button>
                                <button
                                  type="button"
                                  className="cancel"
                                  disabled={Inprogress}
                                  onClick={cancelAddProduct}
                                >
                                  Cancel
                                </button>
                                {Number(id) ? (
                                  <i
                                    onClick={deleteDatatypeById}
                                    className="fa fa-trash"
                                    aria-hidden="true"
                                  ></i>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </section>
            </section>
          </div>
        </div>
      )}
    </>
  );
};

export default AddDataType;
