import React, { useEffect } from "react";
import { localDate } from "../Util";

const CustomerComplaintPrintForm = props => {
  useEffect(() => {
    console.log(props, "print");
  }, []);

  const GetValues = data => {
    if (data == null || data == undefined) {
      return "NA";
    }
    data = data.replace(/[^A-Za-z',\s\d]/g, "").split(",");
    console.log("rs", data);
    if (data.length == 1 && data[0] == "") {
      return "NA";
    }
    return data.map((val, id) => {
      if (id + 1 == data.length) {
        return val;
      }
      return val + ", ";
    });
  };
  return (
    <div id="print-form">
      <div className="row">
        <div className="col-md-6">
          <img className="header-logo" src="/images/logo-print.png" />
        </div>
        <div className="col-md-6">
          <div className="address-block">
            7600 Kensington Ct. Brighton, MI 48116
            <div>Ph: 248-446-9500 ex. 111 Fax: 248-446-1500</div>
            <span className="email-detail">www.fortechproducts.com</span>
          </div>
        </div>
      </div>
      <div className="row form-details">
        <div className="col-md-12">
          <h4>
            Customer Complaint{" "}
            <span>
              #<strong>{props.Data.data.ccNo}</strong>
            </span>
          </h4>
          <div className="row details-head">
            <div className="col-md-3">
              <div>
                <span>Date Created</span>
                <label>{localDate(props.Data.data.date)}</label>
              </div>
            </div>
            <div className="col-md-3">
              <div>
                <span>Close Date</span>
                <label>{localDate(props.Data.data.closeDate)}</label>
              </div>
            </div>
            <div className="col-md-3">
              <div>
                <span>Customer</span>
                <label>{props.Data.data.customerName || "NA"}</label>
              </div>
            </div>
            <div className="col-md-3">
              <div>
                <span>CC No</span>
                <label>{props.Data.data.ccNo}</label>
              </div>
            </div>
          </div>
          <div className="row form-label-values">
            <div className="col-md-4">
              <div>
                <span>Product</span>
                <label>{GetValues(props.Data.data.product)}</label>
              </div>
            </div>
            <div className="col-md-4">
              <div>
                <span>Product Process</span>
                <label>{GetValues(props.Data.data.process)}</label>
              </div>
            </div>
            {/* <div className="col-md-4">
              <div>
                <span>Escape</span>
                <label>{"NA"}</label>
              </div>
            </div> */}
            {/* <div className="col-md-4">
              <div>
                <span>Quality Supervisors</span>
                <label>{"NA"}</label>
              </div>
            </div> */}
            {/* <div className="col-md-4">
              <div>
                <span>Systemic</span>
                <label>{"NA"}</label>
              </div>
            </div> */}
            {/* <div className="col-md-4">
              <div>
                <span>Fortech Affected</span>
                <label>{"NA"}</label>
              </div>
            </div> */}
            <div className="col-md-4">
              <div>
                <span>QC Investigation</span>
                <label>{props.Data.data.qcInvestigation || "NA"}</label>
              </div>
            </div>
            {/* <div className="col-md-4">
              <div>
                <span>Associated ICA #</span>
                <label>{"NA"}</label>
              </div>
            </div> */}
            {/* <div className="col-md-4">
              <div>
                <span>Why no CA</span>
                <label>{"NA"}</label>
              </div>
            </div> */}

            <div className="col-md-12">
              <div>
                <span>Description</span>
                <label>{props.Data.data.description || "NA"}</label>
              </div>
            </div>
            <div className="col-md-12">
              <div>
                <span>Comments</span>
                {/* <label className="comment-lines"></label> */}
              </div>
            </div>
            <div className="col-md-12 comment-lines"></div>
            <div className="col-md-12 comment-lines"></div>
            <div className="col-md-12 comment-lines"></div>
            <div className="col-md-12 comment-lines"></div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CustomerComplaintPrintForm;
