import React, { Component } from "react";
import { FetchFromUserData } from "../Util";

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  IsListing = () => {
    const keys = [
      "#/supplier-evaluation-reports",
      "#/users",
      "#/service-request",
      "#/customer-complaints",
      "#/dock-audit-forms",
      "#/8d-report"
    ];
    return keys.find(ele => {
      return window.location.hash == ele;
    });
  };

  render() {
    const { props } = this;
    return (
      <div>
        <nav id="nav" class="navbar navbar-default">
          <div class="container-fluid">
            <div class="navbar-header">
              <div class="row">
                <div class="col-md-2 text-left">
                  <div
                    class="collapse navbar-collapse navbar-right"
                    id="bs-example-navbar-collapse-1"
                  >
                    <ul class="nav navbar-nav">
                      {/* <li><a><i data-show="show-side-navigation1" class="fa fa-bars show-side-btn"></i></a></li> */}

                      <li class="text-dashboard">
                        {props.title ? props.title : ""}
                      </li>
                      <div class="logo">
                        <img src="/images/logo.jpg" />
                      </div>
                    </ul>
                  </div>
                </div>

                <div class="col-md-3 title_text">
                  <h5>
                    {this.props.Heading != undefined ? this.props.Heading : ""}
                  </h5>
                </div>

                <div class="col-md-7 text-right">
                  <ul class="right-nav">
                    <li>
                      <div class="dropdown">
                        <button
                          class="btn btn-primary dropdown-toggle"
                          type="button"
                          data-toggle="dropdown"
                        >
                          {/* <span class="caret"></span> */}
                        </button>
                        {/* <ul class="dropdown-menu"> */}
                        {/* <li><a href="#">1</a></li>
                          <li><a href="#">2</a></li>
                          <li><a href="#">3</a></li> */}
                        {/* </ul> */}
                      </div>
                    </li>
                    <li>
                      <div class="user-name">
                        <label>{`${FetchFromUserData(
                          "firstName"
                        )} ${FetchFromUserData("lastName")}`}</label>
                        <span>{FetchFromUserData("roleDisplayName")}</span>
                      </div>
                    </li>
                    {/* <li>
                      <a href="javascript:void(0)">
                        <img src="/images/avatar.png" />
                      </a>
                    </li> */}
                    <li>
                      {/* <i class="fa fa-bell-o" aria-hidden="true"></i> */}
                    </li>
                    <li>
                      <div class="input-group md-form form-sm form-2 pl-0">
                        {this.IsListing() ? (
                          <input
                            class="form-control my-0 py-1 red-border"
                            type="text"
                            placeholder="Search"
                            aria-label="Search"
                            id="tableSearch"
                            onChange={this.props.onSearch}
                          />
                        ) : null}
                        {/* <div className="input-group-append">
                          <span
                            class="input-group-text red lighten-3"
                            id="basic-text1"
                          >
                            <i class="fa fa-times" aria-hidden="true"></i>
                          </span>
                        </div> */}
                        {/* <div class="input-group-append">
                          <span
                            class="input-group-text red lighten-3"
                            id="basic-text1"
                          >
                            <i class="fa fa-search" aria-hidden="true"></i>
                          </span>
                        </div> */}
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
    );
  }
}

export default Navbar;
