import React from 'react';
import Upload from "../Upload";
import { BsPlusLg } from "react-icons/bs";

const Attachments = ({ name, title, attachmnts, handleFile, removeFile }) => {
    return (
        <div className="col-md-6 mt-1">
            <label>{title}</label>
            <div>
                {attachmnts?.map((val, index) => {
                    const elementId = name + index;
                    return (
                        <span key={index} className="img-container">
                            <Upload
                                id={elementId}
                                name={name}
                                onSelect={(e) => {
                                    handleFile(e, elementId, index);
                                }}
                                imageShown={val.fileUrl ? {
                                    imageUrl: val.fileUrl
                                } : {}}
                            >
                                <i
                                    class="fa fa-plus-circle"
                                    aria-hidden="true"
                                ></i>
                            </Upload>
                            {val.fileUrl ? (
                                <div className="file-uploader-add-icon">
                                    <i
                                        onClick={() => {
                                            if (removeFile) {
                                                removeFile(index);
                                            }
                                        }}
                                        class="fas fa-times-circle"
                                        aria-hidden="true"
                                    ></i>
                                </div>
                            ) : null}
                        </span>
                    );
                })}
                <Upload
                    id={name + 'New'}
                    name={name}
                    onSelect={(e) => {
                        handleFile(e, name + 'New', null);
                    }}
                >
                    <span className="add-more">
                        <BsPlusLg />
                    </span>
                </Upload>
            </div>
        </div>
    );
};

export default Attachments;