import React, { Component } from "react";
import Navbar from "../containers/navbar";
import ImageLoader from "../containers/loader";
import SideMenu from "../containers/sidemenu";
import * as API from "../services/api";
import * as util from "../Util";
import SampleDetails from "./samples/SampleDetails";
import $ from "jquery";
import { withRouter } from "react-router-dom";
class Samples extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Samples: [],
      modalData: null,
      title: "",
      message: "",
    };
  }

  async componentDidMount() {
    let _this = this;
    // this.setState({ ...this.state, loaded: false })

    if (util.isLoggedIn() === false) {
      this.props.history.push("/login");
    }
    util.showLoader();
    try {
      let result = await API.GetAllSamples();

      // Util.log.info("Data came from cats:" + res.data);
      this.setState(
        {
          Samples: result.data.samples.items,
          message: "No Samples",
        },
        () => {
          util.hideLoader();
        }
      );
    } catch (err) {
      this.setState({
        ...this.state,
        Samples: [],
        loaded: true,
        message: "No Samples",
      });
      util.hideLoader();
    }
  }
  onModalClick = (e, item) => {
    let id = item.id;
    this.props.history.push(`/sampledetail/${id}`);
    // this.setState({ ...this.state, modalData: item, title: "Sample-" + item.id })
    // $('#samples')[0].click()
  };
  onSearch = async (e) => {
    util.showLoader();
    // console.log('search value', e.target.value)
    let value = e.target.value;
    try {
      let result = await API.SearchSamples(e.target.value);

      this.setAllValue(result.data);
    } catch (error) {}
  };
  setAllValue = async (data) => {
    // console.log('data we get', data)
    this.setState(
      (prevState) => {
        let obj = Object.assign({}, prevState);

        obj.Samples = data.samples.items;

        return obj;
      },
      () => util.hideLoader()
    );
  };
  render() {
    const { props } = this;
    return (
      <div>
        <SideMenu />
        <div class="dashboard">
          <Navbar onSearch={this.onSearch} />
          <section id="contents">
            <ImageLoader />
            <section class="statis text-center">
              {/* <SampleDetails data={this.state.modalData} title={this.state.title} /> */}
              <div class="container-fluid">
                <div class="row"></div>
                <div class="row user_section">
                  <div class="col-md-12">
                    {this.state.Samples.length > 0 ? (
                      <div>
                        {this.state.Samples.map((item) => {
                          return (
                            <div
                              class="col-md-4"
                              onClick={(e) => this.onModalClick(e, item)}
                            >
                              <div class="section_second">
                                <div class="img-left">
                                  <img src="/images/samples.png" />
                                </div>
                                <div class="content-right">
                                  {/* {item.feedbackTypeName ? (
                                    <h4>{item.feedbackTypeName}</h4>
                                  ) : (
                                    ""
                                  )} */}
                                  <h4>{item.name}</h4>

                                  <div class="row">
                                    <div class="col-md-6 text-left">
                                      <span>Sample ID</span>
                                      <label>{item.id}</label>
                                    </div>
                                    <div class="col-md-6 text-left">
                                      <span>Sample Date</span>
                                      {item.addedOn ? (
                                        <label>
                                          {util.localDate(item.addedOn)}
                                        </label>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                    {/* <div class="col-md-6 text-left">
                                      <span>Feedback Type</span>

                                      <button>
                                        {this.getFeedbackType(
                                          item.feedbackTypeId
                                        )}
                                      </button>
                                    </div> */}
                                  </div>

                                  <div class="row">
                                    <div class="col-md-6 text-left">
                                      <span>Status</span>
                                      <button>{item.statusName}</button>
                                    </div>
                                    <div class="col-md-6 text-left">
                                      <span>Description</span>
                                      {item.description ? (
                                        <label>{item.description} </label>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    ) : (
                      <React.Fragment>{this.state.message}</React.Fragment>
                    )}
                  </div>
                </div>
              </div>
            </section>
          </section>
        </div>
      </div>
    );
  }
}

export default withRouter(Samples);
