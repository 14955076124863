import React, { Component } from "react";
import Navbar from "../../containers/navbar";
import ImageLoader from "../../containers/loader";
import SideMenu from "../../containers/sidemenu";
import * as API from "../../services/api";
import * as util from "../../Util";
import Pagination from "react-js-pagination";
import $ from "jquery";
import { withRouter } from "react-router-dom";
import UserDetail from "./ UserDetail";
class Users extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      modalData: null,
      message: "",
      activePage: 1,
      APIPageNumber: 1,
      APIPageSize: 100,
      Inprogress: false,
      latestSearch: undefined,
    };
  }

  componentDidMount() {
    this.GetData();
  }

  GetData = async () => {
    let _this = this;
    if (util.isLoggedIn() === false) {
      this.props.history.push("/login");
    }
    util.showLoader();
    // this.setState({ ...this.state, loaded: false })
    if (util.isAdmin()) {
      try {
        let result = await API.getAllUsers(
          this.state.APIPageNumber,
          this.state.APIPageSize
        );
        // console.log(result);
        result = { data: result.data.data, message: result.data.message };

        console.log("binding", result);
        // Util.log.info("Data came from cats:" + res.data);
        if (result.data != null) {
          if (result.data.items.length < this.state.APIPageSize) {
            // Means end of records reached
            this.state.APIPageNumber = null;
          } else {
            this.state.APIPageNumber = this.state.APIPageNumber + 1;
          }

          this.setState({
            users: this.state.users.concat(result.data.items),
            message: "No Users",
          });
        }
      } catch (err) {
        console.log(err.message);
        this.setState({
          ...this.state,
          users: [],
          loaded: true,
          message: "No Users",
        });
      } finally {
        util.hideLoader();
        this.state.Inprogress = false;
      }
    }
    // else {
    //     this.setState({...this.state})
    // }
    else {
      util.hideLoader();
      this.setState({ ...this.state, message: "No Users Found" });
    }
  };

  // getFeedbackType = (id) => {
  //   let response = "";

  //   API.GetFeedbackType(id)
  //     .then(async (res) => {
  //       response = res.data.feedbackType.name;
  //       return response;
  //     })
  //     .catch((err) => {});

  // };

  // getUserDetail =  async (id) => {
  //   let response = "";

  //   await API.GetUser(id)
  //     .then(async(res) => {
  //       debugger;
  //       response = res.data.user.firstName;
  //       return response;

  //     })
  //     .catch((err) => {});

  // };
  onModalClick = (e, item) => {
    this.setState({ ...this.state, modalData: item, title: "User-" + item.id });
    this.props.history.push(`/userdetail/${item.id}`);
    // $("#users")[0].click();
  };

  SearchDebounce;
  onSearch = async (e) => {
    if (this.SearchDebounce) {
      clearInterval(this.SearchDebounce);
    }
    let { value } = e.target;
    let temp = Math.random();
    this.state.latestSearch = temp;
    this.SearchDebounce = setTimeout(async () => {
      util.showLoader();

      try {
        let result = await API.SearchUsers(value);
        // Check if latest search query ?
        if (this.state.latestSearch != temp) {
          return;
        }
        if (result.data != null) {
          this.setState(
            {
              activePage: 1,
            },
            () => {
              this.setAllValue(result.data.data.items);
            }
          );
        }
      } catch (error) {
        console.log("search err");
      } finally {
        util.hideLoader();
      }
    }, 400);
  };
  setAllValue = async (data) => {
    this.setState(
      (prevState) => {
        let obj = Object.assign({}, prevState);

        obj.users = data;
        obj.message = "No Users";

        return obj;
      },
      () => util.hideLoader()
    );
  };
  handlePageChange = (pageNumber) => {
    console.log(`active page is ${pageNumber}`);
    this.setState({ activePage: pageNumber });

    if (this.state.APIPageNumber != null && !this.state.Inprogress) {
      if (this.state.users.length - pageNumber * 12 <= 30) {
        this.state.Inprogress = true;
        this.GetData();
      }
    }
  };
  render() {
    const { props } = this;
    return (
      <div>
        <SideMenu />
        <div class="dashboard">
          <Navbar onSearch={this.onSearch} Heading={"Users"} />
          <section id="contents">
            <ImageLoader />
            <section class="statis text-center">
              <div class="container-fluid">
                <div class="row"></div>
                <div class="row user_section">
                  <div class="col-md-12">
                    {this.state.users.length > 0 ? (
                      <>
                        <div className="row">
                          <div className="col-md-3 text-left">
                            <button
                              className="btn btn-primary add-btn "
                              onClick={() => {
                                this.props.history.push("/userdetail");
                              }}
                            >
                              ADD
                            </button>
                          </div>
                        </div>
                        <div>
                          {this.state.users
                            .filter((val, idx) => {
                              let low, high;
                              low = this.state.activePage * 12 - 12;
                              high = this.state.activePage * 12;
                              console.log(low, high, "pagi");
                              if (idx >= low && idx < high) return true;
                              else return false;
                            })
                            .map((item, key) => {
                              return (
                                <div
                                  class="col-md-4"
                                  key={key}
                                  onClick={(e) => this.onModalClick(e, item)}
                                >
                                  <div class="section_second list-view">
                                    {/* v2 */}
                                    <div className="cust-row">
                                      <img src="/images/icon.png" alt="" />
                                      <h4>{item.email}</h4>
                                    </div>
                                    {/*  */}
                                    <div className="cust-row-no-flex">
                                      <div class="cust-col-50 flex-dr-col-baseline">
                                        <span>Role</span>
                                        <label>{item.roleDisplayName}</label>
                                      </div>
                                      <div class="cust-col-50 flex-dr-col-baseline">
                                        <span>Name</span>
                                        <label>
                                          {item.firstName + " " + item.lastName}
                                        </label>
                                      </div>
                                      <div class="cust-col-50 flex-dr-col-baseline">
                                        <span>Phone</span>
                                        <label>{item.contactNumber}</label>
                                      </div>

                                      <div class="cust-col-50 flex-dr-col-baseline">
                                        <span>Status</span>
                                        {item.isBlocked === false ? (
                                          <label>Inactive</label>
                                        ) : (
                                          <label>Active</label>
                                        )}
                                      </div>
                                    </div>
                                    {/*  */}
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                      </>
                    ) : (
                      <React.Fragment>{this.state.message}</React.Fragment>
                    )}
                  </div>
                </div>
                {this.state.users.length != 0 ? (
                  <div className="row">
                    <Pagination
                      hideFirstLastPages
                      prevPageText="Prev"
                      nextPageText="Next"
                      activePage={this.state.activePage}
                      itemsCountPerPage={12}
                      totalItemsCount={this.state.users.length}
                      pageRangeDisplayed={5}
                      onChange={this.handlePageChange}
                      className="custom_pagination"
                      innerClass="custom_pagination"
                    />
                  </div>
                ) : null}
              </div>
            </section>
          </section>
        </div>
      </div>
    );
  }
}

export default withRouter(Users);
