
export function readFileAsDataUrl(fileData) {
    return new Promise((res, rej) => {
        const reader = new FileReader()
        reader.onload = (e) => {
            console.log('inside file reader', e);
            res(e.target.result)
        }
        reader.readAsDataURL(fileData);
    })
}