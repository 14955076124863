import Axios from "axios";
import * as Util from "../Util";

const apiRoot = Util.GetApiRoot();
let temp = undefined;
Axios.interceptors.request.use(
  async function(config) {
    // Do something before request is sent
    const token = await Util.GetToken();
    console.log("####headers####", token);

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    if (temp !== undefined) {
      config.data = temp;
      temp = undefined;
    }

    return config;
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
Axios.interceptors.response.use(
  function(response) {
    return response;
  },
  async function(error) {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      // const user = JSON.parse(await (await Storage.get({ key: 'user' })).value || '');
      const user = Util.GetUserInfo();
      var em = user.email;
      var pass = user.password;
      // var log_cred = JSON.parse(utility.getCookieValue("log_cred"));
      var log_cred = { email: em, password: pass };
      authorize(log_cred)
        .then(res => {
          let token = res.data.token;
        })
        .catch(err => {});
      Axios.defaults.headers.common[
        "Authorization"
      ] = await `Bearer ${Util.GetToken()}`;
      return Axios(originalRequest);
    }

    return Promise.reject(error);
  }
);

////Get All Feedbacks////
export async function GetAllFeedbacks() {
  try {
    const response = await Axios.get(apiRoot + "Feedback/GetAll?pagesize=100");
    return response;
  } catch (error) {
    return error;
  }
}
//////////
/////Get feedback type
export async function GetFeedbackType(id) {
  try {
    const response = await Axios.get(apiRoot + `FeedbackTypes/Get?id=${id}`);
    return response;
  } catch (error) {
    return error;
  }
}

export async function getServiceRequestImages(id) {
  try {
    const response = await Axios.get(
      apiRoot +
        `ServiceRequestImages/GetServiceRequestImages?pagenumber=1&pagesize=10&servicerequestid=${id}`
    );
    return response;
  } catch (error) {
    return error;
  }
}

export async function getComplaintImages(id) {
  try {
    const response = await Axios.get(
      apiRoot +
        `ComplaintImages/GetComplaintImages?pagenumber=1&pagesize=10&ComplaintId=${id}`
    );
    return response;
  } catch (error) {
    return error;
  }
}

export async function getSupplierImages(id) {
  try {
    const response = await Axios.get(
      apiRoot +
        `SupplierEvaluationImage/GetSupplierEvaluationImage?pagenumber=1&pagesize=10&supplierEvaluationId=${id}`
    );
    return response;
  } catch (error) {
    return error;
  }
}

export async function saveServiceRequestImages(data) {
  try {
    // debugger;
    const response = await Axios.post(
      apiRoot + "ServiceRequestImages/SaveServiceRequestImages",
      data
    );

    return response;
  } catch (error) {
    return error;
  }
}
export async function saveComplaintImages(data) {
  try {
    // debugger;
    const response = await Axios.post(
      apiRoot + "ComplaintImages/SaveComplaintImages",
      data
    );

    return response;
  } catch (error) {
    return error;
  }
}

export async function saveSupplierImage(data) {
  try {
    // debugger;
    const response = await Axios.post(
      apiRoot + "SupplierEvaluationImage/SaveSupplierEvaluationImage",
      data
    );

    return response;
  } catch (error) {
    return error;
  }
}

export async function GetAllProducts() {
  try {
    const response = await Axios.get(
      apiRoot + "Products/GetAll?pagenumber=1&pagesize=1000"
    );
    console.log("api", response);
    return response;
  } catch (error) {
    return error;
  }
}

//////

/////Get User
export function GetUser(id) {
  try {
    const response = Axios.get(apiRoot + `Users/Get?id=${id}`);
    return response;
  } catch (error) {
    return error;
  }
}
//////

///Login
export async function authorize(data) {
  try {
    // debugger;
    const response = await Axios.post(apiRoot + "Auth/Login", data);

    return response;
  } catch (error) {
    return error;
  }
}

//////

///Get all samples
export async function GetAllSamples() {
  try {
    const response = await Axios.get(apiRoot + "Samples/GetAll");
    console.log("api", response);
    return response;
  } catch (error) {
    return error;
  }
}
////
///Get all service request
export async function GetAllServices() {
  try {
    const response = await Axios.get(
      apiRoot + "ServiceRequests/GetAll?pagenumber=1&pagesize=100"
    );
    console.log("api", response);
    return response;
  } catch (error) {
    return error;
  }
}
////
///Get all Supplier Evaluation Reports
export async function GetAllSupplierReports(pagenumber, pagesize) {
  try {
    const response = await Axios.get(
      apiRoot +
        `SupplierEvaluationReport/GetAllSupplierEvaluationReport?pagenumber=${pagenumber}&pagesize=${pagesize}`
    );
    console.log("api", response);
    return response;
  } catch (error) {
    return error;
  }
}
export async function getSingleSupplierReport(id) {
  try {
    let result = await Axios.get(
      apiRoot + "SupplierEvaluationReport/GetSupplierEvaluationReport?id=" + id
    );
    return result;
  } catch (error) {
    return error;
  }
}

export async function saveDockAuditFormImages(data) {
  try {
    let result = await Axios.post(
      apiRoot + "DockAuditFormImage/SaveDockAuditFormImages",
      data
    );
    return result;
  } catch (error) {
    return error;
  }
}

export async function getDockAuditFormImages(id) {
  try {
    let result = await Axios.get(
      `${apiRoot}DockAuditFormImage/GetDockAuditFormImages?pagenumber=1&pagesize=10&dockAuditFormId=${id}`
    );
    return result;
  } catch (error) {
    return error;
  }
}

export async function updateSupplierInfo(data) {
  try {
    let result = await Axios.post(
      apiRoot + "SupplierEvaluationReport/SaveSupplierEvaluationReport",
      data
    );
    return result;
  } catch (error) {
    return error;
  }
}

export async function saveDockAuditForm(data) {
  try {
    let result = await Axios.post(
      apiRoot + "DockAuditForm/SaveDockAuditForm?version=1",
      data
    );
    return result;
  } catch (error) {
    return error;
  }
}
export async function fileUpload(dataFile) {
  const data = new FormData();
  data.append("file", dataFile);
  try {
    let result = await Axios.post(apiRoot + "Files/Upload", data);
    return result;
  } catch (error) {
    return error;
  }
}

export async function getSalesPeople() {
  try {
    let result = await Axios.get(
      apiRoot + "SalesOwner/GetSalesPeople?pagenumber=1&pagesize=1000&query="
    );
    return result;
  } catch (error) {
    return error;
  }
}

export async function getCustomer() {
  try {
    let result = await Axios.get(
      apiRoot + "Customer/GetCustomers?pagenumber=1&pagesize=1000&query="
    );
    return result;
  } catch (error) {
    return error;
  }
}

export async function getTeamMembers() {
  try {
    let result = await Axios.get(
      apiRoot + "Users/GetTeamMembers?pagenumber=1&pagesize=1000"
    );
    return result;
  } catch (error) {
    return error;
  }
}
////
// GET queried
export async function GetQueried(endpoint) {
  try {
    const response = await Axios.get(apiRoot + endpoint);
    console.log("api", response);
    return response;
  } catch (error) {
    return error;
  }
}
//
///Get Records
export async function GetRecords(endpoint, pagenumber, pagesize) {
  try {
    const response = await Axios.get(
      apiRoot + endpoint + "pageNumber" + pagenumber + "&pagesize=" + pagesize
    );
    console.log("api", response);
    return response;
  } catch (error) {
    return error;
  }
}
////
///Get all Dock Audits
export async function GetAllDockAudits(pagenumber, pagesize) {
  try {
    const response = await Axios.get(
      apiRoot +
        "DockAuditForm/GetDockAuditForms?pagenumber=" +
        pagenumber +
        "&pagesize=" +
        pagesize
    );
    console.log("api", response);
    return response;
  } catch (error) {
    return error;
  }
}

///Get single Dock Audits
export async function getSingleDockAudit(id) {
  try {
    const response = await Axios.get(
      apiRoot + `DockAuditForm/GetDockAuditForm?id=${id}`
    );
    console.log("api", response);
    return response;
  } catch (error) {
    return error;
  }
}
////
///Get all Form Submissions
export async function GetAllFormSubmissions(orderid, pagenumber, pagesize) {
  try {
    const response = await Axios.get(
      apiRoot +
        "DockAuditForm/GetDockAuditFormSubmissions?orderid=" +
        orderid +
        "&pagenumber=" +
        pagenumber +
        "&pagesize=" +
        pagesize
    );
    console.log("api", response);
    return response;
  } catch (error) {
    return error;
  }
}
////
///Get all customer complaints
export async function GetAllComplaints() {
  try {
    const response = await Axios.get(
      apiRoot + "CustomerComplaints/GetAll?pagenumber=1&pagesize=100"
    );
    console.log("api", response);
    return response;
  } catch (error) {
    return error;
  }
}
export async function forgetPassword(email) {
  try {
    const response = await Axios.get(
      apiRoot + "Auth/Forgotpassword?email=" + email
    );
    return response;
  } catch (error) {
    return error;
  }
}
export async function SearchComplaint(value, customQuery = false) {
  try {
    let temp = apiRoot + "CustomerComplaints/GetAll?";

    if (customQuery) {
      temp += value;
    } else {
      temp += "query=" + value + "&pagenumber=1&pagesize=1000";
    }

    let result = await Axios.get(temp);

    return result;
  } catch (error) {
    return error;
  }
}

export async function Search8DReports(value, customQuery = false) {
  try {
    let temp = apiRoot + "ResponseReport/GetAll?";
    if (customQuery) {
      temp += value;
    } else {
      temp += "query=" + value + "&pagenumber=1&pagesize=1000";
    }
    let result = await Axios.get(temp);
    return result;
  } catch (error) {
    return error;
  }
}

export async function SearchSupplierForm(value) {
  try {
    let result = await Axios.get(
      apiRoot +
        "SupplierEvaluationReport/GetAllSupplierEvaluationReport?query=" +
        value
    );
    return result;
  } catch (error) {
    return error;
  }
}
export async function SearchSamples(value) {
  try {
    let result = await Axios.get(apiRoot + "Samples/GetAll?query=" + value);
    return result;
  } catch (error) {
    return error;
  }
}

export async function SearchRequests(value, customQuery = false) {
  try {
    let temp = apiRoot + "ServiceRequests/GetAll?";

    if (customQuery) {
      temp += value;
    } else {
      temp += "query=" + value + "&pagenumber=1&pagesize=1000";
    }

    let result = await Axios.get(temp);
    return result;
  } catch (error) {
    return error;
  }
}

export async function searchDockForm(value) {
  try {
    let result = await Axios.get(
      apiRoot + "DockAuditForm/GetDockAuditForms?query=" + value
    );
    return result;
  } catch (error) {
    return error;
  }
}

export async function SearchFeedbacks(value) {
  try {
    let result = await Axios.get(apiRoot + "Feedback/GetAll?query=" + value);
    return result;
  } catch (error) {
    return error;
  }
}
////
export async function getAllUsers(pagenumber, pagesize) {
  try {
    let result = await Axios.get(`
      ${apiRoot}Users/GetAll?pagenumber=${pagenumber}&pagesize=${pagesize}`);

    return result;
  } catch (error) {
    return error;
  }
}
export async function SearchUsers(value) {
  try {
    let result = await Axios.get(apiRoot + "Users/GetAll?query=" + value);
    return result;
  } catch (error) {
    return error;
  }
}
export async function getSingleSample(id) {
  try {
    let result = await Axios.get(apiRoot + "Samples/Get?id=" + id);
    return result;
  } catch (error) {
    return error;
  }
}
export async function submitSample(data) {
  try {
    let result = await Axios.post(apiRoot + "Samples/Save", data);
    return result;
  } catch (error) {
    return error;
  }
}
export async function deleteSample(id) {
  try {
    let result = await Axios.delete(apiRoot + "Samples/Delete?id=" + id);
    return result;
  } catch (error) {
    return error;
  }
}
export async function getSingleRequest(id) {
  try {
    let result = await Axios.get(apiRoot + "ServiceRequests/Get?id=" + id);
    return result;
  } catch (error) {
    return error;
  }
}
export async function submitRequest(data) {
  try {
    let result = await Axios.post(apiRoot + "ServiceRequests/Save", data);
    return result;
  } catch (error) {
    return error;
  }
}
export async function deleteRequest(id) {
  try {
    let result = await Axios.delete(
      apiRoot + "ServiceRequests/Delete?id=" + id
    );
    return result;
  } catch (error) {
    return error;
  }
}

export async function deleteDockAuditForm(id) {
  try {
    let result = await Axios.delete(
      apiRoot + "DockAuditForm/DeleteDockAuditForm?id=" + id
    );
    return result;
  } catch (error) {
    return error;
  }
}
export async function getSingleComplaint(id) {
  try {
    let result = await Axios.get(apiRoot + "CustomerComplaints/Get?id=" + id);
    return result;
  } catch (error) {
    return error;
  }
}
export async function submitComplaint(data) {
  try {
    let result = await Axios.post(apiRoot + "CustomerComplaints/Save", data);
    return result;
  } catch (error) {
    return error;
  }
}
export async function deleteComplaint(id) {
  try {
    let result = await Axios.delete(
      apiRoot + "CustomerComplaints/Delete?id=" + id
    );
    return result;
  } catch (error) {
    return error;
  }
}

export async function deleteSupplierForm(id) {
  try {
    let result = await Axios.delete(
      apiRoot +
        "SupplierEvaluationReport/DeleteSupplierEvaluationReport?id=" +
        id
    );
    return result;
  } catch (error) {
    return error;
  }
}
export async function getSingleFeedback(id) {
  try {
    let result = await Axios.get(apiRoot + "Feedback/Get?id=" + id);
    return result;
  } catch (error) {
    return error;
  }
}
export async function submitFeedback(data) {
  try {
    let result = await Axios.post(apiRoot + "Feedback/Save", data);
    return result;
  } catch (error) {
    return error;
  }
}
export async function deleteFeedback(id) {
  try {
    let result = await Axios.delete(apiRoot + "Feedback/Delete?id=" + id);
    return result;
  } catch (error) {
    return error;
  }
}
export async function getUserById(id) {
  try {
    let result = await Axios.get(apiRoot + "Users/Get?id=" + id);
    return result;
  } catch (error) {
    return error;
  }
}

export async function saveUser(data) {
  try {
    console.log(data);
    let result = await Axios.post(apiRoot + "Users/Save", data);
    return result;
  } catch (error) {
    console.log(error.message);
    return error;
  }
}

export async function deleteUser(id) {
  try {
    let result = await Axios.delete(apiRoot + "Users/Delete", {
      params: { id }
    });
    return result;
  } catch (error) {
    return error;
  }
}

export async function GetAllRoles() {
  try {
    let result = await Axios.get(apiRoot + "Roles/GetAll");

    return result;
  } catch (error) {
    return error;
  }
}

// GET DATA
export async function GetData(query) {
  try {
    const response = await Axios.get(apiRoot + query);
    console.log("api", response);
    return {
      data: response.data.data || response.data,
      message: response.data.message
    };
  } catch (error) {
    return error;
  }
}

// POST DATA
export async function PostData(endpoint, data) {
  try {
    console.log(data);
    let result = await Axios.post(apiRoot + endpoint, data);
    return result;
  } catch (error) {
    console.log(error.message);
    return error;
  }
}

// DELETE DATA
export async function DeleteData(endpoint, body, params = {}) {
  try {
    temp = body;
    let result = await Axios.delete(apiRoot + endpoint, { params: {...params}});
    return result;
  } catch (error) {
    return error;
  }
}

export async function getAllProducts(pagenumber, pagesize) {
  try {
    let result = await Axios.get(`
      ${apiRoot}Products/GetAll?pagenumber=${pagenumber}&pagesize=${pagesize}`);

    return result;
  } catch (error) {
    return error;
  }
}

export async function saveProduct(data) {
  try {
    let result = await Axios.post(apiRoot + "Products/Save",data);
    return result;
  } catch (error) {
    console.log(error.message);
    return error;
  }
}

export async function getProduct(id) {
  try {
    let result = await Axios.get(apiRoot + "Products/Get?id=" + id);
    return result;
  } catch (error) {
    return error;
  }
}

export async function deleteProduct(id){
  try {
    let result = await Axios.delete(apiRoot+"Products/Delete?id="+id)
    return result;
  } catch (error) {
    return error;   
  }
}

export async function dataType(typeId){
  try {
    let result = await Axios.get(apiRoot+"DataTypes?typeId="+typeId)
    return result
  } catch (error) {
    console.log(error)  
  }
}

export async function saveDataType(data) {
  try {
    let result = await Axios.post(apiRoot + "DataTypes",data);
    return result;
  } catch (error) {
    console.log(error.message);
    return error;
  }
}

export async function deleteDataType(id){
  try {
    let result = await Axios.delete(apiRoot+"DataTypes?id="+id)
    return result;
  } catch (error) {
    return error;   
  }
}

