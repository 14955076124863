import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import moment from "moment";
import { saveProduct, getProduct, deleteProduct } from "../../services/api";
import SideMenu from "../../containers/sidemenu";
import Navbar from "../../containers/navbar";
import ImageLoader from "../../containers/loader";

const AddProducts = ({ id, closeModal }) => {
  const [Inprogress, setInprogress] = useState(false);
  const [formData, setFormData] = useState({
    Name: "",
    description: "",
    AddedOn: "",
  });

  const params = useParams();
  const isEditMode = Number(id) > 0;
  const history = useHistory();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    try {
      const productData = {
        Id: Number(id) || 0,
        Name: formData.Name,
        ProductDescription: formData.description,
        ProductCode: "",
        IsActive: true,
        AddedOn: formData.AddedOn,
        AddedBy: 0,
        LastModOn: "2024-01-12T10:09:03.202Z",
        LastModBy: 0,
      };

      const response = await saveProduct(productData);
      if (response.status === 200) {
        console.log("Successfully Saved");
        setFormData({ Name: "", description: "", AddedOn: "" });

        if (isEditMode) {
          closeModal();
        } else {
          history.push("/setting/products");
        }
      } else {
        throw new Error("Failed to Save User");
      }
    } catch (error) {
      console.error(error);
    }
  };

  async function getProductById() {
    try {
      let response = await getProduct(id);
      if (response.status === 200) {
        setFormData({
          Name: response.data.product.name,
          description: response.data.product.productDescription,
          AddedOn: moment(response.data.product.addedOn).format("YYYY-MM-DD"),
        });
      } else {
        console.log(response.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  function cancelAddProduct() {
    if (isEditMode) {
      closeModal();
    } else {
      history.push("/setting/products");
    }
  }

  async function deleteProductById() {
    try {
      let response = await deleteProduct(id);
      if (response.status === 200) {
        closeModal();
        console.log("Delete Successful");
      } else {
        console.log("not deleted");
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getProductById();
  }, []);

  return (
    <>
      {isEditMode ? (
        <>
          <section>
            <div>
              <div
                className="user_section complaints "
                style={{ marginLeft: "0", marginRight: "0", boxShadow: "none" }}
              >
                <div className="row margin-bottom">
                  <div className="col-md-12 text-left">
                    <h5 className="heading">
                      {isEditMode ? "Edit" : "Add"} Product
                    </h5>
                  </div>
                </div>

                <form onSubmit={onSubmit}>
                  <div className="row margin-bottom">
                    <div className="col-md-6 text-left">
                      <div className="row">
                        <div className="col-md-3">
                          <span>Name</span>
                        </div>
                        <div className="col-md-9">
                          <input
                            type="text"
                            name="Name"
                            value={formData.Name}
                            onChange={handleChange}
                            placeholder="Product Name"
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 text-left">
                      <div className="row">
                        <div className="col-md-3">
                          <span>Description</span>
                        </div>
                        <div className="col-md-9">
                          <input
                            type="text"
                            name="description"
                            value={formData.description}
                            onChange={handleChange}
                            placeholder="Description"
                            required
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row margin-bottom">
                    <div className="col-md-6 text-left">
                      <div className="row">
                        <div className="col-md-3">
                          <span>Added On</span>
                        </div>
                        <div className="col-md-9">
                          <input
                            type="date"
                            name="AddedOn"
                            value={formData.AddedOn}
                            onChange={handleChange}
                            placeholder="Added On"
                            required
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row margin-bottom">
                    <div className="col-md-12 text-left">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="buttons">
                            <button
                              type="submit"
                              className="update"
                              disabled={Inprogress}
                            >
                              {isEditMode ? "Update" : "Add"}
                            </button>
                            <button
                              type="button"
                              className="cancel"
                              disabled={Inprogress}
                              onClick={cancelAddProduct}
                            >
                              Cancel
                            </button>
                            {Number(id) ? (
                              <i
                                onClick={deleteProductById}
                                className="fa fa-trash"
                                aria-hidden="true"
                              ></i>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </section>
        </>
      ) : (
        <div>
          {isEditMode ? "" : <SideMenu />}
          <div className="dashboard">
            {isEditMode ? "" : <Navbar />}
            <section id="contents">
              <ImageLoader />
              <section className="statis text-center">
                <div className="container-fluid">
                  <div className="user_section complaints">
                    <div className="row margin-bottom">
                      <div className="col-md-12 text-left">
                        <h5 className="heading">
                          {isEditMode ? "Edit" : "Add"} Product
                        </h5>
                      </div>
                    </div>

                    <form onSubmit={onSubmit}>
                      <div className="row margin-bottom">
                        <div className="col-md-6 text-left">
                          <div className="row">
                            <div className="col-md-3">
                              <span>Name</span>
                            </div>
                            <div className="col-md-9">
                              <input
                                type="text"
                                name="Name"
                                value={formData.Name}
                                onChange={handleChange}
                                placeholder="Product Name"
                                required
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 text-left">
                          <div className="row">
                            <div className="col-md-3">
                              <span>Description</span>
                            </div>
                            <div className="col-md-9">
                              <input
                                type="text"
                                name="description"
                                value={formData.description}
                                onChange={handleChange}
                                placeholder="Description"
                                required
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row margin-bottom">
                        <div className="col-md-6 text-left">
                          <div className="row">
                            <div className="col-md-3">
                              <span>Added On</span>
                            </div>
                            <div className="col-md-9">
                              <input
                                type="date"
                                name="AddedOn"
                                value={formData.AddedOn}
                                onChange={handleChange}
                                placeholder="Added On"
                                required
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row margin-bottom">
                        <div className="col-md-12 text-left">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="buttons">
                                <button
                                  type="submit"
                                  className="update"
                                  disabled={Inprogress}
                                >
                                  {isEditMode ? "Update" : "Add"}
                                </button>
                                <button
                                  type="button"
                                  className="cancel"
                                  disabled={Inprogress}
                                  onClick={cancelAddProduct}
                                >
                                  Cancel
                                </button>
                                {Number(id) ? (
                                  <i
                                    onClick={deleteProductById}
                                    className="fa fa-trash"
                                    aria-hidden="true"
                                  ></i>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </section>
            </section>
          </div>
        </div>
      )}
    </>
  );
};

export default AddProducts;
