import React, { Component } from "react";
import Navbar from "../../containers/navbar";
import ImageLoader from "../../containers/loader";
import SideMenu from "../../containers/sidemenu";
import * as API from "../../services/api";
import * as util from "../../Util";
import { withRouter } from "react-router-dom";
import moment from "moment";
import Pagination from "react-js-pagination";
class DockAuditFormSubmissions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      FormSubmissions: [],
      filteredData: [],
      latestSearch: undefined,
      activePage: 1,
      APIPageNumber: 1,
      APIPageSize: 100,
      Inprogress: false
    };
  }

  async componentDidMount() {
    this.GetData();
  }

  GetData = async () => {
    if (util.isLoggedIn() === false) {
      this.props.history.push("/login");
    }
    util.showLoader();

    try {
      let order_id = this.props.match.params.order_id;
      console.log("res", order_id);
      let result = await API.GetAllFormSubmissions(
        this.props.match.params.order_id,
        this.state.APIPageNumber,
        this.state.APIPageSize
      );

      if (result.data != null && result.data != undefined) {
        console.log("result", result);
        if (result.data.data.items.length < this.state.APIPageSize) {
          // Means end of records reached
          this.state.APIPageNumber = null;
        } else {
          this.state.APIPageNumber = this.state.APIPageNumber + 1;
        }
        this.setState({
          FormSubmissions: result.data.data.items,
          filteredData: result.data.data.items
        });
      } else {
        throw "err";
      }
    } catch (err) {
      this.setState({ ...this.state, loaded: true });
    } finally {
      util.hideLoader();
    }
  };

  onSearch = async e => {
    util.showLoader();
    let value = e.target.value;
    try {
      let temp = Math.random();
      this.state.latestSearch = temp;
      //   let result = await API.SearchRequests(value);
      // Check if latest search query ?
      if (this.state.latestSearch != temp) {
        return;
      }
      //   if (result.data != null) {
      //     this.setState(
      //       {
      //         FormSubmissions: result.data.data.items
      //       },
      //       () => {
      //         // Filter by current drop-down value
      //         this.onDropDownChange({ target: { value: this.state.status } });
      //       }
      //     );
      //   } else {
      //     throw "err";
      //   }
    } catch (error) {
    } finally {
      util.hideLoader();
    }
  };

  GetStatus = status => {
    if (util.ReduceString(status) == "approved") {
      return (
        <span className="dot green-dot">
          <i class="fas fa-check"></i>
        </span>
      );
    } else {
      return (
        <span className="dot yellow-dot">
          <i class="fas fa-hourglass-end"></i>
        </span>
      );
    }
  };

  handlePageChange = pageNumber => {
    console.log(`active page is ${pageNumber}`);
    this.setState({ activePage: pageNumber });

    if (this.state.APIPageNumber != null && !this.state.Inprogress) {
      if (this.state.users.length - pageNumber * 12 <= 30) {
        this.state.Inprogress = true;
        this.GetData();
      }
    }
  };
  render() {
    const { props } = this;
    return (
      <div>
        <SideMenu />
        <div class="dashboard">
          <Navbar
            onSearch={this.onSearch}
            Heading={`Dock Audit Forms - Order #${this.props.match.params.order_id}`}
          />
          <section id="contents">
            <ImageLoader />
            <section class="statis text-center">
              <div class="container-fluid">
                <div class="row"></div>
                <div class="row user_section">
                  <div class="col-md-12">
                    {this.state.filteredData.length != 0 ? (
                      <div>
                        {this.state.filteredData
                          .filter((val, idx) => {
                            let low, high;
                            low = this.state.activePage * 12 - 12;
                            high = this.state.activePage * 12;
                            console.log(low, high, "pagi");
                            if (idx >= low && idx < high) return true;
                            else return false;
                          })
                          .map(item => {
                            return (
                              <div class="col-md-4" onClick={() => {this.props.history.push(`/dock-audit-forms/${item.id}/edit`)}}>
                                <div class="section_second form-submissions">
                                  <div className="row">
                                    <div class="img-left">
                                      <img src="/images/dock-audit-ico.png" />
                                    </div>
                                    <div class="content-right">
                                      <div class="row">
                                        <div class="col-md-6 text-left">
                                          <span>Date</span>
                                          <label>
                                            {moment(item.date).format(
                                              "MMM DD, YYYY"
                                            )}
                                          </label>
                                        </div>
                                        <div class="col-md-6 text-left">
                                          <span>Submitted by</span>
                                          {item.submittedBy}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="row status-marker">
                                    {this.GetStatus(item.status)}
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    ) : (
                      "No Form Submissions"
                    )}
                  </div>
                </div>
                {this.state.filteredData.length != 0 ? (
                  <div className="row">
                    <Pagination
                      hideFirstLastPages
                      prevPageText="Prev"
                      nextPageText="Next"
                      activePage={this.state.activePage}
                      itemsCountPerPage={12}
                      totalItemsCount={this.state.filteredData.length}
                      pageRangeDisplayed={5}
                      onChange={this.handlePageChange}
                      className="custom_pagination"
                      innerClass="custom_pagination"
                    />
                  </div>
                ) : null}
              </div>
            </section>
          </section>
        </div>
      </div>
    );
  }
}

export default withRouter(DockAuditFormSubmissions);
