import React, { Component } from "react";
import Navbar from "../containers/navbar";
import ImageLoader from "../containers/loader";
import SideMenu from "../containers/sidemenu";
import * as API from "../services/api";
import * as util from "../Util";
import Pagination from "react-js-pagination";
import ServiceDetails from "./Service Requests/ServiceDetails";
import $ from "jquery";
import { withRouter } from "react-router-dom";
import FilterForm from "./FilterForm";

let cb = undefined;
class ServiceRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //
      IsOpen: false,
      //
      services: [],
      modalData: null,
      filteredData: [],
      Inprogress: false,
      activePage: 1,
      APIPageNumber: 1,
      APIPageSize: 100,
      SearchText: "",
      status: "All", // i.e. open
      latestSearch: undefined,
    };
  }

  async componentDidMount() {
    this.GetData();
  }

  GetData = async () => {
    if (util.isLoggedIn() === false) {
      this.props.history.push("/login");
    }

    util.showLoader();

    try {
      let result = await API.GetData(
        `ServiceRequests/GetAll?pagenumber=${this.state.APIPageNumber}&pagesize=${this.state.APIPageSize}&ServiceRequestStatus=${this.state.status}`
      );
      console.log(result);
      if (result.data != null) {
        result = result.data.items;
        if (result.length < this.state.APIPageSize) {
          this.state.APIPageNumber = null;
        } else {
          this.state.APIPageNumber += 1;
        }
        this.setState(
          {
            ...this.state,
            services: this.state.services.concat(result),
            filteredData: this.state.services.concat(result),
          },
          () => {
            util.hideLoader();
            console.log(this.state.activePage);
            this.setState({
              ...this.state,
              activePage: this.state.activePage,
            });
          }
        );
      } else {
        throw result;
      }
    } catch (err) {
      this.setState({ ...this.state, services: [], loaded: true });
      util.hideLoader();
    } finally {
      this.setState(
        {
          Inprogress: false,
        },
        () => {
          // this.onDropDownChange({ target: { value: this.state.status } });
        }
      );
    }
  };

  onModalClick = (e, item) => {
    let id = item.id;
    this.props.history.push(`/servicedetails/${id}`);
    // this.setState({ ...this.state, modalData: item, title: "Service Request-" + item.id })
    // $('#servicerequest')[0].click()
  };

  SearchDebounce;
  onSearch = async (e, IsCustomSearch = false) => {
    if (this.SearchDebounce) {
      clearInterval(this.SearchDebounce);
    }
    let value;

    if (!IsCustomSearch) {
      value = e.target.value;
      if (cb) cb();
    } else {
      value = e;
    }
    let temp = Math.random();
    this.state.latestSearch = temp;
    this.SearchDebounce = setTimeout(async () => {
      util.showLoader();

      // check
      if (value.trim() == "") {
        this.setState(
          {
            APIPageNumber: 1,
            activePage: 1,
            services: [],
            filteredData: [],
            SearchText: "",
          },
          () => {
            this.GetData();
          }
        );
        return;
      }

      try {
        let result = await API.SearchRequests(value, IsCustomSearch);
        // Check if latest search query ?
        if (this.state.latestSearch != temp) {
          return;
        }
        if (result.data != null) {
          this.setState(
            {
              SearchText: value,
              services: result.data.data.items,
              filteredData: result.data.data.items,
              activePage: 1,
              APIPageNumber: 1,
              status: "All",
            },
            () => {
              // Filter by current drop-down value
              // this.onDropDownChange({ target: { value: this.state.status } });
            }
          );
        } else {
          throw "err";
        }
      } catch (error) {
        console.log("err");
      } finally {
        util.hideLoader();
      }
    }, 400);
  };

  onDropDownChange = async (e) => {
    let value = e.target.value;
    try {
      // if (value == "All") {
      //   this.setState(
      //     {
      //       ...this.state,
      //       APIPageNumber: 1,
      //       activePage: 1,
      //       services: [],
      //       filteredData: [],
      //       status: value,
      //     },
      //     () => {
      //       this.GetData();
      //     }
      //   );
      // } else {
      this.setState(
        {
          ...this.state,
          APIPageNumber: 1,
          activePage: 1,
          services: [],
          filteredData: [],
          status: value,
        },
        () => {
          this.GetData();
        }
      );
      // }
    } catch (err) {
    } finally {
      this.setState({
        // APIPageNumber: 1,
        activePage: 1,
      });
    }
  };

  handlePageChange = (pageNumber) => {
    console.log(`active page is ${pageNumber}`);
    this.setState({ activePage: pageNumber });

    if (this.state.SearchText != "") {
      return;
    }

    if (this.state.APIPageNumber != null && !this.state.Inprogress) {
      if (this.state.filteredData.length - pageNumber * 12 <= 30) {
        this.state.Inprogress = true;
        this.GetData();
      }
    }
  };

  //
  OnClose = () => {
    console.log("popup close");
    this.setState({
      IsOpen: false,
    });
  };

  FilterValues = (values) => {
    let filterObject = {
      productName: values.ProductName,
      customerName: values.CustomerName,
      metalType: values.MetalType,
      month: values.Month,
      year: values.Year,
      process: values.ProcessType,
      subProcess: values.SubProcessType,
      serverity: values.SeverityLevel,
      keyword: values.Keyword,
      tsrType: values.TSRType,
    };

    let keys = Object.keys(filterObject);

    let _query = "";
    keys.forEach((key) => {
      if (filterObject[key]) {
        if (_query.length == 0) {
          _query += `${key}=${filterObject[key]}`;
        } else {
          _query += `&${key}=${filterObject[key]}`;
        }
      }
    });
    console.log("filter by", values, filterObject, _query);
    if (_query) {
      this.onSearch(_query, true);
    }
  };

  ExportExcel = async () => {
    console.log("export excel file");
    let result = await API.GetData("ServiceRequests/ExportServiceRequestExcel");
    console.log(result);
    if (result.data) {
      util.CreateLinkAndClick(result.data, "service-requests");
    }
  };

  render() {
    const { props } = this;
    return (
      <div>
        {/* Filter Form */}
        {/*  */}
        <SideMenu />
        <div class="dashboard requests-section">
          <Navbar onSearch={this.onSearch} Heading={"Service Requests"} />
          <section id="contents">
            <ImageLoader />
            <section class="statis text-center">
              <div class="container-fluid">
                <div class="row"></div>
                <div class="row user_section">
                  <FilterForm
                    IsOpen={this.state.IsOpen}
                    OnClose={this.OnClose}
                    ReceiveFilterValues={this.FilterValues}
                    ClearAll={(fn) => {
                      cb = fn;
                    }}
                    ShowTSR={true}
                  />
                  <div>
                    <div class="col-md-3 text-left">
                      <button
                        onClick={() => {
                          this.props.history.push("servicedetails");
                        }}
                        class="btn btn-primary add-btn "
                      >
                        ADD
                      </button>
                    </div>
                    <div class="form-group statusSelect">
                      <span>
                        <i
                          className="fas fa-file-export hand"
                          onClick={this.ExportExcel}
                        ></i>
                        EXPORT
                      </span>
                      <span>
                        <i
                          class="hand fas fa-redo"
                          title="Clear Filter"
                          onClick={() => {
                            if (cb) cb();
                            this.onSearch("", true);
                          }}
                        ></i>
                        CLEAR FILTER
                      </span>
                      <span>
                        <i
                          title="Filter"
                          className="hand fas fa-filter"
                          onClick={() => {
                            this.setState({ IsOpen: !this.state.IsOpen });
                          }}
                        ></i>
                        FILTER
                      </span>
                      <select
                        className="statusDropwdown"
                        id="viewselect"
                        onChange={this.onDropDownChange}
                        value={this.state.status}
                      >
                        <option value={"All"} selected>
                          All
                        </option>
                        <option value={"Open"}>Open</option>
                        <option value={"Close"}>Closed</option>
                      </select>
                    </div>
                  </div>

                  <div class="col-md-12">
                    {this.state.filteredData.length != 0 ? (
                      <div>
                        {this.state.filteredData
                          .filter((val, idx) => {
                            let low, high;
                            low = this.state.activePage * 12 - 12;
                            high = this.state.activePage * 12;
                            console.log(low, high, "pagi");
                            if (idx >= low && idx < high) return true;
                            else return false;
                          })
                          .map((item) => {
                            return (
                              <div
                                class="col-md-4"
                                onClick={(e) => this.onModalClick(e, item)}
                              >
                                <div class="section_second list-view">
                                  {/* v2 */}
                                  <div className="cust-row">
                                    <img src="/images/service.png" alt="" />
                                    <h4>TSR{"#" + item.tsrNo}</h4>
                                  </div>
                                  {/*  */}
                                  <div className="cust-row-no-flex">
                                    <div class="cust-col-50 flex-dr-col-baseline">
                                      <span>Customer Name</span>
                                      <label title={item.customerName}>
                                        {util.checkEmpty(item.customerName)}
                                      </label>{" "}
                                    </div>
                                    <div class="cust-col-50 flex-dr-col-baseline">
                                      <span>Date</span>
                                      <label>
                                        {item.addedOn
                                          ? util.localDate(item.addedOn)
                                          : "NA"}
                                      </label>
                                    </div>
                                    <div class="cust-col-50 flex-dr-col-baseline">
                                      <span>Sales Owner</span>
                                      {item.salesOwner ? (
                                        <label title={item.salesOwner}>
                                          {util.checkEmpty(item.salesOwner)}
                                        </label>
                                      ) : (
                                        <label>NA</label>
                                      )}{" "}
                                    </div>

                                    <div class="cust-col-50 flex-dr-col-baseline">
                                      <span>Product</span>
                                      {item.product ? (
                                        <label
                                          class="labelspace"
                                          title={util.parseStrArray(
                                            item.product
                                          )}
                                        >
                                          {util.parseStrArray(item.product)}
                                        </label>
                                      ) : (
                                        <label>NA</label>
                                      )}
                                    </div>

                                    {/* close dates */}
                                    {/* Target Close Date */}
                                    {item.status && !util.IsClient() ? (
                                      <div class="cust-col-50 flex-dr-col-baseline">
                                        <span>Target Close Date</span>
                                        <label>
                                          {item.targetCloseDate
                                            ? util.localDate(
                                                item.targetCloseDate
                                              )
                                            : "NA"}
                                        </label>
                                      </div>
                                    ) : null}
                                    {/* Actual Close Date */}
                                    {item.status && !util.IsClient() ? (
                                      <div class="cust-col-50 flex-dr-col-baseline">
                                        <span>Actual Close Date</span>
                                        <label>
                                          {item.actualCloseDate
                                            ? util.localDate(
                                                item.actualCloseDate
                                              )
                                            : "NA"}
                                        </label>
                                      </div>
                                    ) : null}
                                    {/* close dates end */}
                                  </div>
                                  {/*  */}
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    ) : (
                      "No Requests"
                    )}
                  </div>
                </div>
                {this.state.filteredData.length != 0 ? (
                  <div className="row">
                    <Pagination
                      hideFirstLastPages
                      prevPageText="Prev"
                      nextPageText="Next"
                      activePage={this.state.activePage}
                      itemsCountPerPage={12}
                      totalItemsCount={this.state.filteredData.length}
                      pageRangeDisplayed={5}
                      onChange={this.handlePageChange}
                      className="custom_pagination"
                      innerClass="custom_pagination"
                    />
                  </div>
                ) : null}
              </div>
            </section>
          </section>
        </div>
      </div>
    );
  }
}

export default withRouter(ServiceRequest);
