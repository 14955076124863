import React, { useState, useEffect } from 'react'
import * as API from '../services/api'
const FeedbackType = (props) => {

    const [state, setState] = useState({
        data: null,
        loaded: false,
        name: false,
        added: false,
        id: ""
    })
    useEffect(() => {
        // console.log('###id###', props)
        let { id, field } = props
        let result
        async function fetchData(feedId) {

            try {
                result = await API.GetFeedbackType(feedId)
                console.log('main result', result.data)
                if (typeof result.data.feedbackType !== 'undefined') {
                    // console.log('state setting')
                    setState(prevState => {
                        let obj = Object.assign({}, prevState)
                        obj[field] = true;
                        obj.data = result.data.feedbackType;
                        obj.loaded = true;
                        obj.id = id
                        return obj
                    })
                }

            } catch (error) {
                setState({ ...state, data: null, loaded: true })

            }
        }

        fetchData(id)



    }, [props.id, props.field])


    return (
        <React.Fragment>
            {state.loaded === true && state.name === true ? <label>{state.data.name}</label> :
                ""
            }

            <React.Fragment>  {
                state.loaded === true && state.added === true && state.data !== null ? <label>{state.data.addedBy}</label> : ""
            }
            </React.Fragment>
        </React.Fragment>
    )
}



export default FeedbackType